import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { searchPosts } from "../../actions";
import get from "lodash/get";
import Loading from "../../components/Loading/Loading";
import PostList from "../../components/PostList/PostList";
import { TiArrowUnsorted } from "react-icons/ti";

const PostSearchResult = (props) => {
  const { searchPosts, loading } = props;
  const title = get(props, "match.params.title");

  const [posts, setPost] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      const searchresult = await searchPosts(title);
      setPost(searchresult);
    }
    fetchPosts();
  }, [searchPosts, title]);
  const sortingPublished = (property) => {
    const copy = [...posts];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] && b[property]) {
        const nameA = a[property].toLowerCase();
        const nameB = b[property].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        return 0;
      }
    });
    setPost(copy);

    if (copy[0].id === posts[0].id) {
      copy.reverse();
    }
  };
  return (
    <div className="body-container">
      <div className="column">
        {loading ? (
          <Loading />
        ) : (
          <>
            {posts.length !== 0 ? (
              <>
                <div className="list-header-row">
                  <div onClick={() => sortingPublished("title")}>
                    Titel <TiArrowUnsorted />
                  </div>
                  <div onClick={() => sortingPublished("user_name")}>
                    verfasst von <TiArrowUnsorted />
                  </div>
                  <div onClick={() => sortingPublished("creation_date")}>
                    erschienen am <TiArrowUnsorted />
                  </div>
                  <div onClick={() => sortingPublished("update_author_name")}>
                    bearbeitet von <TiArrowUnsorted />
                  </div>
                  <div onClick={() => sortingPublished("last_update_date")}>
                    bearbeitet am <TiArrowUnsorted />
                  </div>
                  <div onClick={() => sortingPublished("edit_lock_user_name")}>
                    in Bearbeitung von <TiArrowUnsorted />
                  </div>
                </div>
                {posts.map((post) => (
                  <Link
                    to={`/stöbern/kategorien/beitrag/${post.id}`}
                    className="Link"
                    key={post.id}
                  >
                    <PostList
                      id={post.id}
                      role={post.class_role}
                      title={post.title}
                      date={post.creation_date}
                      userName={post.user_name}
                      updateDate={post.last_update_date}
                      updateUserName={post.update_author_name}
                      editLockUserName={post.edit_lock_user_name}
                      aquiredEditLockAt={post.aquired_edit_lock_at}
                    />
                  </Link>
                ))}
              </>
            ) : (
              <h2>
                Leider konnten wir keinen Beitrag mit dem Suchbegriff "{title}"
                finden
              </h2>
            )}
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    postsRedux: state.posts.posts,
    loading: state.posts.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchPosts: (title) => dispatch(searchPosts(title)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PostSearchResult);
