import React, { useState, useEffect } from "react";
import Select from "react-select";
import TinyMCE12 from "../../components/TinyMCE/TinyMCE12";
import TinyMCE34 from "../../components/TinyMCE/TinyMCE34";
import { connect } from "react-redux";
import { postPost, getCategories, getHelp } from "../../actions";
import { GrClose } from "react-icons/gr";
import happy from "../../pictures/Kidistatus/happy.svg";
import styles from "../NewPost/NewPost.module.css";
import { Prompt } from "react-router";

const NewPost = ({
  postPost,
  id,
  role,
  class_id,
  getCategories,
  allCategories,
  getHelp,
}) => {
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [modal, setModal] = useState(false);
  const [subs, setSubs] = useState(false);
  const [success, setSuccess] = useState(false);
  const [category, setCategory] = React.useState(null);
  const [subCategory, setSubCategory] = React.useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const categories = allCategories.filter(
    (category) => category.parent === null
  );
  const subCategories = allCategories.filter(
    (category) => category.parent !== null
  );
  const titleInput = React.useRef(null);

  useEffect(() => {
    async function fetchCategories() {
      await getCategories();
    }
    getHelp(5);
    fetchCategories();
  }, [getCategories, getHelp]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleTextChange = (text) => {
    setText(text);
  };

  const handleDataRedux = () => {
    postPost(title, text, id, categoryId, role, class_id)
      .then((res) => {
        if (role < 7) {
          getHelp(
            "Dein Beitrag wurde an deine Lehrperson geschickt",
            "success"
          );
        } else {
          getHelp(
            "Ihr Beitrag wurde veröffentlicht. Nur andere Lehrpersonen können ihn sehen",
            "success"
          );
        }
        setTitle("");
        setText("");
        setModal(false);
        setSuccess(true);
      })
      .catch((err) => {
        getHelp(err.message, "error");
      });
  };

  const handleModal = () => {
    if (title === "") {
      getHelp("Welche Überschrift bekommt dein Beitrag?", "error");
      titleInput.current.focus();
      window.scrollTo(0, 0);
    } else if (text === "") {
      getHelp(
        "Dein Textfeld ist leer. Schreibe zuerst einen Beitrag.",
        "error"
      );
    } else {
      setModal(true);
    }
  };
  const handleCloseModal = () => {
    setModal(false);
    setCategory(null);
    setSubCategory(null);
  };
  const renderList = () => {
    return categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  };

  const renderSubList = () => {
    if (category) {
      const subCategoriesOfParent = subCategories.filter(
        (c) => c.parent === category.value
      );

      return subCategoriesOfParent.map((category) => ({
        label: category.name,
        value: category.id,
      }));
    }
  };

  const handleChange = (selectedOption) => {
    const id = selectedOption.value;
    if (selectedOption) {
      setCategory(selectedOption);
      setSubCategory(null);
      setCategoryId(id);
    } else {
      return null;
    }
    /*     handleChangeSub(); */

    const subCategoriesOfParent = subCategories.filter((c) => c.parent === id);
    if (subCategoriesOfParent.length !== 0) {
      setSubs(true);
    } else {
      setSubs(false);
    }
  };
  const handleChangeSub = (selectedOption) => {
    if (selectedOption) {
      const id = selectedOption.value;
      setSubCategory(selectedOption);
      setCategoryId(id);
    } else {
      return null;
    }
  };
  const handleEditor = () => {
    if (role === 1 || role === 2) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {(title !== "" || text !== "") && (window.onbeforeunload = () => true) ? (
        <Prompt
          /*    when={shouldBlockNavigation} */
          message="Willst du diese Seite wirklich verlassen? Dein Beitrag wird nicht gespeichert."
        />
      ) : null}
      {success ? (
        <>
          <img className={styles.picture} src={happy} alt="gut gemacht" />
        </>
      ) : (
        <>
          <div
            className={modal ? styles.modal : styles.noModal}
            /*  onClick={() => {
          handleCloseModal();
        }} */
          >
            <div className={styles.container}>
              <div className={styles.headline}>
                <div className={styles.emptySpace} />
                <h2>Kategorie</h2>
                <GrClose
                  className={styles.cross}
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
              </div>
              <p>Wähle hier eine Kategorie für deinen Beitrag aus:</p>
              <Select
                className={styles.select}
                value={category}
                onChange={handleChange}
                options={renderList()}
                type="search"
                placeholder="wähle die Oberkategorie ..."
                isClearable={false}
                classNamePrefix="Select"
                /* menuIsOpen={true} */
              />
              {subs ? (
                <Select
                  className={styles.select}
                  value={subCategory}
                  onChange={handleChangeSub}
                  options={renderSubList()}
                  type="search"
                  placeholder="wähle die Unterkategorie ..."
                  isClearable={false}
                  classNamePrefix="Select"
                  /* menuIsOpen={true} */
                />
              ) : null}
              <button className="button" onClick={handleDataRedux}>
                Beitrag speichern
              </button>
            </div>
          </div>
          <div className="body-container">
            <div className={styles.block}>
              {handleEditor() ? (
                <TinyMCE12
                  setTitle={handleTitleChange}
                  title={title}
                  setText={handleTextChange}
                  text={text}
                  titleInput={titleInput}
                />
              ) : (
                <TinyMCE34
                  setTitle={handleTitleChange}
                  title={title}
                  setText={handleTextChange}
                  text={text}
                  titleInput={titleInput}
                />
              )}
              <button className="button" onClick={handleModal}>
                Beitrag speichern
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    id: state.user.id,
    role: state.user.role_id,
    class_id: state.user.class_id,
    allCategories: state.categories.categories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postPost: (title, text, id, categoryId, role, class_id) =>
      dispatch(
        postPost(title, text, id, categoryId, null, role, class_id, "publish")
      ),
    getCategories: () => dispatch(getCategories()),
    getHelp: (res, type) => dispatch(getHelp(res, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPost);

//ty082cngqvyq19zypeopn3qcrg74uvpodg4c06za3o0cv6xm
