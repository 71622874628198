import React from "react";
import styles from "./kidiABC.module.css";
import image from "../../pictures/Szene/Szene_abc.svg";
import { connect } from "react-redux";

window.addEventListener("hashchange", function () {
  window.scrollTo(window.scrollX, window.scrollY - 290);
});

const kidiABC = ({ pageContent }) => {
  const filteredContent = pageContent.pageContent.filter((c) => {
    return c.type === "ABC";
  });
  const filteredContentLoggedOut = pageContent.pageContent.filter((c) => {
    return c.type === "loggedOutABC";
  });

  return (
    <>
      {localStorage.getItem("token") ? (
        <div className="body-container">
          <div className={styles.container}>
            <h1>Willkommen beim kidi-ABC! </h1> <h3>Du hast Fragen? </h3>{" "}
            <h4>
              Hier findest du unter den Buchstaben des ABC Antworten zu oft
              gestellten Fragen rund um kidipedia.
            </h4>
            <div className={styles.header}>
              <div className={styles.letterbox}>
                <a href="#A">A</a>
                <a href="#B">B</a>
                <a href="#C">C</a>
                <a href="#D">D</a>
                <a href="#E">E</a>
                <a href="#F">F</a>
                <a href="#G">G</a>
                <a href="#H">H</a>
                <a href="#I">I</a>

                <a href="#J">J</a>
                <a href="#K">K</a>
                <a href="#L">L</a>
                <a href="#M">M</a>
              </div>
              <div className={styles.letterbox}>
                <a href="#N">N</a>
                <a href="#O">O</a>
                <a href="#P">P</a>
                <a href="#Q">Q</a>

                <a href="#R">R</a>
                <a href="#S">S</a>
                <a href="#T">T</a>
                <a href="#U">U</a>
                <a href="#V">V</a>
                <a href="#W">W</a>
                <a href="#X">X</a>
                <a href="#Y">Y</a>
                <a href="#Z">Z</a>
              </div>
            </div>
            {filteredContent[0] ? (
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: filteredContent[0].text }}
              ></div>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="row">
          <img src={image} alt="kidiABC" className="pictures" /> *
          {filteredContent[0] ? (
            <div className="column welcometexts marginLeft">
              <div
                dangerouslySetInnerHTML={{
                  __html: filteredContentLoggedOut[0].text,
                }}
              ></div>{" "}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageContent: state.pageContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(kidiABC);
