import * as actions from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  pageContent: [],
};

const pageContent = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_PAGE_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        pageContent: action.payload,
      };
    case actions.GET_PAGE_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.POST_PAGE_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.POST_PAGE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        pageContent: [...state.pageContent, action.payload],
      };
    case actions.POST_PAGE_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.UPDATE_PAGE_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_PAGE_CONTENT_SUCCESS:
      const newArr = state.pageContent.filter(
        (content) => content.type !== action.payload.type
      );
      return {
        ...state,
        loading: false,
        pageContent: [...newArr, action.payload],
      };
    case actions.UPDATE_PAGE_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default pageContent;
