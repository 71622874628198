import * as actions from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  schools: [],
};

const schools = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_SCHOOLS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_SCHOOLS_SUCCESS:
      return {
        ...state,
        loading: false,
        schools: action.payload,
      };
    case actions.GET_SCHOOLS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case action.POST_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.POST_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        schools: [...state.schools, action.payload],
      };
    case actions.POST_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.UPDATE_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_SCHOOL_SUCCESS:
      const filteredArrSchooles = state.schools.filter(
        (c) => c.id !== action.payload.id
      );

      const arrSchools = [action.payload, ...filteredArrSchooles];
      const sortedArr = arrSchools.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      return {
        ...state,
        loading: false,
        schools: sortedArr,
      };
    case actions.UPDATE_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.DELETE_SCHOOL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_SCHOOL_SUCCESS:
      return {
        ...state,
        loading: false,
        schools: state.schools.filter((s) => s.id !== action.payload),
      };
    case actions.DELETE_SCHOOL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.POST_USER_SUCCESS:
      const user = action.payload;
      if (user.role_id === 8) {
        const findSchool = state.schools.filter((s) => s.id === user.school_id);
        const newArr = state.schools.filter((s) => s.id !== user.school_id);
        const fullName = user.first_name + " " + user.last_name;
        const newSchool = { ...findSchool[0], school_admin: fullName };
        return {
          ...state,
          loading: false,
          schools: [...newArr, newSchool],
        };
      } else {
        return {
          ...state,
          loading: false,
          schools: state.schools.filter((s) => s.id !== action.payload),
        };
      }
    case actions.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default schools;
