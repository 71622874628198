import React, { useState, useEffect } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../../actions";

import Door from "../../pictures/login_pictures/Tuer.svg";
import closedDoor from "../../pictures/login_pictures/geschlossene_Tuer.svg";

import "./Footer.css";

const Footer = (props) => {
  const { user, loggedIn, logOut } = props;
  const path = useLocation().pathname;
  const [pathname, setPathname] = useState(props.path);
  // TODO:
  const [redirect, setRedirect] = useState(false);
  const role = user.role_id;
  useEffect(() => {
    setPathname(path);
  }, [path]);
  const handleLogOut = () => {
    logOut();
    localStorage.removeItem("token");
  };
  const handlePassword = () => {
    if (pathname === "/meinkidi" && loggedIn) {
      return true;
    } else {
      return false;
    }
  };

  const handleAdmin = () => {
    if (loggedIn && role > 6) {
      return true;
    } else {
      return false;
    }
  };
  const isTeacher = () => {
    if ((loggedIn && role === 7) || role === 8) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="footer">
        <div className="nav">
          <ul className="footerRow">
            {redirect ? <Redirect to={`/`} /> : null}
            <li className="admin-button">
              {handleAdmin() ? (
                <>
                  {" "}
                  {isTeacher() ? (
                    <Link
                      to="/Administration_Lehrpersonen"
                      className="button-yellow"
                    >
                      Administration
                    </Link>
                  ) : (
                    <Link to="/administration" className="button-yellow">
                      Administration
                    </Link>
                  )}
                </>
              ) : null}
            </li>
            {handlePassword() ? (
              <li className="changePassword">
                <Link to="/meinkidi/passwort" className="button-blue">
                  <div>Passwort ändern</div>
                </Link>
              </li>
            ) : (
              <div className="invisible">
                <Link to="" className="button-blue">
                  <div>Passwort ändern</div>
                </Link>
              </div>
            )}
            <li className="faq-button">
              <Link to="/kidi-ABC" className="button-yellow">
                kidi-ABC
              </Link>
            </li>
            <li className="faq-button">
              <Link to="/materialien" className="button-yellow">
                Materialien
              </Link>
            </li>
            <li className="impressum-button">
              <Link to="/impressum" className="button-yellow">
                Impressum
              </Link>
            </li>
            <li className="login-button">
              {localStorage.getItem("token") ? (
                <Link
                  to=""
                  className="button-yellow-footer"
                  onClick={handleLogOut}
                >
                  <img
                    src={closedDoor}
                    alt="geschlossene_Tür"
                    className="tuer-footer"
                  />
                  Raus
                </Link>
              ) : (
                <Link to="/anmelden" className="button-yellow-footer">
                  <img src={Door} alt="Tür" className="tuer-footer" />
                  <div className="text">Rein</div>
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    loggedIn: state.user.loggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logOut()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
