export const bulletpoints = `<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#F1F9FC;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st1{fill:#CED5D8;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st2{fill:#575756;stroke:#1D1D1B;stroke-width:0.25;stroke-miterlimit:10;}
</style>
<g>
	<path class="st0" d="M84.1,86.9H15.3c-2,0-3.6-1.6-3.6-3.6V20.4c0-2,1.6-3.6,3.6-3.6h68.8c2,0,3.6,1.6,3.6,3.6v62.9
		C87.7,85.3,86.1,86.9,84.1,86.9z"/>
	<path class="st1" d="M79.2,31.3h-41c-1.1,0-1.9-0.8-1.9-1.9l0,0c0-1.1,0.8-1.9,1.9-1.9h41c1.1,0,1.9,0.8,1.9,1.9l0,0
		C81.1,30.5,80.3,31.3,79.2,31.3z"/>
	<path class="st1" d="M79.3,54.9h-41c-1.1,0-1.9-0.8-1.9-1.9l0,0c0-1.1,0.8-1.9,1.9-1.9h41c1.1,0,1.9,0.8,1.9,1.9l0,0
		C81.2,54,80.4,54.9,79.3,54.9z"/>
	<path class="st1" d="M79.6,75.7h-41c-1.1,0-1.9-0.8-1.9-1.9l0,0c0-1.1,0.8-1.9,1.9-1.9h41c1.1,0,1.9,0.8,1.9,1.9l0,0
		C81.5,74.8,80.7,75.7,79.6,75.7z"/>
	<circle class="st2" cx="24" cy="29.4" r="4.2"/>
	<circle class="st2" cx="24" cy="53" r="4.2"/>
	<circle class="st2" cx="24" cy="73.8" r="4.2"/>
</g>
</svg>`;
