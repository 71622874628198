import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Notifications.module.css";
import { connect } from "react-redux";
import {
  seenNotifications,
  getNotifications,
  reportPost,
  giveStars,
} from "../../actions";
import noNotifications from "../../pictures/Szene/Szene_Briefkasten_leer.svg";
import closedEnvelope from "../../pictures/kidi/Briefumschlag_geschlossen.svg";
import openedEnvelope from "../../pictures/kidi/Briefumschlag_offen.svg";
import { MdCheck } from "react-icons/md";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import ReactStars from "react-rating-stars-component";

const Notifications = ({
  notifications,
  seenNotifications,
  user,
  getNotifications,
  loading,
  reportPost,
  giveStars,
}) => {
  const [seenPosts, setSeenPosts] = useState([]);
  const [unseenPosts, setUnseenPosts] = useState([]);
  const [seeSeenPosts, setSeeSeenPosts] = useState(false);
  const [modal, setModal] = useState(false);
  const [statePost, setStatePost] = useState(null);
  const [starsValue, setStarsValue] = useState(null);

  useEffect(() => {
    async function fetchNotifications() {
      await getNotifications();
    }
    fetchNotifications();
  }, []);

  useEffect(() => {
    const unseen = notifications.filter(
      (notification) => notification.seen === false
    );
    const seen = notifications.filter(
      (notification) => notification.seen === true
    );
    setSeenPosts(seen);
    setUnseenPosts(unseen);
  }, [notifications]);

  const formatDate = (input) => {
    const date = input.slice(0, 10);
    const dArr = date.split("-");
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0].substring(2);
  };
  const handleSeen = (id) => {
    seenNotifications(id);
    const newArr = unseenPosts.filter((n) => n.id !== id);
    const filteredNotification = unseenPosts.filter((n) => n.id === id);
    setUnseenPosts([...newArr]);
    setSeenPosts([filteredNotification[0], ...seenPosts]);
  };

  const handlePublish = (type) => {
    reportPost(
      statePost.post_id,
      statePost.id,
      statePost.student_id,
      statePost.class_id
    );
    handleSeen(statePost.id);
    setModal(false);

    if (type === "stars") {
      giveStars(
        statePost.post_id,
        user.id,
        statePost.date,
        starsValue,
        statePost.student_id,
        statePost.class_id
      ).then(setStarsValue(null));
    } else {
      setStarsValue(null);
    }
  };
  const handleModal = (post) => {
    setModal(true);
    setStatePost(post);
  };
  const giveRating = {
    size: 40,
    count: 5,
    color: "lightgray",
    activeColor: "var(--yellow)",
    value: 0,
    a11y: true,
    edit: true,
    isHalf: true,
    onChange: (newValue) => {
      const stars = newValue * 2;
      setStarsValue(stars);
    },
  };
  const validateStars = (stars, title, post_id) => {
    if (stars === 10) {
      return (
        <>
          <div className={styles.text}>
            Dein Beitrag "{title}" hat {stars / 2} Sterne erhalten! Gib doch
            Mitschülern und Mitschülerinnen Tipps für die Erstellung ihrer
            Beiträge. Oder hilf bei der Überarbeitung anderer Beiträge! Klicke
            unten, wenn du Tipps brauchst.
          </div>
        </>
      );
    } else if (stars === 9 || stars === 8) {
      return (
        <div className={styles.text}>
          Das <em>kidipedia</em>-Lexikon ist durch deine Mitarbeit wieder
          vielfältiger geworden! Du hast für den Beitrag "{title}" {stars / 2}{" "}
          Sterne erhalten. Sicher kannst du anderen kidi-Kids bei der Erstellung
          ihrer Beiträge Tipps geben. Du brauchst selbst noch einen letzten
          Tipp? Dann klicke unten auf kidis Tipps.
        </div>
      );
    } else if (stars === 7 || stars === 6) {
      return (
        <div className={styles.text}>
          Du hast mit deinem Beitrag <em>kidipedia</em> unterstützt! Dein
          Beitrag "{title}" hat {stars / 2} Sterne erhalten. Brauchst du noch
          ein paar Tipps, um deinen Beitrag zu überarbeiten? Dann tausche dich
          mit deiner Lehrperson und deinen Mitschülern und Mitschülerinnen aus
          klicke unten auf kidis Tipps.
        </div>
      );
    } else if (stars === 5 || stars === 4) {
      return (
        <div className={styles.text}>
          Du hast das <em>kidipedia</em>-Lexikon mitgestaltet! Dein Beitrag "
          {title}" hat {stars / 2} Sterne erhalten. Um deinen Beitrag zu
          verbessern, hole dir weitere Tipps bei deiner Lehrperson oder klicke
          unten auf kidis Tipps.
        </div>
      );
    } else if (stars === 3) {
      return (
        <div className={styles.text}>
          Du hast bei <em>kidipedia</em> mitgearbeitet! Dein Beitrag "{title}"
          hat
          {stars / 2} Sterne erhalten. Klicke unten und du findest noch Tipps,
          um deinen Beitrag zu überarbeiten.
        </div>
      );
    } else if (stars === 2) {
      return (
        <div className={styles.text}>
          Du hast bei <em>kidipedia</em> mitgearbeitet! Dein Beitrag "{title}"
          hat 1 Stern erhalten. Klicke unten und du findest noch Tipps, um
          deinen Beitrag zu überarbeiten.
        </div>
      );
    } else if (stars === 1) {
      return (
        <div className={styles.text}>
          Du hast bei <em>kidipedia</em> mitgearbeitet! Dein Beitrag "{title}"
          hat einen halben Stern erhalten. Klicke unten und du findest noch
          Tipps, um deinen Beitrag zu überarbeiten.
        </div>
      );
    }
  };
  return (
    <>
      {seenPosts === [] ? (
        <h1>hello</h1>
      ) : (
        <>
          <div className="body-container">
            <div className={styles.column}>
              <div className={modal ? styles.modal : styles.noModal}>
                <div className={styles.frame}>
                  <h4>Geben Sie diesem Beitrag eine Sternebewertung</h4>
                  <ReactStars {...giveRating} />
                  <div className={`${styles.row} ${styles.inputRow}`}>
                    <button
                      className={`button ${styles.margin}`}
                      onClick={() => handlePublish()}
                    >
                      später bewerten
                    </button>
                    <button
                      className={`button ${styles.margin}`}
                      onClick={() => handlePublish("stars")}
                    >
                      speichern
                    </button>
                    <button
                      className={`button ${styles.margin}`}
                      onClick={() => {
                        setModal(false);
                        setStarsValue(null);
                      }}
                    >
                      abbrechen
                    </button>
                  </div>
                </div>
              </div>
              <div className={styles.columnOut}>
                <h2>Postfach</h2>

                {unseenPosts.length === 0 ? (
                  <>
                    <div>Du hast leider keine neuen Nachrichten</div>
                    <img
                      src={noNotifications}
                      alt="leider keine Post"
                      className={styles.picture}
                    />
                  </>
                ) : (
                  <>
                    {unseenPosts.map((post) => (
                      <div className={styles.card} key={post.id}>
                        <div className="row">
                          <img
                            src={closedEnvelope}
                            alt="geschlossener Brief"
                            className={styles.envelope}
                          />
                          <div className={styles.column}>
                            <div>{formatDate(post.date)}</div>
                            {post.type === "rated" ? (
                              <Link
                                to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                                className="Link"
                                key={post.post_id}
                              >
                                {validateStars(
                                  post.stars,
                                  post.title,
                                  post.post_id
                                )}
                              </Link>
                            ) : null}
                            {post.type === "published" ? (
                              <Link
                                to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                                className="Link"
                                key={post.post_id}
                              >
                                <div className={styles.text}>
                                  Dein Beitrag "{post.title}" wurde
                                  veröffentlicht. Schau ihn dir auf kidipedia.de
                                  an.
                                </div>
                              </Link>
                            ) : null}
                            {post.type === "updated" ? (
                              <Link
                                to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                                className="Link"
                                key={post.post_id}
                              >
                                <div className={styles.text}>
                                  Dein Beitrag "{post.title}" wurde
                                  überarbeitet. Möchtest du vielleicht noch
                                  etwas hinzufügen?
                                </div>
                              </Link>
                            ) : null}
                            {post.type === "reported" ? (
                              <Link
                                to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                                className="Link"
                                key={post.post_id}
                              >
                                <div className={styles.text}>
                                  Der Beitrag "{post.title}" von{" "}
                                  {post.first_name
                                    ? post.first_name
                                    : post.user_name}
                                  {post.last_name ? post.last_name : null} wurde
                                  von der Redaktion aus <em>kidipedia</em>{" "}
                                  entfernt.
                                </div>
                              </Link>
                            ) : null}
                            {post.type === "publish" ? (
                              <Link
                                to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                                className="Link"
                                key={post.post_id}
                              >
                                <div className={styles.text}>
                                  Wollen Sie den Beitrag "{post.title}" von{" "}
                                  {post.first_name
                                    ? post.first_name
                                    : post.user_name}
                                  {post.last_name ? post.last_name : null}{" "}
                                  veröffentlichen?
                                </div>
                              </Link>
                            ) : null}
                            {post.type === "check" ? (
                              <Link
                                to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                                className="Link"
                                key={post.post_id}
                              >
                                <div className={styles.text}>
                                  {post.first_name
                                    ? post.first_name
                                    : post.user_name}
                                  {post.last_name ? post.last_name : null} hat
                                  den Beitrag "{post.title}" überarbeitet.
                                  Schauen Sie gerne einmal drüber.
                                </div>
                              </Link>
                            ) : null}
                            <div className={styles.row}>
                              {post.type === "publish" ? (
                                <div
                                  className={styles.buttonSeen}
                                  onClick={() => {
                                    handleModal(post);
                                  }}
                                >
                                  veröffentlichen
                                </div>
                              ) : null}
                              {post.type === "reported" ? (
                                <div className={styles.buttonSeen}>
                                  <div
                                    onClick={() => {
                                      reportPost(
                                        post.post_id,
                                        undefined,
                                        post.student_id,
                                        post.class_id
                                      );
                                    }}
                                  >
                                    wieder veröffentlichen
                                  </div>
                                </div>
                              ) : null}
                              {post.type === "rated" && post.stars !== 10 ? (
                                <a
                                  href="https://dev05.api.kidipedia.de/public/files/1624964764808_Kriterienraster%20Beitrags%C3%BCberarbeitung.pdf"
                                  className={styles.index}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  → kidis Tipps für Beiträge
                                </a>
                              ) : null}
                              <div
                                className={styles.buttonSeen}
                                onClick={() => handleSeen(post.id)}
                              >
                                <MdCheck className={styles.icons} />
                                gelesen
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              {!seeSeenPosts ? (
                <div
                  className={styles.toggle}
                  onClick={() => setSeeSeenPosts(!seeSeenPosts)}
                >
                  gelesene Nachrichten anzeigen
                  <IoIosArrowDown className={styles.icons} />
                </div>
              ) : (
                <>
                  <div
                    className={styles.toggle}
                    onClick={() => setSeeSeenPosts(!seeSeenPosts)}
                  >
                    gelesene Nachrichten ausblenden
                    <IoIosArrowUp className={styles.icons} />
                  </div>
                  {seenPosts.map((post) => (
                    <div className={styles.card} key={post.id}>
                      <div className="row">
                        <img
                          src={openedEnvelope}
                          alt="geoffneter Brief"
                          className={styles.envelope}
                        />
                        <div className={styles.column}>
                          <div>{formatDate(post.date)}</div>
                          {post.type === "rated" ? (
                            <>
                              {validateStars(
                                post.stars,
                                post.title,
                                post.post_id
                              )}
                            </>
                          ) : null}
                          {post.type === "published" ? (
                            <Link
                              to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                              className="Link"
                              key={post.post_id}
                            >
                              <div className={styles.text}>
                                Dein Beitrag "{post.title}" wurde
                                veröffentlicht. Schau ihn dir auf kidipedia.de
                                an.
                              </div>
                            </Link>
                          ) : null}
                          {post.type === "updated" ? (
                            <Link
                              to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                              className="Link"
                              key={post.post_id}
                            >
                              <div className={styles.text}>
                                Dein Beitrag "{post.title}" wurde überarbeitet.
                                Möchtest du vielleicht noch etwas hinzufügen?
                              </div>
                            </Link>
                          ) : null}
                          {post.type === "reported" ? (
                            <Link
                              to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                              className="Link"
                              key={post.post_id}
                            >
                              <div className={styles.text}>
                                Der Beitrag "{post.title}" von{" "}
                                {post.first_name
                                  ? post.first_name
                                  : post.user_name}
                                {post.last_name ? post.last_name : null} wurde
                                von <em>kidipedia</em> genommen.
                              </div>
                            </Link>
                          ) : null}
                          {post.type === "publish" ? (
                            <Link
                              to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                              className="Link"
                              key={post.post_id}
                            >
                              <div className={styles.text}>
                                Wollen Sie den Beitrag "{post.title}" von{" "}
                                {post.first_name
                                  ? post.first_name
                                  : post.user_name}
                                {post.last_name ? post.last_name : null}
                                veröffentlichen?
                              </div>
                            </Link>
                          ) : null}
                          {post.type === "check" ? (
                            <Link
                              to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                              className="Link"
                              key={post.post_id}
                            >
                              <div className={styles.text}>
                                {post.first_name
                                  ? post.first_name
                                  : post.user_name}
                                {post.last_name ? post.last_name : null} hat den
                                Beitrag "{post.title}" überarbeitet. Schauen Sie
                                gerne einmal drüber.
                              </div>
                            </Link>
                          ) : null}
                          <div className={styles.row}>
                            <Link
                              to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                              className="Link"
                              key={post.post_id}
                            ></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>{" "}
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.notifications.loading,
    notifications: state.notifications.notifications,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seenNotifications: (id) => dispatch(seenNotifications(id)),
    getNotifications: () => dispatch(getNotifications()),
    reportPost: (postId, noteId, studendID, classId) => {
      console.log(postId, noteId, studendID, classId);
      dispatch(
        reportPost(postId, true, false, studendID, classId, "published", noteId)
      );
    },
    giveStars: (postId, teacherId, version, stars, author_id, class_id) =>
      dispatch(
        giveStars(
          postId,
          teacherId,
          version,
          stars,
          author_id,
          class_id,
          "rated"
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
