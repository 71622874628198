import React from "react";
import { connect } from "react-redux";
import styles from "./NavBar.module.css";
import { toggleHelpRedux } from "../../actions";
import { Link } from "react-router-dom";
import { AiOutlineQuestionCircle, AiFillQuestionCircle } from "react-icons/ai";
import kidi from "../../pictures/kidi.png";
import kidiHappy from "../../pictures/Kidistatus/happy.svg";
import kidiSit from "../../pictures/Kidistatus/sit.svg";
import kidiNeutral from "../../pictures/Kidistatus/neutral.svg";
import kidiSleep from "../../pictures/Kidistatus/sleep.svg";
import kidiMitmachen from "../../pictures/mitmachen.png";
import kidiAnschauen from "../../pictures/anschauen.png";
import meinKidi from "../../pictures/mein_kidi_normal.png";
import Logo from "../../pictures/Logo.svg";
import SearchBar from "../../components/SearchBar/SearchBar";
import kidiFlag from "../../pictures/kidi/Kidi_blau_mitFahne.svg";

const NavBar = (props) => {
  const {
    user,
    posts,
    help,
    helpText,
    postStatus,
    toggle,
    helpLoading,
    status,
    toggleHelpRedux,
    notifications,
  } = props;
  const handleHelp = () => {
    toggleHelpRedux();
  };

  const unseen = notifications.filter(
    (notification) => notification.seen === false
  );
  const translateRoles = () => {
    if (user.role_id === 7) {
      return "Lehrperson";
    } else if (user.role_id === 8) {
      return "Schuladmin";
    } else if (user.role_id === 9) {
      return "Redaktion";
    } else if (user.role_id === 10) {
      return "Admin";
    } else {
      return null;
    }
  };
  const name = user.user_name;

  const kidiStatus = () => {
    if (postStatus > status.air) {
      return <img src={kidiHappy} className="kidiStatus" alt="kidi" />;
    } else if (postStatus > status.neutral) {
      return <img src={kidiNeutral} className="kidiStatus" alt="kidi" />;
    } else if (postStatus > status.sad) {
      return <img src={kidiSit} className="kidiStatus" alt="kidi" />;
    } else if (postStatus <= status.sad) {
      return <img src={kidiSleep} className="kidiStatus" alt="kidi" />;
    } else {
      return <img src={kidi} className="kidi" alt="kidi" />;
    }
  };
  return (
    <nav>
      <div className="nav">
        <Link to="/">
          <div className="kidi-hitbox" onClick={() => props.setPost(0)}></div>
        </Link>
        {kidiStatus()}

        <div
          className="help"
          onClick={() => {
            handleHelp();
          }}
        >
          {toggle ? <AiFillQuestionCircle /> : <AiOutlineQuestionCircle />}
        </div>
        {helpLoading || !toggle ? null : (
          <div className="help-text">
            {helpText.type === "help" ? (
              <div
                className="speech-bubble"
                dangerouslySetInnerHTML={{ __html: help }}
              ></div>
            ) : null}
            {helpText.type === "error" ? (
              <div
                className="speech-bubble-error"
                dangerouslySetInnerHTML={{ __html: help }}
              ></div>
            ) : null}
            {helpText.type === "success" ? (
              <div
                className="speech-bubble-success"
                dangerouslySetInnerHTML={{ __html: help }}
              ></div>
            ) : null}
          </div>
        )}
        {posts ? <SearchBar posts={posts} /> : null}

        <img src={Logo} className="Logo" alt="Logo" />
        <ul>
          <div className="firstRow">
            {name ? (
              <div className={styles.messageLogin}>
                <div className={styles.blue}>{name}</div>
                <div className={styles.yellow}> | </div>
                {user.role_id > 6 ? (
                  <>
                    <div className={styles.red}> {translateRoles()}</div>
                    <div className={styles.yellow}> | </div>
                  </>
                ) : (
                  <>
                    <div className={styles.red}> {user.class}</div>
                    <div className={styles.yellow}> | </div>
                  </>
                )}

                <div className={styles.green}> {user.school} </div>
              </div>
            ) : null}
          </div>
          <div className="secondRow">
            <li>
              <Link to="/beiträge">
                <img
                  src={kidiMitmachen}
                  className="kidi-nav-beiträge"
                  alt="kidi"
                />
              </Link>
            </li>
            <li>
              <Link to="/stöbern">
                <img
                  src={kidiAnschauen}
                  className="kidi-nav-stöbern"
                  alt="kidi"
                />
              </Link>
            </li>
            <li>
              <Link to="/meinkidi">
                {unseen.length !== 0 ? (
                  <img
                    src={kidiFlag}
                    className="kidi-nav"
                    style={{ marginTop: "4px" }}
                    alt="flag"
                  />
                ) : (
                  <img src={meinKidi} className="kidi-nav" alt="kidi" />
                )}
              </Link>
            </li>
          </div>

          <div className="thirdRow">
            <li>
              <Link to="/beiträge" className="green">
                Beiträge
              </Link>
            </li>
            <li>
              <Link to="/stöbern" className="red">
                Stöbern
              </Link>
            </li>
            <li>
              <Link to="/meinkidi" className="blue">
                Mein kidi
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  );
};
const mapStateToProps = (state) => {
  return {
    helpText: state.helpTexts,
    help: state.helpTexts.help,
    loggedIn: state.user.loggedIn,
    user: state.user,
    posts: state.posts.posts,
    loading: state.posts.loading,
    helpLoading: state.helpTexts.loading,
    toggle: state.helpTexts.toggle,
    notifications: state.notifications.notifications,
    postStatus: state.posts.postStatus,
    status: state.posts.kidiStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    toggleHelpRedux: () => dispatch(toggleHelpRedux()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
