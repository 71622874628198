import * as actions from "../actions/types";

const initialState = {
  loading: false,
  loadingMyRating: false,
  stars: 0,
  ratings: 0,
  mostStars: [],
  myRating: 0,
  error: null,
};

const starRatings = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_STARS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_STARS_SUCCESS:
      return {
        ...state,
        loading: false,
        stars: action.payload.allStars / action.payload.ratings,
        ratings: action.payload.ratings,
      };
    case actions.GET_STARS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_RATING_REQUEST:
      return {
        ...state,
        loadingMyRating: true,
      };
    case actions.GET_RATING_SUCCESS:
      return {
        ...state,
        loadingMyRating: false,
        myRating: action.payload,
      };
    case actions.GET_RATING_FAILURE:
      return {
        ...state,
        loadingMyRating: false,
        error: action.payload.error,
      };
    case actions.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default starRatings;
