import * as actions from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  notes: [],
};

const postsNotes = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_NOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_NOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: action.payload,
      };
    case actions.GET_NOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.POST_NOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.POST_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: [
          { ...action.payload.note, user_name: action.payload.user_name },
          ...state.notes,
        ],
      };
    case actions.POST_NOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.DELETE_POSTNOTES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_POSTNOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: state.notes.filter((n) => n.id !== action.payload),
      };
    case actions.DELETE_POSTNOTES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default postsNotes;
