import React from "react";
import styles from "./Materials.module.css";
import { connect } from "react-redux";

const Materials = ({ pageContent }) => {
  const filteredContent = pageContent.pageContent.filter((c) => {
    return c.type === "Materialien";
  });

  return (
    <>
      {
        <div className="body-container">
          <div className="column">
            <h2>Materialien und Handreichungen:</h2>
            <div className={styles.width}>
              {filteredContent[0] ? (
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{ __html: filteredContent[0].text }}
                ></div>
              ) : null}
            </div>
          </div>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageContent: state.pageContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Materials);
