import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PostView from "../../components/PostView/PostView";
import { connect } from "react-redux";
import { getPost } from "../../actions";
import get from "lodash/get";
import Loading from "../../components/Loading/Loading";
import styles from "./ClassPost.module.css";

const ClassPost = (props) => {
  const { loading, getPost, classPosts, user } = props;
  const postId = get(props, "match.params.id");
  const postFilter = classPosts.filter((p) => p.id === postId)[0];

  const [post, setPost] = useState(postFilter);

  useEffect(() => {
    async function fetchPosts() {
      await getPost(postId).then((res) => setPost(res));
      /*      if(!classPosts){
        const posts = await getClassPosts(user.class_id);
        
      } */
    }

    fetchPosts();
  }, [getPost, postId]);
  return (
    <>
      <div className="body-container">
        <div className="column">
          {loading ? (
            <Loading />
          ) : (
            <>
              <PostView title={post.title} text={post.text} />
            </>
          )}

          {user.role < 7 ? (
            <div className="right" /* onClick={() => getPost(post.id)} */>
              <Link to={`/beiträge/überarbeiten`} className="Link">
                <button className={styles.button}>Beitrag bearbeiten</button>
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.post.loading,
    classPosts: state.posts.classPosts.posts,
    user: state.user,
    post: state.post.post,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPost: (postId) => dispatch(getPost(postId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClassPost);
