import React, { useEffect, useRef } from "react";
import L from "leaflet";
import { Map, TileLayer, FeatureGroup } from "react-leaflet";
import * as ELG from "esri-leaflet-geocoder";
import { EditControl } from "react-leaflet-draw";
/* import { SearchControl, OpenStreetMapProvider } from "react-leaflet-geosearch";
 */ import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";

const myIcon = L.icon({
  iconUrl: "/myIcons/Marker.svg",
  iconSize: [30, 45],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});
/* const provider = new OpenStreetMapProvider();
const GeoSearchControlElement = withLeaflet(SearchControl); */

const Leaflet = (props) => {
  const { setGeoJson, setLayerCount, layerCount } = props;
  const mapRef = useRef();
  const featureGroupRef = useRef();

  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    if (!map) return;

    L.control.scale({ imperial: false }).addTo(map);
    new ELG.Geosearch({
      position: "topleft",
      placeholder: "Suche hier nach Orten",
    }).addTo(map);
    new L.LayerGroup().addTo(map);
  }, []);
  /*   const propagateChanges = (e) => {
    setLayerCount(layerCount + 1);
    if (setGeoJson && e) {
      var geoJSON = featureGroupRef.current.leafletElement.toGeoJSON();
      let type = e.layerType;
      if (type === "circle") {
        var layer = e.layer;
        var radius = layer.getRadius();
        geoJSON.features[layerCount].properties.radius = radius;
        var geoJSON = featureGroupRef.current.leafletElement.toGeoJSON();
      } else {
        var geoJSON = featureGroupRef.current.leafletElement.toGeoJSON();
      }
      console.log("NUM", layerCount);
      console.log("geeeo", geoJSON);
    }
  }; */

  const propagateChanges = (e) => {
    if (setGeoJson && e) {
      var geoJSON = featureGroupRef.current.leafletElement.toGeoJSON();

      /*       geoJSON.features[layerCount].properties.id = layerCount;
       */
      setGeoJson(geoJSON);
      setLayerCount(layerCount + 1);
    }
  };

  var circleToGeoJSON = L.Circle.prototype.toGeoJSON;
  L.Circle.include({
    toGeoJSON: function () {
      var feature = circleToGeoJSON.call(this);
      feature.properties = {
        point_type: "circle",
        radius: this.getRadius(),
      };
      return feature;
    },
  });

  if (props.deleteGeo) {
    featureGroupRef.current.leafletElement.clearLayers();
    props.setDeleteGeo(false);
  }
  // const deleteGeoJson = (e) => {
  //   featureGroupRef.current.leafletElement.clearLayers();
  // };

  const _onEdited = (e) => {
    let numEdited = 0;
    e.layers.eachLayer((layer) => {
      numEdited += 1;
    });
    console.log(`_onEdited: edited ${numEdited} layers`, e);
    propagateChanges(e);
  };

  const _onCreated = (e) => {
    let type = e.layerType;

    // let layer = e.layer;
    propagateChanges(e);

    if (type === "marker") {
      // Do marker specific actions
      console.log("_onCreated: marker created", e);
    } else {
      console.log("_onCreated: something else created:", type, e);
    }
    // Do whatever else you need to. (save to db; etc)
  };

  const _onDeleted = (e) => {
    propagateChanges(e);
  };

  const _onMounted = (drawControl) => {
    console.log("_onMounted", drawControl);
  };

  const _onEditStart = (e) => {
    console.log("_onEditStart", e);
  };

  const _onEditStop = (e) => {
    console.log("_onEditStop", e);
  };

  const _onDeleteStart = (e) => {
    console.log("_onDeleteStart", e);
  };

  const _onDeleteStop = (e) => {
    console.log("_onDeleteStop", e);
  };
  const _onzoomend = (e) => {
    const zoom = mapRef.current.leafletElement.getZoom();
    props.setZoom(zoom);
  };
  const _onmoveend = () => {
    const center = mapRef.current.leafletElement.getCenter();

    props.setLat(center.lat);
    props.setLng(center.lng);
  };

  L.drawLocal = {
    draw: {
      toolbar: {
        // #TODO: this should be reorganized where actions are nested in actions
        // ex: actions.undo  or actions.cancel
        actions: {
          title: "Abbrechen",
          text: "Abbrechen",
        },
        finish: {
          title: "Beenden",
          text: "Beenden",
        },
        undo: {
          title: "Rückgängig",
          text: "Rückgängig",
        },
        buttons: {
          polyline: "Linie",
          polygon: "Polygon",
          rectangle: "Viereck",
          circle: "Kreis",
          marker: "Markierung",
          circlemarker: "Kreismarkierung",
        },
      },
      handlers: {
        circle: {
          tooltip: {
            start: "Klicke auf den ersten Punkt und beende deine Fläche",
          },
          radius: "Radius",
        },
        circlemarker: {
          tooltip: {
            start: "Lasse die Maus los und beende deine Kreisfläche",
          },
        },
        marker: {
          tooltip: {
            start: "Klicke und füge einen Marker ein",
          },
        },
        polygon: {
          tooltip: {
            start: "Klicke, setze Punkte und spanne eine Fläche auf",
            cont: "Füge einen weiteren Punkt hinzu",
            end: "Beende deine Fläche, indem du auf deinen ersten Punkt klickst",
          },
        },
        polyline: {
          error: "<strong>Error:</strong> Die Ränder dürfen sich nicht kreuzen",
          tooltip: {
            start: "Klicke und starte deine Linie",
            cont: "Klicke und setze einen Punkt in deiner Linie",
            end: "Klicke und beende die Linie",
          },
        },
        rectangle: {
          tooltip: {
            start:
              "Klicke, halte die Maus gedrückt und ziehe deine Rechteckfläche ",
          },
        },
        simpleshape: {
          tooltip: {
            end: "Lasse die Maus los und beende das Zeichnen",
          },
        },
      },
    },
    edit: {
      toolbar: {
        actions: {
          save: {
            title: "Speichern",
            text: "Speichern",
          },
          cancel: {
            title: "Abbrechen",
            text: "Abbrechen",
          },
          clearAll: {
            title: "Lösche alle Flächen",
            text: "Lösche alle Flächen",
          },
        },
        buttons: {
          edit: "Fläche bearbeiten",
          editDisabled: "Keine Fläche zum Bearbeiten",
          remove: "Lösche alle Flächen",
          removeDisabled: "Keine Flächen zum Löschen",
        },
      },
      handlers: {
        edit: {
          tooltip: {
            text: "Klicke auf die Punkte und verschiebe sie",
            subtext:
              "Klicke auf Abbrechen um das Bearbeiten rückgänig zu machen",
          },
        },
        remove: {
          tooltip: {
            text: "Klicke auf deinen Marker, deine Linie oder deine Fläche und lösche sie",
          },
        },
      },
    },
  };

  /*  L.control.scale().addTo(Map); */
  /*   L.esri.Geocoding.geosearch.placeholder = "test";

  
   */
  return (
    <Map
      ref={mapRef}
      id="mapid"
      center={[props.lat, props.lng]}
      zoom={props.zoom}
      doubleClickZoom={false}
      closePopupOnClick={false}
      dragging={true}
      trackResize={false}
      touchZoom={false}
      scrollWheelZoom={false}
      onzoomend={_onzoomend}
      onmoveend={_onmoveend}
    >
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="topright"
          onEdited={_onEdited}
          onCreated={_onCreated}
          onDeleted={_onDeleted}
          onMounted={_onMounted}
          onEditStart={_onEditStart}
          onEditStop={_onEditStop}
          onDeleteStart={_onDeleteStart}
          onDeleteStop={_onDeleteStop}
          draw={{
            rectangle: {
              shapeOptions: {
                color: "var(--blueStroke)",
                weight: 5,
                opacity: 0.65,
              },
            },
            marker: {
              icon: myIcon,
            },
            circle: {
              shapeOptions: {
                color: " var(--blueStroke)",
                weight: 5,
                opacity: 0.65,
              },
            },
            polyline: {
              shapeOptions: {
                color: " var(--blueStroke)",
                weight: 5,
                opacity: 0.65,
              },
            },
            circlemarker: false,

            polygon: {
              allowIntersection: false,
              shapeOptions: {
                color: " var(--blueStroke)",
                weight: 5,
                opacity: 0.65,
              },
            },
            edit: {
              remove: false,
            },
          }}
        />
        {/*      <Circle center={[51.51, -0.06]} radius={200} /> */}

        {/*         <GeoSearchControlElement
          provider={provider}
          showMarker={false}
          showPopup={false}
          popupFormat={({ query, result }) => result.label}
          maxMarkers={3}
          retainZoomLevel={false}
          animateZoom={true}
          autoClose={false}
          searchLabel={"Suche hier ..."}
          keepResult={true}
          countrycodes={"nl"}
          language={"de"}
        /> */}
      </FeatureGroup>

      {props.layer ? (
        <TileLayer
          attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        />
      ) : (
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"
        />
      )}
    </Map>
  );
};

export default Leaflet;
