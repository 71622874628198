import * as actions from "../actions/types";

const initialState = {
  loading: false,
  notifications: [],
  error: null,
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
      };
    case actions.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.SEEN_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.SEEN_NOTIFICATIONS_SUCCESS:
      const newArr = state.notifications.filter(
        (n) => n.id !== action.payload.id
      );
      return {
        ...state,
        loading: false,
        notifications: [...newArr, action.payload],
      };
    case actions.SEEN_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default notifications;
