import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
// import tinymce from "tinymce/tinymce";
import config from "../../config";
import styles from "./TinyMCE.module.css";
import "tinymce/skins/ui/oxide/skin.min.css";
import { pinselRot } from "../../pictures/Editor/Pinsel_rot";
import { pinselBlau } from "../../pictures/Editor/Pinsel_blau";
import { pinselSchwarz } from "../../pictures/Editor/Pinsel_schwarz";
import { smallFontSize } from "../../pictures/Editor/Schrift_klein";
import { middleFontSize } from "../../pictures/Editor/Schrift_mittel";
import { bigFontSize } from "../../pictures/Editor/Schrift_gross";
import { bulletpoints } from "../../pictures/Editor/Auflistung_Bulletpoints";
import { tabelle } from "../../pictures/Editor/Tabelle";
import { unterstreichen } from "../../pictures/Editor/Schrift_unterstrichen";
import { karte } from "../../pictures/Editor/Karte";
import Leaflet from "./Leaflet";
import { GrClose } from "react-icons/gr";
import { connect } from "react-redux";
import { getHelp } from "../../actions";
// import { Map, TileLayer, Marker, Popup } from "react-leaflet";
// import { renderToString, renderToStaticMarkup } from "react-dom/server";

import "tinymce/tinymce";
import Axios from "axios";

const TinyMCE12 = (props) => {
  const { getHelp } = props;
  const [modal, setModal] = useState(false);
  const [layerSatellit, setLayerSatellit] = useState(false);
  const [ed, setEditor] = useState(null);
  const [geoJson, setGeoJson] = useState(null);
  const [lat, setLat] = useState(51.5167);
  const [lng, setLng] = useState(9.9167);
  const [zoom, setZoom] = useState(5);

  const handleLayer = () => {
    setLayerSatellit(!layerSatellit);
  };

  const handleTitleChange = (event) => {
    props.setTitle(event);
  };
  const handleTextChange = (text) => {
    props.setText(text);
  };

  const insertMap = () => {
    const dataJson = `${JSON.stringify(geoJson)}`;

    const geoData = encodeURIComponent(btoa(JSON.stringify(dataJson)));
    ed.insertContent(
      `<iframe style="width: 650px;height: 450px;" src="${config.API_ENDPOINT}/leaflet?sat=${layerSatellit}&lat=${lat}&lng=${lng}&zoom=${zoom}&geo=${geoData}" title="leaflet"></iframe>`
    );
    setModal(false);
    setGeoJson(null);
    setLat(51.5167);
    setLng(9.9167);
    setZoom(5);
  };

  return (
    <>
      <div className={modal ? styles.modal : styles.noModal}>
        <div className={styles.frame}>
          <div className={styles.crossContainer}>
            <div className={styles.toggle} onClick={handleLayer}>
              {layerSatellit ? "Karte" : "Satellit"}
            </div>
            <GrClose className={styles.cross} onClick={() => setModal(false)} />
          </div>
          <Leaflet
            layer={layerSatellit}
            onChange={setGeoJson}
            geoJson={geoJson}
            setLat={setLat}
            lat={lat}
            setLng={setLng}
            lng={lng}
            setZoom={setZoom}
            zoom={zoom}
          />

          <button className="button" onClick={insertMap}>
            Karte einfügen
          </button>
        </div>
      </div>
      <input
        className="input-title"
        type="text"
        value={props.title}
        placeholder="Überschrift"
        onChange={handleTitleChange}
        ref={props.titleInput}
      />

      <Editor
        /* apiKey="ty082cngqvyq19zypeopn3qcrg74uvpodg4c06za3o0cv6xm" */
        init={{
          height: 500,
          content_style:
            "body { font-family: Grundschrift, serif; font-size:18px }",
          maxwidth: 2000,
          /*           images_upload_url: `${config.API_ENDPOINT}/api/upload`,
           */
          images_upload_handler: function (blobInfo, success, failure) {
            let data = new FormData();
            data.append("file", blobInfo.blob(), blobInfo.filename());
            Axios({
              method: "POST",
              url: `${config.API_ENDPOINT}/api/upload`,
              headers: {
                "auth-token": localStorage.getItem("token"),
              },
              data: data,
            }).then(
              ({ data }) => {
                success(data.location);
              },
              (error) => {
                console.error(error);
                getHelp("Die Datei ist wahrscheinlich zu gross", "error");
                failure("Da ging was schief. Probiere es erneut.");
              }
            );
          },
          menubar: false,
          table_toolbar:
            "tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
          selector: "textarea",
          placeholder: "Schreibe hier deinen Beitrag",
          language: "de",
          paste_data_images: true,
          images_file_types: "jpg, jpeg, JPG, png, svg, webp",
          language_url: "/langs/de.js",
          extended_valid_elements:
            "iframe[src|frameborder|style|scrolling|class|width|height|name|align]",

          skin_url: "/skins/ui/oxide",
          // media_live_embeds: true,
          default_link_target: "_blank",
          target_list: false,
          media_alt_source: false,
          media_poster: false,
          media_dimensions: false,
          file_picker_types: "media,file",
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");

            if (meta.filetype === "file") {
              input.setAttribute("accept", "application/pdf");
            } else if (meta.filetype === "media") {
              input.setAttribute("accept", "video/*");
            }

            /*
              Note: In modern browsers input[type="file"] is functional without
              even adding it to the DOM, but that might not be the case in some older
              or quirky browsers like IE, so you might want to add it to the DOM
              just in case, and visually hide it. And do not forget do remove it
              once you do not need it anymore.
            */

            input.onchange = function () {
              var file = this.files[0];

              const formData = new FormData();
              formData.append("file", file);
              Axios({
                method: "POST",
                url: `${config.API_ENDPOINT}/api/upload/files`,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                data: formData,
              }).then(
                ({ data }) => {
                  if (data && data.location) {
                    cb(data.location, { title: file.name });
                  } else {
                    console.error("Could not process file upload.");
                  }
                },
                (error) => {
                  console.error(error);
                  getHelp("Die Datei ist wahrscheinlich zu gross", "error");
                }
              );

              // var reader = new FileReader();
              // reader.onload = function () {
              /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
              // var id = "blobid" + new Date().getTime();
              // var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              // var base64 = reader.result.split(",")[1];
              // var blobInfo = blobCache.create(id, file, base64);
              // blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              // cb(blobInfo.blobUri(), { title: file.name });
              // };
              // reader.readAsDataURL(file);
            };

            input.click();
          },
          /*           image_description: false,
          image_dimensions: false,
          image_title: false, */
          ////SETUP
          setup: function (editor) {
            setEditor(editor);
            editor.on("click", (e) => {});
            editor.on("PostProcess", (e, bla, blu) => {});
            editor.ui.registry.addButton("smallFontSize", {
              text: `<svg class="icon" aria-hidden="true">${smallFontSize}</svg>`,
              tooltip: "kleine Schriftgröße",
              onAction: function (_) {
                editor.execCommand("FontSize", false, "15px");
              },
            });
            editor.ui.registry.addButton("middleFontSize", {
              text: `<svg class="icon" aria-hidden="true">${middleFontSize}</svg>`,
              tooltip: "mittlere Schriftgröße",
              onAction: function (_) {
                editor.execCommand("FontSize", false, "18px");
              },
            });
            editor.ui.registry.addButton("bigFontSize", {
              text: `<svg class="icon" aria-hidden="true">${bigFontSize}</svg>`,
              tooltip: "große Schriftgröße",
              onAction: function (_) {
                editor.execCommand("FontSize", false, "25px");
              },
            });
            editor.ui.registry.addButton("colorRed", {
              text: `<svg class="icon" aria-hidden="true">${pinselRot}</svg>`,
              tooltip: "rote Schrift",
              onAction: function (_) {
                editor.execCommand("ForeColor", false, "#cd2258");
              },
            });
            editor.ui.registry.addButton("colorBlue", {
              text: `<svg class="icon" aria-hidden="true">${pinselBlau}</svg>`,
              tooltip: "blaue Schrift",
              onAction: function (_) {
                editor.execCommand("ForeColor", false, "#3a809e");
              },
            });
            editor.ui.registry.addButton("colorBlack", {
              text: `<svg class="icon" aria-hidden="true">${pinselSchwarz}</svg>`,
              tooltip: "schwarze Schrift",
              onAction: function (_) {
                editor.execCommand("ForeColor", false, "black");
              },
            });
            editor.ui.registry.addButton("bulletList", {
              text: `<svg class="icon" aria-hidden="true">${bulletpoints}</svg>`,
              tooltip: "Auflistung",
              onAction: function (_) {
                editor.execCommand("InsertUnorderedList");
              },
            });
            editor.ui.registry.addButton("underlineNew", {
              text: `<svg class="icon" aria-hidden="true">${unterstreichen}</svg>`,

              tooltip: "unterstreichen",
              onAction: function (_) {
                editor.execCommand("Underline");
              },
            });
            editor.ui.registry.addButton("maps", {
              text: `<svg class="icon" aria-hidden="true">${karte}</svg>`,
              tooltip: "füge eine Karte hinzu",
              onAction: function (_) {
                setModal(true);
              },
            });
            editor.ui.registry.addMenuButton("inserttable", {
              text: `<svg class="icon-table" aria-hidden="true">${tabelle}</svg>`,
              fetch: (callback) =>
                callback([
                  {
                    type: "fancymenuitem",
                    fancytype: "inserttable",
                    onAction: (data) => {
                      editor.execCommand("mceInsertTable", false, {
                        rows: data.numRows,
                        columns: data.numColumns,
                      });
                    },
                  },
                ]),
            });
          },
          plugins: ["media", "table", "link"],
          external_plugins: {
            custom_image: `${config.API_ENDPOINT}/public/scripts/tinymce/custom_image.js`,
            custom_media: `${config.API_ENDPOINT}/public/scripts/tinymce/custom_media.js`,
          },
          toolbar:
            " | colorRed  colorBlack  colorBlue |  middleFontSize bigFontSize  |underlineNew    | custom_image custom_media ",
        }}
        value={props.text}
        onEditorChange={handleTextChange}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHelp: (res, type) => dispatch(getHelp(res, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TinyMCE12);
