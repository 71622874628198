import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getHelp } from "../../actions";
import NeuerBeitrag from "../../pictures/Beiträge/Neuer_Beitrag.svg";
import MeineBeitraege from "../../pictures/Beiträge/Meine_Beitraege.svg";
import Klassenbeitraege from "../../pictures/Beiträge/Klassenbeitraege.svg";
import notLoggedIn from "../../pictures/Szene/Szene_grun.svg";

import "./Beiträge.css";

const Beiträge = ({ getHelp, loggedIn, pageContent }) => {
  useEffect(() => {
    getHelp();
  }, [getHelp]);

  const filteredContent = pageContent.pageContent.filter((c) => {
    return c.type === "Beitrage";
  });
  return (
    <>
      {localStorage.getItem("token") ? (
        <div className="body-container">
          <div className="grid-container">
            <div className="grid-mitmachen">
              <Link
                to={{
                  pathname: `/beiträge/neuerBeitrag`,
                  /*    state: { id: name.id }, */
                }}
                className="text-decoration"
              >
                <div className="box1">
                  <div className="column">
                    <img
                      src={NeuerBeitrag}
                      alt={`Neuer Beitrag`}
                      className="pictureBeiträge"
                    />
                    <div className="text-decoration">Neuer Beitrag</div>
                  </div>
                </div>
              </Link>
              <Link
                to={{
                  pathname: `/beiträge/meineBeiträge`,
                  /*    state: { id: name.id }, */
                }}
                className="text-decoration"
              >
                <div className="box1">
                  <div className="column">
                    <img
                      src={MeineBeitraege}
                      alt={`Meine Beitraege`}
                      className="pictureBeiträge"
                    />
                    <div className="text-decoration">Meine Beiträge</div>
                  </div>
                </div>
              </Link>
              <Link
                to={{
                  pathname: `/beiträge/Klassenbeiträge`,
                  /*    state: { id: name.id }, */
                }}
                className="text-decoration"
              >
                <div className="box1">
                  <div className="column">
                    <img
                      src={Klassenbeitraege}
                      alt={`Klassenbeitraege`}
                      className="pictureBeiträge"
                    />
                    <div className="text-decoration">Klassenbeiträge</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          {" "}
          <img
            src={notLoggedIn}
            alt="du bist leider nicht angemeldet"
            className="pictures"
          />
          <div className="column welcometexts">
            {filteredContent[0] ? (
              <div
                dangerouslySetInnerHTML={{ __html: filteredContent[0].text }}
              ></div>
            ) : null}
          </div>
        </div>
      )}

      {/*   <Switch>
          <Route path="/mitmachen/neuerBeitrag" component={TinyMCE} />
        </Switch> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.user.loggedIn,
    pageContent: state.pageContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    /*  getPosts: () => dispatch(getPosts()), */

    getHelp: () => dispatch(getHelp(4)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Beiträge);
