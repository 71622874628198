import * as actions from "../actions/types";

const initialState = {
  loading: false,
  posts: [],
  allPosts: [],
  myPosts: [],
  classPosts: { loading: false, posts: [] },
  mostStars: [],
  postsIWorkedOn: [],
  searchResult: [],
  kidiStatus: 0,
  postStatus: "",
  error: null,
};

const posts = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        posts: action.payload,
      };
    //[ ...state.posts,action.payload],
    case actions.GET_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_ALL_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ALL_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        allPosts: action.payload,
      };
    case actions.GET_ALL_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_MY_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_MY_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        myPosts: action.payload,
      };
    case actions.GET_MY_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_MOST_STARS_REQUEST:
      return {
        ...state,
      };
    case actions.GET_MOST_STARS_SUCCESS:
      return {
        ...state,

        error: null,
        mostStars: action.payload,
      };
    case actions.GET_MOST_STARS_FAILURE:
      return {
        ...state,

        error: action.payload.error,
      };
    case actions.GET_KIDI_STATUS_REQUEST:
      return {
        ...state,
      };
    case actions.GET_KIDI_STATUS_SUCCESS:
      return {
        ...state,
        kidiStatus: action.payload,
      };
    case actions.GET_KIDI_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case actions.UPDATE_KIDI_STATUS_REQUEST:
      return {
        ...state,
      };
    case actions.UPDATE_KIDI_STATUS_SUCCESS:
      return {
        ...state,
        kidiStatus: action.payload,
      };
    case actions.UPDATE_KIDI_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case actions.GET_POSTS_STATUS_REQUEST:
      return {
        ...state,
      };
    case actions.GET_POSTS_STATUS_SUCCESS:
      return {
        ...state,
        postStatus: action.payload,
      };
    case actions.GET_POSTS_STATUS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case actions.GET_CLASS_POSTS_REQUEST:
      return {
        ...state,
        classPosts: { loading: true },
      };
    case actions.GET_CLASS_POSTS_SUCCESS:
      return {
        ...state,
        error: null,
        classPosts: { loading: false, posts: action.payload },
      };
    case actions.GET_CLASS_POSTS_FAILURE:
      return {
        ...state,
        classPosts: { loading: false, posts: [], error: action.payload.error },
      };
    case actions.SEARCH_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.SEARCH_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResult: action.payload,
      };
    case actions.SEARCH_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_POSTS_I_WORKED_ON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_POSTS_I_WORKED_ON_SUCCESS:
      return {
        ...state,
        loading: false,
        postsIWorkedOn: action.payload,
      };
    case actions.GET_POSTS_I_WORKED_ON_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.POST_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.POST_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        myPosts: [...state.myPosts, action.payload],
        classPosts: {
          loading: false,
          posts: [...state.classPosts.posts, action.payload],
        },
      };

    case actions.POST_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.DELETE_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_POST_SUCCESS:
      return {
        ...state,
        loading: false,

        posts: state.posts.filter((p) => p.id !== action.payload),
        allPosts: state.allPosts.filter((p) => p.id !== action.payload),
        myPosts: state.myPosts.filter((p) => p.id !== action.payload),
        classPosts: {
          loading: false,
          posts: state.classPosts.posts.filter((p) => p.id !== action.payload),
        },
        mostStars: state.mostStars.filter((p) => p.id !== action.payload),
      };
    case actions.DELETE_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.UPDATE_POST_SUCCESS:
      const newArrPosts = state.posts.filter(
        (post) => post.id !== action.payload.id
      );
      const newArrMyPosts = state.myPosts.filter(
        (post) => post.id !== action.payload.id
      );
      const newArrClassPosts = state.classPosts.posts.filter(
        (post) => post.id !== action.payload.id
      );
      return {
        ...state,
        loading: false,
        posts: [...newArrPosts, action.payload],
        myPosts: [...newArrMyPosts, action.payload],
        classPosts: {
          posts: [...newArrClassPosts, action.payload],
        },
      };
    case actions.REPORT_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.REPORT_POST_SUCCESS:
      const post = action.payload;
      if (post.published === true && post.reported === false) {
        return {
          ...state,
          loading: false,
          error: null,
          posts: [...state.posts, action.payload],
          classPosts: {
            loading: false,
            posts: [...state.classPosts.posts, action.payload],
          },
        };
      }
      if (post.published === false && post.reported === true) {
        return {
          ...state,
          loading: false,

          posts: state.posts.filter((p) => p.id !== action.payload.id),
          allPosts: state.allPosts.filter((p) => p.id !== action.payload.id),
          myPosts: state.myPosts.filter((p) => p.id !== action.payload.id),
          classPosts: {
            loading: false,
            posts: state.classPosts.posts.filter(
              (p) => p.id !== action.payload.id
            ),
          },
          mostStars: state.mostStars.filter((p) => p.id !== action.payload.id),
        };
      } else {
        return state;
      }

    case actions.REPORT_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default posts;
