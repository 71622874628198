import * as actions from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  categories: [],
  posts: [],
};

const categories = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case actions.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_CATEGORY_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CATEGORY_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        posts: action.payload,
      };
    case actions.GET_CATEGORY_POSTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.POST_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.POST_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: [...state.categories, action.payload],
      };
    case actions.POST_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_CATEGORY_SUCCESS:
      const newArr = state.categories.filter(
        (category) => category.id !== action.payload.id
      );
      const arr = [action.payload, ...newArr];
      const sortedArr = arr.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );
      return {
        ...state,
        loading: false,
        categories: sortedArr,
      };
    case actions.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.filter(
          (category) => category.id !== action.payload
        ),
      };
    case actions.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default categories;
