import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getParentId } from "../../actions";
import get from "lodash/get";
import styles from "../Stöbern/Stöbern.module.css";
import Loading from "../../components/Loading/Loading";
import PostList from "../../components/PostList/PostList";
import "./Categories.css";
import { TiArrowUnsorted } from "react-icons/ti";

const Categories = (props) => {
  const { getParentId, posts, subCategories, loading } = props;
  const parentName = get(props, "match.params.name");
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    async function fetchCategories() {
      await getParentId(parentName);
    }
    fetchCategories();
  }, [getParentId, parentName]);

  useEffect(() => {
    setPostList(posts);
  }, [posts]);

  const sorting = (property) => {
    const copy = [...postList];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] !== null && b[property] !== null) {
        const nameA = a[property].toLowerCase();
        const nameB = b[property].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        return 0;
      }
    });
    setPostList(copy);

    if (copy[0].id === postList[0].id) {
      copy.reverse();
    }
  };

  return (
    <>
      <div className="body-container column">
        <h3>{parentName}</h3>
        <div className={styles.gridFlex}>
          {loading ? (
            <div>Loading</div>
          ) : (
            subCategories.map((name) => (
              <div key={name.id}>
                <Link
                  to={{
                    pathname: `/stöbern/${parentName}/${name.name}`,
                    /*    state: { id: name.id }, */
                  }}
                  className={styles.textDecoration}
                  key={name.id}
                >
                  <div className={styles.column}>
                    {name.imageurl ? (
                      <img
                        /*                           src={require(`../../pictures/Kategoriebilder_aktuell/${name.name}.svg`)}
                         */ alt={`${name.name}`}
                        src={name.imageurl}
                        className={styles.picture}
                      />
                    ) : null}

                    <div className={styles.textDecoration}>
                      {name.name} ({name.count})
                    </div>
                  </div>
                </Link>
              </div>
            ))
          )}
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            {postList.length !== 0 ? (
              <div className="list-header-row">
                <div onClick={() => sorting("title")}>
                  Titel <TiArrowUnsorted />
                </div>
                <div onClick={() => sorting("user_name")}>
                  verfasst von <TiArrowUnsorted />
                </div>
                <div onClick={() => sorting("creation_date")}>
                  erschienen am <TiArrowUnsorted />
                </div>
                <div onClick={() => sorting("update_author_name")}>
                  bearbeitet von <TiArrowUnsorted />
                </div>
                <div onClick={() => sorting("last_update_date")}>
                  bearbeitet am <TiArrowUnsorted />
                </div>
                <div onClick={() => sorting("edit_lock_user_id")}>
                  in Bearbeitung von <TiArrowUnsorted />
                </div>
              </div>
            ) : null}

            {postList.map((post) => (
              <Link
                to={`/stöbern/kategorien/beitrag/${post.id}`}
                className="Link"
                key={post.id}
              >
                <PostList
                  id={post.id}
                  role={post.class_role}
                  title={post.title}
                  date={post.creation_date}
                  userName={post.user_name}
                  updateDate={post.last_update_date}
                  updateUserName={post.update_author_name}
                  editLockUserName={post.edit_lock_user_name}
                  aquiredEditLockAt={post.aquired_edit_lock_at}
                />
              </Link>
            ))}
          </>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    subCategories: state.subCategories.subCategories,
    loading: state.subCategories.loading,
    posts: state.categories.posts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getParentId: (parentName) => dispatch(getParentId(parentName)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
