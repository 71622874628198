export const tabelle = `<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
.st0tab{fill:#F1F9FC;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
.st1tab{fill:#CED5D8;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
.st2tab{fill:none;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
</style>
<g>
<path class="st0tab" d="M89.4,81.9H10.3c-2.3,0-4.2-1.9-4.2-4.2V23.4c0-2.3,1.9-4.2,4.2-4.2h79.1c2.3,0,4.2,1.9,4.2,4.2v54.3
   C93.6,80,91.7,81.9,89.4,81.9z"/>
<path class="st1tab" d="M93.6,27.3v-3.9c0-2.3-1.9-4.2-4.2-4.2H10.3c-2.3,0-4.2,1.9-4.2,4.2v3.9C6.1,27.3,93.6,27.3,93.6,27.3z"/>
<line class="st2tab" x1="32.4" y1="19.1" x2="32.3" y2="81.9"/>
<line class="st2tab" x1="65.4" y1="19.1" x2="65.3" y2="81.9"/>
<line class="st2tab" x1="93.7" y1="45.3" x2="5.9" y2="45.3"/>
<line class="st2tab" x1="93.8" y1="65.7" x2="6" y2="65.7"/>
</g>
</svg>
`;
