import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getHelp,
  toggleHelpRedux,
  postClass,
  updateClass,
  getClassDetails,
  deleteClasses,
} from "../../../actions";
import styles from "./Classes.module.css";
import UpdateModal from "../../../components/UpdateModal/UpdateModal";
import { MdDelete, MdEdit } from "react-icons/md";

const Classes = ({
  getClassDetails,
  getHelp,
  toggleHelpRedux,
  classesRedux,
  postClass,
  updateClass,
  user,
  deleteClasses,
}) => {
  const [modalClass, setModalClass] = useState(false);
  const [className, setClassName] = useState("");
  const [classesList, setClassesList] = useState([]);

  const [modalUpdate, setModalUpdate] = useState(false);
  const [editId, setEditId] = React.useState();
  const [updatedName, setupdatedName] = React.useState("");

  useEffect(() => {
    async function fetchClassDetails() {
      await getClassDetails(user.class_id);
    }
    fetchClassDetails();
  }, [getClassDetails, user.class_id]);

  useEffect(() => {
    setClassesList(classesRedux.classDetails);
  }, [classesRedux.classDetails]);

  const handlePostClass = () => {
    postClass(className, user.school_id, user.id)
      .then((res) => {
        setModalClass(false);
        setClassName("");
        getHelp("Neue Klasse wurde angelegt", "success");
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
      })
      .catch((err) => {
        getHelp(err.message, "error");
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
      });
  };

  const handleEdit = (c) => {
    setModalUpdate(true);
    setupdatedName(c.class_name);
    setEditId(c.id);
  };
  const handleChangeClass = () => {
    updateClass(editId, {
      class_name: updatedName,
    }).then(setModalUpdate(false));
  };
  return (
    <div className="body-container">
      <div className={styles.column}>
        <div className={modalClass ? styles.modal : styles.noModal}>
          <div className={styles.frame}>
            <h4>Füge eine neue Klasse hinzu:</h4>
            <input
              className={styles.input}
              placeholder="Name der Klasse *"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
            ></input>

            <div className={`${styles.row} ${styles.inputRow}`}>
              <button
                className={`button ${styles.margin}`}
                onClick={() => handlePostClass()}
              >
                speichern
              </button>
              <button
                className={`button ${styles.margin}`}
                onClick={() => setModalClass(false)}
              >
                abbrechen
              </button>
            </div>
          </div>
        </div>

        <div className={modalUpdate ? styles.modal : styles.noModal}>
          <UpdateModal
            inputFields={[
              {
                input: updatedName,
                state: setupdatedName,
                placeholder: "Name",
              },
            ]}
            setModal={setModalUpdate}
            handleChangeCategory={handleChangeClass}
          />
        </div>

        <div className={styles.row}>
          <h3>Dies sind ihre Klassen</h3>
          <div className={styles.add} onClick={() => setModalClass(true)}>
            + neue Klasse hinzufügen
          </div>
        </div>
        {classesRedux.loading ? null : (
          <div className={styles.height}>
            {classesList.map((c) => (
              <div className={styles.row} key={c.id}>
                <div className={styles.list}> {c.class_name}</div>

                <div className="row">
                  <div
                    className={styles.delete}
                    onClick={() => {
                      handleEdit(c);
                    }}
                  >
                    <MdEdit className={styles.icons} />
                  </div>
                  <div
                    className={styles.delete}
                    onClick={() => {
                      if (
                        window.confirm(
                          `Die Klasse "${c.class_name}" wirklich löschen? Achtung: Es werden auch alle Schülerinnen und Schüler dieser Klasse gelöscht`
                        )
                      ) {
                        deleteClasses(c.id);
                      }
                    }}
                  >
                    <MdDelete className={styles.icons} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    posts: state.posts.allPosts,
    loading: state.posts.loadings,
    user: state.user,
    classesRedux: state.classes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHelp: (res, type) => dispatch(getHelp(res, type)),
    toggleHelpRedux: (boolean) => dispatch(toggleHelpRedux(boolean)),
    postClass: (className, schoolId, id) =>
      dispatch(postClass(className, schoolId, id)),
    getClassDetails: (classId) => {
      dispatch(getClassDetails(classId));
    },
    deleteClasses: (id) => dispatch(deleteClasses(id)),
    updateClass: (id, data) => dispatch(updateClass(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Classes);
