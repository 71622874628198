import React from "react";

const Kontakt = () => {
  return (
    <>
      <div>Hello Kids</div>
    </>
  );
};

export default Kontakt;
