import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import styles from "./SearchBar.module.css";

const SearchBar = (props) => {
  const [input, setInput] = useState("");
  const [searchTerm, setSearchTerm] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const renderList = () => {
    return props.posts.map((post) => ({
      label: post.title,
      value: post.id,
    }));
  };
  const handleChange = (selectedOption) => {
    if (selectedOption && localStorage.getItem("token")) {
      const label = selectedOption.label;
      setSearchTerm(label);

      if (label === searchTerm) {
        setSearchTerm(null);
      }
    } else if (!localStorage.getItem("token")) {
      setRedirect(true);
    } else {
      setSearchTerm(null);
    }
  };

  const handleInput = (e) => {
    if (e.key === "Enter" && localStorage.getItem("token")) {
      enterPressed();
    } else {
      setInput(e.target.value);
    }
  };
  const enterPressed = () => {
    if (input && localStorage.getItem("token")) {
      setSearchTerm(input);
    } else {
      setRedirect(true);
    }
  };

  return (
    <>
      <div
        text={searchTerm}
        onKeyUp={handleInput} /* onKeyPress={enterPressed} */
      >
        <Select
          className={styles.inputfield}
          /* theme={customTheme} */
          onChange={handleChange}
          //name={setSearchTerm}
          value={searchTerm}
          options={renderList()}
          type="search"
          placeholder="suchen ..."
          isClearable
          classNamePrefix="Searchbar"
          noOptionsMessage={() => "Kein Beitrag gefunden"}
          /* menuIsOpen={true} */
        />
      </div>
      {searchTerm !== null ? (
        <Redirect to={`/beiträge/suche/${searchTerm}`} />
      ) : null}
      {redirect ? <Redirect to={`/stöbern`} /> : null}
    </>
  );
};

export default SearchBar;
