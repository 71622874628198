import React from "react";
import styles from "./UpdateModal.module.css";

const PostView = (props) => {
  return (
    <div className={styles.frame}>
      <h3>Bearbeite hier diesen Inhalt</h3>
      <div className={styles.innerFrame}>
        {props.inputFields.map((i) => {
          return (
            <div className="row">
              <div className={styles.label}>{i.placeholder}:</div>
              {i.placeholder === "Text" ? (
                <textarea
                  type="text"
                  /*   placeholder="..." */
                  value={i.input}
                  onChange={(e) => i.state(e.target.value)}
                  className={styles.textareaModal}
                ></textarea>
              ) : (
                <input
                  type="text"
                  /*   placeholder="..." */
                  value={i.input}
                  onChange={(e) => i.state(e.target.value)}
                  className={styles.inputModal}
                ></input>
              )}
            </div>
          );
        })}
      </div>

      <div className="row">
        <button className="button" onClick={props.handleChangeCategory}>
          Änderung speichern
        </button>
        <button
          className={`button ${styles.label}`}
          onClick={() => props.setModal(false)}
        >
          abbrechen
        </button>
      </div>
    </div>
  );
};

export default PostView;
