export const auflistung = `<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
.st0list{fill:#F1F9FC;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
.st1list{fill:#CED5D8;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
.st2list{fill:#575756;}
.st3list{font-family:'ArialRoundedMTBold';}
.st4list{font-size:12px;}
</style>
<g>
<path class="st0list" d="M84.1,86.9H15.3c-2,0-3.6-1.6-3.6-3.6V20.4c0-2,1.6-3.6,3.6-3.6h68.8c2,0,3.6,1.6,3.6,3.6v62.9
   C87.7,85.3,86.1,86.9,84.1,86.9z"/>
<path class="st1list" d="M79.2,31.3h-41c-1.1,0-1.9-0.8-1.9-1.9l0,0c0-1.1,0.8-1.9,1.9-1.9h41c1.1,0,1.9,0.8,1.9,1.9l0,0
   C81.1,30.5,80.3,31.3,79.2,31.3z"/>
<path class="st1list" d="M79.3,54.9h-41c-1.1,0-1.9-0.8-1.9-1.9l0,0c0-1.1,0.8-1.9,1.9-1.9h41c1.1,0,1.9,0.8,1.9,1.9l0,0
   C81.2,54,80.4,54.9,79.3,54.9z"/>
<path class="st1list" d="M79.6,75.7h-41c-1.1,0-1.9-0.8-1.9-1.9l0,0c0-1.1,0.8-1.9,1.9-1.9h41c1.1,0,1.9,0.8,1.9,1.9l0,0
   C81.5,74.8,80.7,75.7,79.6,75.7z"/>
<text transform="matrix(1 0 0 1 18.9454 32.866)" class="st2list st3list st4list">1.</text>
<text transform="matrix(1 0 0 1 18.0982 54.1068)" class="st2list st3list st4list">2.</text>
<text transform="matrix(1 0 0 1 17.6371 75.7247)" class="st2list st3list st4list">3.</text>
</g>
</svg>`;
