export const unterstreichen = `<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
.st0u{fill:#020203;}
.st1u{font-family:'Quicksand' !important;}
.st2u{font-size:72px !important;}
.st3u{fill:#f7cb0e;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
.st4u{fill:#575756;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
.st5u{fill:#CE9713;}
.st6u{fill:none;stroke:#030304;stroke-width:0.25;stroke-miterlimit:10;}
.st7u{fill:#F4AE18;}
.st8u{fill:#CD8E61;stroke:#030304;stroke-width:0.25;stroke-miterlimit:10;}
.st9u{fill:#5F656C;stroke:#030304;stroke-width:0.25;stroke-miterlimit:10;}
.st10u{fill:#7B848B;}
.st11u{fill:#AB7852;}
</style>
<g>
<text transform="matrix(1 0 0 1 6.0261 72.9705)" class="st0u st1u st2u">A</text>
<path class="st3u" d="M90,91.3H12.3c-2.2,0-4-1.8-4-4v-6.2c0-2.2,1.8-4,4-4H90c2.2,0,4,1.8,4,4v6.2C94,89.5,92.2,91.3,90,91.3z"/>
<circle class="st4u" cx="89.9" cy="81" r="1.3"/>
<path class="st4u" d="M76.3,77.2v4.1c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-4.1H76.3z"/>
<path class="st4u" d="M31.3,77.1v4.1c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-4.1H31.3z"/>
<path class="st4u" d="M46.5,77.1v4.1c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-4.1H46.5z"/>
<path class="st4u" d="M62.8,77.1v4.1c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-4.1H62.8z"/>
<path class="st4u" d="M16.9,77.2v4.1c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5v-4.1H16.9z"/>
<g>
   <path class="st5u" d="M72.3,63c1.2,0.6,2.9-0.4,3.6-2l21.3-48.1c0.7-1.7,0.3-3.5-0.9-4l-7.5-3.3c-1.2-0.6-2.9,0.4-3.6,2L63.8,55.6
	   c-0.7,1.7-0.3,3.5,0.9,4L72.3,63z"/>
   <path class="st6u" d="M72.3,63c1.2,0.6,2.9-0.4,3.6-2l21.3-48.1c0.7-1.7,0.3-3.5-0.9-4l-7.5-3.3c-1.2-0.6-2.9,0.4-3.6,2L63.8,55.6
	   c-0.7,1.7-0.3,3.5,0.9,4L72.3,63z"/>
</g>
<g>
   <path class="st7u" d="M70.9,62.4c1.2,0.6,2.9-0.4,3.6-2l21.1-48c0.7-1.7,0.3-3.5-0.9-4l-5.9-2.7c-1.2-0.6-2.9,0.4-3.6,2L64,55.6
	   c-0.7,1.7-0.3,3.5,0.9,4L70.9,62.4z"/>
</g>
<polygon class="st8u" points="75.4,62 74.9,57.4 71.8,60.1 71.4,55.6 68.1,58.2 68,53.9 63.5,56.7 63.7,71.8 	"/>
<polygon class="st9u" points="66.8,69.3 63.6,67.8 63.6,71.8 	"/>
<polygon class="st10u" points="63.7,69.4 63.7,68 64.8,68.5 	"/>
<polygon class="st11u" points="66.8,69.1 65,68.3 75.1,60 75.3,61.9 	"/>
</g>
</svg>
`;
