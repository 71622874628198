import React from "react";

import config from "../../config";
import { pinselRot } from "../../pictures/Editor/Pinsel_rot";
import { pinselBlau } from "../../pictures/Editor/Pinsel_blau";
import { pinselSchwarz } from "../../pictures/Editor/Pinsel_schwarz";
import { pinselGruen } from "../../pictures/Editor/Pinsel_gruen";
import { pinselGelb } from "../../pictures/Editor/Pinsel_gelb";
import { middleFontSize } from "../../pictures/Editor/Schrift_mittel";
import { bigFontSize } from "../../pictures/Editor/Schrift_gross";
import { tabelle } from "../../pictures/Editor/Tabelle";
import { unterstreichen } from "../../pictures/Editor/Schrift_unterstrichen";
import { auflistung } from "../../pictures/Editor/Auflistung";
import { bulletpoints } from "../../pictures/Editor/Auflistung_Bulletpoints";
import { smallFontSize } from "../../pictures/Editor/Schrift_klein";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";
import { getHelp } from "../../actions";

import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/plugins/code";
import "tinymce/plugins/media";
import "tinymce/plugins/link";
import "tinymce/skins/ui/oxide/skin.min.css";

import Axios from "axios";

const TinyMCE = (props) => {
  const { getHelp } = props;
  /*   const handleTitleChange = (event) => {
    props.setTitle(event);
  }; */
  const handleTextChange = (text) => {
    props.setText(text);
  };

  return (
    <>
      <Editor
        /*   apiKey="ZIYUDFLT" */
        /*         apiKey="ty082cngqvyq19zypeopn3qcrg74uvpodg4c06za3o0cv6xm"
         */ initialValue="<p>Schreibe hier...</p>"
        init={{
          height: 500,
          content_style: "body { font-family: Arial, serif; font-size:18px }",
          maxwidth: 2000,
          /*           images_upload_url: `${config.API_ENDPOINT}/api/upload`,
           */
          images_upload_handler: function (blobInfo, success, failure) {
            let data = new FormData();
            data.append("file", blobInfo.blob(), blobInfo.filename());
            Axios({
              method: "POST",
              url: `${config.API_ENDPOINT}/api/upload`,
              headers: {
                "auth-token": localStorage.getItem("token"),
              },
              data: data,
            }).then(
              ({ data }) => {
                success(data.location);
              },
              (error) => {
                console.error(error);
                getHelp("Die Datei ist wahrscheinlich zu gross", "error");
                failure("Da ging was schief. Probiere es erneut.");
              }
            );
          },
          menubar: false,
          table_toolbar:
            "tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
          selector: "textarea",
          placeholder: "Schreibe hier deinen Beitrag",
          language: "de",
          language_url: "/langs/de.js",
          paste_data_images: true,
          images_file_types: "jpg, jpeg, JPG, png, svg, webp",
          extended_valid_elements:
            "iframe[src|frameborder|style|scrolling|class|width|height|name|align]",

          skin_url: "/skins/ui/oxide",
          // media_live_embeds: true,
          default_link_target: "_blank",
          target_list: false,
          media_alt_source: false,
          media_poster: false,
          media_dimensions: false,
          file_picker_types: "media,file",
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");

            if (meta.filetype === "file") {
              input.setAttribute("accept", "application/pdf");
            } else if (meta.filetype === "media") {
              input.setAttribute("accept", "video/*");
            }
            input.onchange = function () {
              var file = this.files[0];

              const formData = new FormData();
              formData.append("file", file);
              Axios({
                method: "POST",
                url: `${config.API_ENDPOINT}/api/upload/files`,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                data: formData,
              }).then(
                ({ data }) => {
                  if (data && data.location) {
                    cb(data.location, { title: file.name });
                  } else {
                    console.error("Could not process file upload.");
                  }
                },
                (error) => {
                  console.error(error);
                  getHelp("Die Datei ist wahrscheinlich zu gross", "error");
                }
              );
            };

            input.click();
          },
          ////Buttons
          setup: function (editor) {
            editor.ui.registry.addButton("smallFontSize", {
              text: `<svg class="icon" aria-hidden="true">${smallFontSize}</svg>`,
              tooltip: "kleine Schriftgröße",
              onAction: function (_) {
                editor.execCommand("FontSize", false, "15px");
              },
            });
            editor.ui.registry.addButton("middleFontSize", {
              text: `<svg class="icon" aria-hidden="true">${middleFontSize}</svg>`,
              tooltip: "mittlere Schriftgröße",
              onAction: function (_) {
                editor.execCommand("FontSize", false, "18px");
              },
            });
            editor.ui.registry.addButton("bigFontSize", {
              text: `<svg class="icon" aria-hidden="true">${bigFontSize}</svg>`,
              tooltip: "große Schriftgröße",
              onAction: function (_) {
                editor.execCommand("FontSize", false, "25px");
              },
            });
            editor.ui.registry.addButton("colorRed", {
              text: `<svg class="icon" aria-hidden="true">${pinselRot}</svg>`,
              tooltip: "rote Schrift",
              onAction: function (_) {
                editor.execCommand("ForeColor", false, "#cd2258");
              },
            });
            editor.ui.registry.addButton("colorBlue", {
              text: `<svg class="icon" aria-hidden="true">${pinselBlau}</svg>`,
              tooltip: "blaue Schrift",
              onAction: function (_) {
                editor.execCommand("ForeColor", false, "#3a809e");
              },
            });
            editor.ui.registry.addButton("colorBlack", {
              text: `<svg class="icon" aria-hidden="true">${pinselSchwarz}</svg>`,
              tooltip: "schwarze Schrift",
              onAction: function (_) {
                editor.execCommand("ForeColor", false, "black");
              },
            });
            editor.ui.registry.addButton("colorGreen", {
              text: `<svg class="icon" aria-hidden="true">${pinselGruen}</svg>`,
              tooltip: "grüne Schrift",
              onAction: function (_) {
                editor.execCommand("ForeColor", false, "#448a35");
              },
            });
            editor.ui.registry.addButton("colorYellow", {
              text: `<svg class="icon" aria-hidden="true">${pinselGelb}</svg>`,
              tooltip: "gelbe Schrift",
              onAction: function (_) {
                editor.execCommand("ForeColor", false, "#f7cb0e");
              },
            });
            editor.ui.registry.addButton("list", {
              text: `<svg class="icon" aria-hidden="true">${auflistung}</svg>`,
              tooltip: "Auflistung",
              onAction: function (_) {
                editor.execCommand("InsertOrderedList");
              },
            });
            editor.ui.registry.addButton("bulletList", {
              text: `<svg class="icon" aria-hidden="true">${bulletpoints}</svg>`,
              tooltip: "Auflistung",
              onAction: function (_) {
                editor.execCommand("InsertUnorderedList");
              },
            });
            editor.ui.registry.addButton("tableNew", {
              text: `<svg class="icon-table" aria-hidden="true">${tabelle}</svg>`,
              tooltip: "Tabelle",
              onAction: function (_) {
                editor.execCommand("mceTableProps");
              },
            });
            editor.ui.registry.addButton("underlineNew", {
              text: `<svg class="icon" aria-hidden="true">${unterstreichen}</svg>`,

              tooltip: "unterstreichen",
              onAction: function (_) {
                editor.execCommand("Underline");
              },
            });
            editor.ui.registry.addButton("maps", {
              text: `<svg class="icon" aria-hidden="true">${unterstreichen}</svg>`,

              tooltip: "unterstreichen",
              onAction: function (_) {
                editor.execCommand("jsplus_maps");
              },
            });
          },

          plugins: "code, link, table",
          external_plugins: {
            custom_image: `${config.API_ENDPOINT}/public/scripts/tinymce/custom_image.js`,
            custom_media: `${config.API_ENDPOINT}/public/scripts/tinymce/custom_media.js`,
          },

          toolbar:
            "code link| colorRed  colorBlack  colorBlue colorYellow colorGreen |smallFontSize middleFontSize bigFontSize| bold italic|alignleft aligncenter alignright |  bulletList list|underlineNew table| custom_image  custom_media ",
        }}
        value={props.text}
        onEditorChange={handleTextChange}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHelp: (res, type) => dispatch(getHelp(res, type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TinyMCE);
