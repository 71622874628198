import * as actions from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  classes: [],
  classDetails: [],
};

const classes = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CLASSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CLASSES_SUCCESS:
      return {
        ...state,
        loading: false,
        classes: action.payload,
      };
    case actions.GET_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case action.POST_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.POST_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        classDetails: [...state.classDetails, action.payload],
        classes: [...state.classes, action.payload],
      };
    case actions.POST_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_CLASSDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_CLASSDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        classDetails: action.payload,
      };
    case actions.GET_CLASSDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.UPDATE_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_CLASS_SUCCESS:
      const filteredArrClasses = state.classes.filter(
        (c) => c.id !== action.payload.id
      );
      const filteredArrClassDetails = state.classDetails.filter(
        (c) => c.id !== action.payload.id
      );
      const arrClasses = [action.payload, ...filteredArrClasses];
      const arrClassDetails = [action.payload, ...filteredArrClassDetails];
      /*       const sortedArr = arr.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      ); */
      return {
        ...state,
        loading: false,
        classes: arrClasses,
        classDetails: arrClassDetails,
      };
    case actions.UPDATE_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.DELETE_CLASSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_CLASSES_SUCCESS:
      return {
        ...state,
        loading: false,
        classes: state.classes.filter(
          (classes) => classes.id !== action.payload
        ),
        classDetails: state.classDetails.filter(
          (classes) => classes.id !== action.payload
        ),
      };
    case actions.DELETE_CLASSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default classes;
