import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getCategories, getHelp } from "../../actions";
import styles from "./Meins.module.css";

const Meins = ({ user, posts, notifications }) => {
  useEffect(() => {}, []);

  const publishedNotifications = notifications.filter((n) => {
    return n.type === "published";
  });
  const ratedNotifications = notifications.filter((n) => {
    return n.type === "rated";
  });
  const updatedNotifications = notifications.filter((n) => {
    return n.type === "updated";
  });
  const stars = () => {
    const stars = ratedNotifications.map((r) => r.stars);
    var sum = 0;
    for (var i = 0; i < stars.length; i++) {
      sum += stars[i];
    }
    return sum;
  };
  const avgStars = () => {
    if (stars() !== 0) {
      return Math.round(stars() / ratedNotifications.length);
    } else {
      return "0";
    }
  };
  return (
    <div className={styles.container}>
      <div>
        Dein <i>kidipedia</i>-Profil
      </div>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>Dein kidi-Name</td>
            <td>{user.user_name}</td>
          </tr>
          <tr>
            <td>Dein Name</td>
            <td>
              {user.first_name} {user.last_name}
            </td>
          </tr>
          <tr>
            <td>Deine Schule</td>
            <td>{user.school}</td>
          </tr>
          <tr>
            <td>Deine Klasse</td>
            <td>{user.class}</td>
          </tr>
          <tr>
            <td>Deine Klassenstufe</td>
            <td>{user.role_id}</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.space}></div>
      <div>
        Deine <i>kidipedia</i>-Aktivitäten
      </div>
      <table className={styles.table}>
        <tbody>
          <tr>
            <td>
              So viele Beiträge gibt es auf <i>kidipedia</i> insgesamt
            </td>
            <td>{posts.length}</td>
          </tr>
          <tr>
            <td>
              So viele Beiträge hast du auf <i>kidipedia</i> erstellt
            </td>
            <td>{publishedNotifications.length}</td>
          </tr>
          <tr>
            <td>So viele Beiträge von dir wurden überarbeitet</td>
            <td>{updatedNotifications.length}</td>
          </tr>
          <tr>
            <td>So viele Bewertungen hast du bekommen</td>
            <td>{ratedNotifications.length}</td>
          </tr>
          <tr>
            <td>So viele Sterne hast du bisher durchschnittlich bekommen</td>
            <td>{avgStars() / 2}</td>
          </tr>
          <tr>
            <td>So viele Sterne waren es insgesamt</td>
            <td>{stars() / 2}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    posts: state.posts.posts,
    user: state.user,
    notifications: state.notifications.notifications,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: () => dispatch(getCategories()),
    getHelp: () => dispatch(getHelp(8)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Meins);
