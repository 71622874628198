import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PostView from "../../components/PostView/PostView";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import {
  getPost,
  addClick,
  reportPost,
  getNotes,
  postNote,
  giveStars,
  updatePost,
  updateStars,
  toggleHelpRedux,
  getHelp,
  getRating,
  getStars,
  postHistory,
} from "../../actions";
import get from "lodash/get";
import ReactStars from "react-rating-stars-component";
import Loading from "../../components/Loading/Loading";
import styles from "./Post.module.css";
import { Editor } from "@tinymce/tinymce-react";

const Post = (props) => {
  const {
    loading,
    getPost,
    getNotes,
    addClick,
    history,
    updatePost,
    loadingHistory,
    postHistory,
    getRating,
    reportPost,
    toggleHelpRedux,
    getHelp,
    notes,
    postNote,
    authorId,
    role,
    post,
    userId,
    giveStars,
    updateStars,
    starRatings,
    class_id,
    user_name,
    author_id,
    getStars,
  } = props;
  const postId = get(props, "match.params.id");

  /*   const [post, setPost] = useState([]); */
  const [text, setText] = useState("");
  // TODO
  const [toggleEditor, setToggleEditor] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [showHisory, setShowHistory] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [notAuthorized, setNotAuthorized] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [historyTitle, setHistoryTitle] = useState("");
  const [historyText, setHistoryText] = useState("");
  const [modal, setModal] = useState(false);
  const [historyPost, setHistoryPost] = useState(null);

  useEffect(() => {
    async function fetchPosts() {
      await getPost(postId).then((res) => {
        if (res === "Request failed with status code 401") {
          setNotAuthorized(true);
        }
        if (res.deleted /* || (post.reported && role < 7) */) {
          setDeleted(true);
        }
      });
    }
    /*   setPost(posts); */
    addClick(postId);
    getNotes(postId);
    getRating(userId, postId);
    getStars(postId);
    fetchPosts();
  }, [addClick, getNotes, getPost, getRating, getStars, postId, userId]);
  useEffect(() => {
    async function fetchRatings() {
      await getRating(userId, postId);

      await getStars(postId);
    }
    fetchRatings();
  }, [getRating, getStars, postId, starRatings.myRating, userId]);
  const value = Math.round(starRatings.stars) / 2;

  const stars = {
    size: 40,
    count: 5,
    color: "lightgray",
    activeColor: "var(--yellow)",
    value: value,
    /*  a11y: true, */
    edit: false,
    isHalf: true,
  };

  const giveRating = {
    size: 40,
    count: 5,
    color: "lightgray",
    activeColor: "var(--yellow)",
    value: 0,
    a11y: true,
    edit: true,
    isHalf: true,
    onChange: (newValue) => {
      const stars = newValue * 2;
      const creationDate = post.creation_date;
      const updateDate = post.last_update_date;
      const version = () => {
        if (updateDate === null) {
          return creationDate;
        } else {
          return updateDate;
        }
      };
      giveStars(postId, userId, version(), stars, author_id, class_id);
    },
  };

  const updateRating = {
    size: 40,
    count: 5,
    color: "lightgray",
    activeColor: "var(--yellow)",
    value: starRatings.myRating / 2,
    a11y: true,
    edit: true,
    isHalf: true,
    onChange: (newValue) => {
      const stars = newValue * 2;
      const creationDate = post.creation_date;
      const updateDate = post.last_update_date;
      const version = () => {
        if (updateDate === null) {
          return creationDate;
        } else {
          return updateDate;
        }
      };
      updateStars(postId, userId, version(), stars, author_id, class_id);
    },
  };

  const handleOccupied = () => {
    toggleHelpRedux(true);
    getHelp(
      "Dieser Beitrag wird zur Zeit bearbeitet. Versuche es später noch einmal.",
      "error"
    );

    setTimeout(function () {
      toggleHelpRedux(false);
    }, 6000);
  };
  const handleReportPost = () => {
    const author = () => {
      if (post.author_id_update) {
        return post.author_id_update;
      } else {
        return author_id;
      }
    };
    if (
      window.confirm(
        "Möchtest du diesen Beitrag wirklich melden? Danach wird der Beitrag von der Redaktion aus kidipedia entfernt."
      )
    ) {
      reportPost(postId, author(), class_id);
      setRedirect(true);
    } else {
      return null;
    }
  };
  const handleTextChange = (text) => {
    setText(text);
  };
  const handlePostNote = () => {
    postNote(postId, text, authorId, user_name)
      .then(setText(""))
      .then(setToggleEditor(false));
    /*       .then(getNotes(postId)); */
  };
  const formatDate = (input) => {
    if (input !== null && input !== undefined) {
      const date = input.slice(0, 10);
      const dArr = date.split("-");
      return dArr[2] + "-" + dArr[1] + "-" + dArr[0].substring(2);
    } else {
      return null;
    }
  };

  const handleRecover = () => {
    const { post_id, title, text, author_id, role, class_id, last_author_id } =
      historyPost;
    updatePost(post_id, title, text, author_id, role, class_id, last_author_id);
    postHistory(post);
  };
  const occupied = () => {
    const HOURS = 1000 * 60 * 60 * 4;
    if (post.edit_lock_user_name === user_name) {
      return false;
    } else if (
      Date.now() - new Date(post.aquired_edit_lock_at).getTime() <
      HOURS
    ) {
      return true;
    } else {
      return false;
    }
  };
  const classId = post.class_role;
  const isSameGrade = () => {
    if (
      (role === 1 && classId === 1) ||
      (role === 1 && classId === 2) ||
      (role === 2 && classId === 1) ||
      (role === 2 && classId === 2)
    ) {
      return true;
    } else if (
      (role === 3 && classId === 3) ||
      (role === 3 && classId === 4) ||
      (role === 4 && classId === 3) ||
      (role === 4 && classId === 4)
    ) {
      return true;
    } else if (
      (role === 5 && classId === 5) ||
      (role === 5 && classId === 6) ||
      (role === 6 && classId === 5) ||
      (role === 6 && classId === 6)
    ) {
      return true;
    } else if (classId < 9 && role > 6) {
      return true;
    } else if (role > 8) {
      return true;
    } else {
      return false;
    }
  };

  const displayHistory = () => {
    if (showHisory) {
      return (
        <>
          {history ? (
            <>
              <div className="row">
                <h4>Version</h4>
                <h4>Titel</h4>
                <h4>Erstellungsdatum</h4>
                <h4>Autor</h4>
              </div>
              {history.map((post, i) => (
                <div
                  key={post.id}
                  className={styles.rowHistory}
                  onClick={() => {
                    setHistoryTitle(post.title);
                    setHistoryText(post.text);
                    setHistoryPost(post);
                    setModal(true);
                  }}
                >
                  <div>Version: {i + 1} </div>
                  <div>{post.title}</div>
                  <div>{formatDate(post.version_date)}</div>
                  {post.user_name_update ? (
                    <div>{post.user_name_update}</div>
                  ) : (
                    <div>{post.user_name}</div>
                  )}
                </div>
              ))}
            </>
          ) : null}
        </>
      );
    } else {
      return null;
    }
  };

  const displayNotes = () => {
    if (showNotes) {
      return (
        <div className={styles.editor}>
          <Editor
            apiKey="ty082cngqvyq19zypeopn3qcrg74uvpodg4c06za3o0cv6xm"
            init={{
              height: 300,
              maxwidth: 2000,
              content_css: "./TinyMCE.css",
              menubar: false,

              icons: "material",
              language: "de",
              language_url: "/langs/de.js",

              plugins: [
                "advlist autolink lists link image",
                "charmap print preview anchor help",
                "searchreplace visualblocks code",
                "insertdatetime media table paste wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent |image | link|  help ",
            }}
            value={text}
            onEditorChange={handleTextChange}
            /*   onChange={this.handleEditorChange} */
          />
          <button className={styles.button} onClick={() => handlePostNote()}>
            Hinweis speichern
          </button>
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <>
      <div className="body-container">
        <div className="column">
          {loading ? (
            <Loading />
          ) : (
            <>
              {deleted ? (
                <h2>
                  Dieser Beitrag wurde von der Redaktion aus <em>kidipedia</em>{" "}
                  entfernt.
                </h2>
              ) : (
                <>
                  {notAuthorized ? (
                    <h2>Diesen Beitrag kannst du leider nicht sehen.</h2>
                  ) : (
                    <>
                      <div className={styles.stars}>
                        {starRatings.loading ? null : <ReactStars {...stars} />}
                      </div>
                      <div className={styles.ratings}>
                        {value ? value : 0}/5 bei {starRatings.ratings}{" "}
                        Bewertungen
                      </div>
                      <PostView
                        title={post.title}
                        text={post.text}
                        stars={post.stars}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          <div className="right">
            {isSameGrade() && !deleted ? (
              <>
                {occupied() ? (
                  <button
                    onClick={handleOccupied}
                    className={styles.buttonGrey}
                  >
                    Beitrag bearbeiten
                  </button>
                ) : (
                  <Link to={`/beiträge/überarbeiten`} className="Link">
                    <button className={styles.button}>
                      Beitrag bearbeiten
                    </button>
                  </Link>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {/*  <-------------------------------------------------------------------- Area for Teachers ----------------------------------------------------------> */}
      {role > 6 && !deleted ? (
        <div className="body-container">
          <div className={modal ? styles.modal : styles.noModal}>
            <div className={styles.container}>
              <h1>{historyTitle}</h1>
              {historyText ? (
                <div dangerouslySetInnerHTML={{ __html: historyText }} />
              ) : null}
              <button className={styles.buttonHistory} onClick={handleRecover}>
                wiederherstellen
              </button>
              <button
                className="button"
                onClick={() => {
                  setModal(false);
                }}
              >
                schliessen
              </button>
            </div>
          </div>
          <div className="post-container">
            {!starRatings.loadingMyRating ? (
              <div>
                {starRatings.myRating ? (
                  <>
                    <div className={styles.row}>
                      <div className={styles.label}>
                        Dies ist ihre Bewertung:{" "}
                      </div>
                      <ReactStars {...updateRating} />
                    </div>
                    <div className={styles.row}>
                      Vielleicht hat sich der Beitrag mittlerweile verändert.
                    </div>
                    <div className={styles.row}>
                      Sie können ihre Bewertung jederzeit aktualisieren.
                    </div>
                  </>
                ) : (
                  <div className={styles.row}>
                    <div className={styles.label}>
                      Bewerten Sie diesen Beitrag:{" "}
                    </div>
                    <ReactStars {...giveRating} />
                  </div>
                )}
              </div>
            ) : null}

            <div className={styles.rowMenu}>
              <button
                className={styles.button}
                onClick={() => {
                  setShowHistory(false);
                  setShowNotes(!showNotes);
                }}
              >
                Hinweis für andere Lehrpersonen schreiben
              </button>
              <button
                className={styles.button}
                onClick={() => {
                  setShowHistory(!showHisory);
                  setShowNotes(false);
                }}
              >
                Beitragshistorie
              </button>
              <button
                className={styles.buttonRed}
                onClick={() => handleReportPost()}
              >
                Beitrag melden
              </button>
            </div>
            {displayNotes()}
            <div className={styles.history}>
              {loadingHistory ? null : <>{displayHistory()}</>}
            </div>
            {notes.map((note) => (
              <div key={note.id} className={styles.note}>
                <div className={styles.author}>
                  <h3>{note.user_name}</h3>
                  <div>{formatDate(note.creation_date)}</div>
                </div>
                <div dangerouslySetInnerHTML={{ __html: note.text }}></div>
              </div>
            ))}
          </div>
          {redirect ? <Redirect to={`/`} /> : null}
        </div>
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.post.loading,
    loadingHistory: state.postsHistory.loading,
    loadingNotes: state.postsNotes.loading,
    history: state.postsHistory.history,
    notes: state.postsNotes.notes,
    authorId: state.user.id,
    user_name: state.user.user_name,
    post: state.post.post,
    role: state.user.role_id,
    userId: state.user.id,
    starRatings: state.starRatings,
    class_id: state.post.post.class_id,
    author_id: state.post.post.author_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStars: (postId) => dispatch(getStars(postId)),
    getHelp: (res, type) => dispatch(getHelp(res, type)),
    toggleHelpRedux: (boolean) => dispatch(toggleHelpRedux(boolean)),
    getPost: (postId) => dispatch(getPost(postId)),
    addClick: (postId) => dispatch(addClick(postId)),
    reportPost: (postId, author_id, class_id) =>
      dispatch(
        reportPost(postId, false, true, author_id, class_id, "reported")
      ),
    getNotes: (postId) => {
      dispatch(getNotes(postId));
    },
    postNote: (postId, text, authorId, user_name) =>
      dispatch(postNote(postId, text, authorId, user_name)),
    giveStars: (postId, teacherId, version, stars, author_id, class_id) =>
      dispatch(
        giveStars(
          postId,
          teacherId,
          version,
          stars,
          author_id,
          class_id,
          "rated"
        )
      ),
    updateStars: (postId, teacherId, version, stars, author_id, class_id) =>
      dispatch(
        updateStars(
          postId,
          teacherId,
          version,
          stars,
          author_id,
          class_id,
          "rated"
        )
      ),
    getRating: (userId, postId) => dispatch(getRating(userId, postId)),
    postHistory: (obj) => dispatch(postHistory(obj)),
    updatePost: (postId, title, text, authorId, role, class_id, lastAuthor) =>
      dispatch(
        updatePost(
          postId,
          title,
          text,
          authorId,
          role,
          class_id,
          "recover",
          lastAuthor
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Post);
