import * as actions from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  history: [],
};

const postsHistory = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_HISTORY_REQUEST:
      return {
        loading: true,
      };
    case actions.GET_HISTORY_SUCCESS:
      return {
        loading: false,
        history: action.payload,
      };
    case actions.GET_HISTORY_FAILURE:
      return {
        loading: false,
        error: action.payload.error,
      };
    case actions.POST_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.POST_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: [...state.history, action.payload],
      };
    case actions.POST_HISTORY_FAILURE:
      return {
        loading: false,
        error: action.payload.error,
      };
    case actions.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default postsHistory;
