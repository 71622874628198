import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";

export function PrivateRoute(props) {
  const { component: Component, ...rest } = props;

  if (props.loggedIn) {
    if (props.acceptedTermsOfUse || (props.role !== 7 && props.role !== 8)) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
    return <Redirect to="/anmelden" />;
  } else {
    return null;
  }
}

export function AdminRoute(props) {
  const { component: Component, ...rest } = props;
  if (props.loggedIn) {
    if (props.acceptedTermsOfUse || (props.role !== 7 && props.role !== 8)) {
      if (props.role > 8) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
      } else {
        return <Redirect to="/" />;
      }
    }
    return <Redirect to="/anmelden" />;
  } else {
    return null;
  }
}

export function TeacherRoute(props) {
  const { component: Component, ...rest } = props;
  if (props.loggedIn) {
    if (props.acceptedTermsOfUse && (props.role === 7 || props.role === 8)) {
      if (props.role > 6 && props.role < 9) {
        return <Route {...rest} render={(props) => <Component {...props} />} />;
      } else {
        return <Redirect to="/" />;
      }
    }
    return <Redirect to="/anmelden" />;
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
PrivateRoute(connect(mapStateToProps)(PrivateRoute));
AdminRoute(connect(mapStateToProps)(AdminRoute));
