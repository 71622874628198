import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import {
  login,
  getRole,
  getHelp,
  toggleHelpRedux,
  getPageContent,
  logOut,
  acceptTermsOfUse,
} from "../../actions";
import LoginGrid from "../../components/LoginGrid/LoginGrid";
import styles from "./Login.module.css";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

const Login = ({
  login,
  user,
  getRole,
  getHelp,
  toggleHelpRedux,
  pageContent,
  getPageContent,
  logOut,
  acceptTermsOfUse,
}) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [inputField, setInputField] = useState(true);
  const [loginGrid, setLoginGried] = useState(false);
  const [toggleRow, setToggelRow] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [modalTermsOfUse, setModalTermsOfUse] = useState(false);
  const [checked, setChecked] = useState(false);
  const [cancel, setCancel] = useState(false);

  const passwordInput = React.useRef(null);
  const userNameInput = React.useRef(null);

  useEffect(() => {
    // current property is refered to input element
    if (userNameInput.current) {
      userNameInput.current.focus();
    }
    async function fetchContent() {
      await getPageContent();
    }
    fetchContent();
  }, [getPageContent]);

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleDataRedux = () => {
    login(userName, password)
      .then((res) => {
        if (
          !res.accepted_terms_of_use &&
          (res.role_id === 7 || res.role_id === 8)
        ) {
          setModalTermsOfUse(true);
        }
      })
      .catch((err) => {
        setToggelRow(true);
        getHelp(err.message, "error");

        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
        setPassword("");
      });
  };
  const handleUsername = () => {
    getRole(userName).then((res) => {
      const role = res.data.role;
      if (role === "1 grade" || role === "2 grade") {
        setLoginGried(true);
        setInputField(false);
      } else {
        setInputField(true);
        setLoginGried(false);
        setTimeout(function () {
          passwordInput.current.focus();
        }, 700);
      }
      setToggelRow(true);
    });
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleDataRedux();
    }
  };
  const handleKeyPressUser = (e) => {
    if (e.key === "Enter") {
      handleUsername();
    }
  };

  const handleGridPassword = (passwordArray) => {
    const copy = [...passwordArray];
    const passwordString = copy.sort().join("");
    setPassword(passwordString);
  };
  const handleTermsOfUse = () => {
    async function acceptTerms() {
      await acceptTermsOfUse(user.id);
      await handleDataRedux();
    }
    acceptTerms();
  };
  const handleCancel = () => {
    logOut();
    localStorage.removeItem("token");
    setCancel(true);
  };
  const filteredContent = pageContent.pageContent.filter((c) => {
    return c.type === "TermsOfUse";
  });

  const token = localStorage.getItem("token");

  const loggedInJWT = () => {
    if (token) {
      const decoded = jwt_decode(token);
      if (
        token &&
        (decoded.accepted_terms_of_use ||
          (decoded.role !== 7 && decoded.role !== 8))
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  };
  return (
    <div className="body-container">
      {cancel ? <Redirect to="/" /> : null}
      <div className={modalTermsOfUse ? styles.modal : styles.noModal}>
        <div className={styles.frame}>
          <div className={styles.infoText}>
            {filteredContent[0] ? (
              <div
                dangerouslySetInnerHTML={{ __html: filteredContent[0].text }}
              ></div>
            ) : null}
            <div className="row">
              {" "}
              <input
                type="checkbox"
                onChange={() => setChecked(!checked)}
              ></input>
              <div>Hiermit bestätige ich die Nutzungsbedingungen</div>
            </div>
            <button className="button" onClick={handleCancel}>
              abbrechen
            </button>
            <button
              className="button"
              onClick={handleTermsOfUse}
              disabled={!checked}
            >
              speichern
            </button>
          </div>
        </div>
      </div>
      <div className="column">
        {/*         {loggedIn ? null : <h3 className="textAlign"> Melde dich hier an</h3>} */}
        <div className={styles.columnContainer}>
          {loggedInJWT() ? (
            <Redirect to={`/`} />
          ) : (
            <div className={`${toggleRow ? styles.rowToRight : styles.row}`}>
              <div className={styles.column}>
                <div className={styles.title}>Dein kidi-Name:</div>
                <input
                  className={styles.input}
                  value={userName}
                  autoComplete="off"
                  onChange={handleUserNameChange}
                  onKeyPress={handleKeyPressUser}
                  ref={userNameInput}
                ></input>
                {/* hier */}

                <button className="buttonOutline" onClick={handleUsername}>
                  <FiArrowRight className={styles.arrow} />
                </button>
              </div>
              {inputField ? (
                <div className={styles.column}>
                  <div className={styles.title}>
                    Gib hier dein Passwort ein:
                  </div>
                  <input
                    className={styles.input}
                    type={hidden ? "password" : "text"}
                    value={password}
                    autoComplete="off"
                    onChange={handlePasswordChange}
                    onKeyPress={handleKeyPress}
                    ref={passwordInput}
                  ></input>
                  <button
                    className="buttonOutline"
                    onClick={() => {
                      setHidden(!hidden);
                    }}
                  >
                    {hidden ? <>Passwort anzeigen</> : <>Passwort verbergen</>}
                  </button>
                  <div className={styles.rowNormal}>
                    <button
                      className="buttonOutline"
                      onClick={() => {
                        setToggelRow(false);
                      }}
                    >
                      <FiArrowLeft className={styles.arrow} />
                    </button>
                    <div className="buttonContainer">
                      <button
                        className={styles.button}
                        onClick={handleDataRedux}
                      >
                        Rein
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {loginGrid ? (
                <div className={styles.column}>
                  <div>Hier kannst du dein Bilderpasswort eingeben</div>
                  <LoginGrid getGridArray={handleGridPassword} />
                  <div className={styles.rowNormal}>
                    <button
                      className="buttonOutline"
                      onClick={() => {
                        setToggelRow(false);
                      }}
                    >
                      <FiArrowLeft className={styles.arrow} />
                    </button>
                    <div className="buttonContainer">
                      <button
                        className={styles.button}
                        onClick={handleDataRedux}
                      >
                        Rein
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    pageContent: state.pageContent,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: (userName, password) => dispatch(login(userName, password)),
    getRole: (userName) => dispatch(getRole(userName)),
    getHelp: (res, type) => dispatch(getHelp(res, type)),
    toggleHelpRedux: (boolean) => dispatch(toggleHelpRedux(boolean)),
    getPageContent: () => dispatch(getPageContent()),
    logOut: () => dispatch(logOut()),
    acceptTermsOfUse: (id) => dispatch(acceptTermsOfUse(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
