import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoginGrid from "../../components/LoginGrid/LoginGrid";
import {
  updatePassword,
  toggleHelpRedux,
  getHelp,
} from "../../actions";
import styles from "./ChangePassword.module.css";
import PasswordChanged from "../../pictures/PasswordChanged.png";
import PasswordGuidelines from "../../components/PasswordGuidelines/PasswordGuidelines";

const Login = ({ updatePassword, user, toggleHelpRedux, getHelp }) => {
  const [password, setPassword] = useState("");
  const [comparePassword, setComparePassword] = useState("");
  // TODO: 
  const [errorPasswordSimilarity, setErrorPasswordSimilarity] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const userId = user.id;
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleComparePasswordChange = (event) => {
    setComparePassword(event.target.value);
  };
  const handleGridPassword = (passwordArray) => {
    const copy = [...passwordArray];
    if (passwordArray.length !== 3) {
      getHelp("Wähle 3 Bilder", "error");
      const passwordString = copy.sort().join("");
      setPassword(passwordString);
    } else {
      getHelp("Jetzt kannst du auf speichern klicken", "success");
      const passwordString = copy.sort().join("");
      setPassword(passwordString);
      setComparePassword(passwordString);
    }
  };
  const handleDataRedux = () => {
    if (password === comparePassword && passwordValidation()) {
      updatePassword(password, userId)
        .then((res) => {})
        .catch((err) => console.log(err));
      setPassword("");
      setComparePassword("");
      setPasswordChanged(true);
      getHelp(
        "Du hast dein Passwort geändert. Halte dein neues Passwort geheim.",
        "success"
      );
    } else if (password !== comparePassword) {
      getHelp("Deine Passwörter stimmen nicht überein", "error");
      toggleHelpRedux(true);
    } else {
      getHelp("Dein Passwort entspricht nicht den Richtlinien.", "error");
      toggleHelpRedux(true);
    }
  };
  const passwordValidation = () => {
    const split = password.split("");

    const upper = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    const lower = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];
    const number = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    const space = [" "];
    if (
      password.length > 4 &&
      split.some((e) => upper.includes(e)) &&
      split.some((e) => lower.includes(e)) &&
      split.some((e) => number.includes(e)) &&
      !split.some((e) => space.includes(e))
    ) {
      return true;
    } else {
      getHelp("Das Passwort entspricht nicht den Richtlinien", "error");
      toggleHelpRedux(true);
      setTimeout(function () {
        toggleHelpRedux(false);
      }, 6000);
      return false;
    }
  };
  return (
    <div className="body-container">
      <div className="column">
        {passwordChanged ? (
          <Link
            to={{
              pathname: `/`,
            }}
            className="text-decoration"
          >
            <img
              src={PasswordChanged}
              alt="passwort geaendert"
              className={styles.picture}
            />
          </Link>
        ) : (
          <>
            {" "}
            <div>Hier kannst du dein Passwort ändern</div>
            {user.role_id > 2 ? (
              <>
                {" "}
                <input
                  className={styles.input}
                  placeholder="neues Passwort"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                ></input>
                <input
                  className={styles.input}
                  placeholder="Passwort wiederholen"
                  type="password"
                  value={comparePassword}
                  onChange={handleComparePasswordChange}
                ></input>
                {errorPasswordSimilarity ? (
                  <div className={styles.error}>
                    Passwörter stimmen nicht überein
                  </div>
                ) : null}
              </>
            ) : (
              <LoginGrid getGridArray={handleGridPassword} />
            )}
            <div className="buttonContainer">
              <button className="button" onClick={handleDataRedux}>
                speichern
              </button>
            </div>{" "}
          </>
        )}
      </div>
      {user.role_id > 6 ? (
        <h3>
          Damit Ihr Passwort sicher ist, müssen diese Richtlinien beachtet
          werden:
        </h3>
      ) : (
        <h3>
          Damit dein Passwort sicher ist, musst du bei deinem Passwort folgende
          Regeln beachten:
        </h3>
      )}

      <PasswordGuidelines />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHelp: (res, type) => dispatch(getHelp(res, type)),
    toggleHelpRedux: (boolean) => dispatch(toggleHelpRedux(boolean)),
    updatePassword: (password, userId) =>
      dispatch(updatePassword(password, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
