import React from "react";
import styles from "./PostList.module.css";

const PostList = (props) => {
  const role = props.role;

  const colors = () => {
    if (role === 1 || role === 2) {
      return `${styles.postlistOne}`;
    } else if (role === 3 || role === 4) {
      return `${styles.postlistTwo}`;
    } else if (role === 5 || role === 6) {
      return `${styles.postlistThree}`;
    } else {
      return `${styles.postlistTeacher}`;
    }
  };

  const formatDate = (input) => {
    if (input) {
      const date = input.slice(0, 10);
      const dArr = date.split("-");
      return dArr[2] + "-" + dArr[1] + "-" + dArr[0].substring(2);
    } else {
      return [];
    }
  };
  const occupied = (time) => {
    const HOURS = 1000 * 60 * 60 * 4;
    if (time && Date.now() - new Date(time).getTime() < HOURS) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <div className={colors()} key={props.id}>
        <div className={styles.postlistContainer}>
          <div className={styles.title}>{props.title}</div>
          <div>{props.userName}</div>
          <div>{formatDate(props.date)}</div>
          <div>{props.updateUserName}</div>
          <div>{formatDate(props.updateDate)}</div>
          {occupied(props.aquiredEditLockAt) ? (
            <div>{props.editLockUserName}</div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
};

export default PostList;
