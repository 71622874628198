import axios from "axios";
import * as actions from "../actions/types";
import config from "../config";
import jwt_decode from "jwt-decode";

/* const authAxios = axios.create({
  headers: {
    "auth-token": localStorage.getItem("token"),
  },
}); */
/* ---------------------------------------- posts ----------------------------------------*/
export const getPosts = () => {
  return (dispatch) => {
    dispatch(getPostsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/posts`,
    })
      .then((res) => {
        const posts = res.data;
        dispatch(getPostsSuccess(posts));
        return posts;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getPostsFailure(errorMsg));
      });
  };
};
const getPostsRequest = () => ({
  type: actions.GET_POSTS_REQUEST,
});

const getPostsSuccess = (posts) => ({
  type: actions.GET_POSTS_SUCCESS,
  payload: posts,
});

const getPostsFailure = (error) => ({
  type: actions.GET_POSTS_FAILURE,
  payload: {
    error,
  },
});
export const getAllPosts = () => {
  return (dispatch) => {
    dispatch(getAllPostsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/posts/allPosts`,
    })
      .then((res) => {
        const posts = res.data;
        dispatch(getAllPostsSuccess(posts));
        return posts;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getAllPostsFailure(errorMsg));
      });
  };
};
const getAllPostsRequest = () => ({
  type: actions.GET_ALL_POSTS_REQUEST,
});

const getAllPostsSuccess = (posts) => ({
  type: actions.GET_ALL_POSTS_SUCCESS,
  payload: posts,
});

const getAllPostsFailure = (error) => ({
  type: actions.GET_ALL_POSTS_FAILURE,
  payload: {
    error,
  },
});
export const getMyPosts = (userName) => {
  return (dispatch) => {
    dispatch(getMyPostsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/posts/myPosts`,
      data: {
        username: userName,
      },
    })
      .then((res) => {
        const posts = res.data;
        dispatch(getMyPostsSuccess(posts));
        return posts;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getMyPostsFailure(errorMsg));
      });
  };
};
const getMyPostsRequest = () => ({
  type: actions.GET_MY_POSTS_REQUEST,
});

const getMyPostsSuccess = (posts) => ({
  type: actions.GET_MY_POSTS_SUCCESS,
  payload: posts,
  //posts: action.payload (das gleiche?)
});

const getMyPostsFailure = (error) => ({
  type: actions.GET_MY_POSTS_FAILURE,
  payload: {
    error,
  },
});

export const getPostsForStatus = (userId, days) => {
  return (dispatch) => {
    dispatch(getPostsForStatusRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/posts/myKidiStatus/${userId}/${days}`,
    })
      .then((res) => {
        const posts = res.data;
        dispatch(getPostsForStatusSuccess(posts));
        return posts;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getPostsForStatusFailure(errorMsg));
      });
  };
};
const getPostsForStatusRequest = () => ({
  type: actions.GET_POSTS_STATUS_REQUEST,
});

const getPostsForStatusSuccess = (posts) => ({
  type: actions.GET_POSTS_STATUS_SUCCESS,
  payload: posts,
});

const getPostsForStatusFailure = (error) => ({
  type: actions.GET_POSTS_STATUS_FAILURE,
  payload: {
    error,
  },
});

export const getKidiStatus = (userId) => {
  return (dispatch) => {
    dispatch(getKidiStatusRequest());

    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/posts/kidiStatus`,
    })
      .then((res) => {
        const status = res.data.rows[0];
        dispatch(getPostsForStatus(userId, status.days));
        dispatch(getKidiStatusSuccess(status));
        return status;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getKidiStatusFailure(errorMsg));
      });
  };
};
const getKidiStatusRequest = () => ({
  type: actions.GET_KIDI_STATUS_REQUEST,
});

const getKidiStatusSuccess = (status) => ({
  type: actions.GET_KIDI_STATUS_SUCCESS,
  payload: status,
});

const getKidiStatusFailure = (error) => ({
  type: actions.GET_KIDI_STATUS_FAILURE,
  payload: {
    error,
  },
});
export const updateKidiStatus = (days, air, neutral, sad) => {
  return (dispatch) => {
    dispatch(updateKidiStatusRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/posts/kidiStatus/update`,
      data: {
        days,
        air,
        neutral,
        sad,
      },
    })
      .then((res) => {
        const status = res.data[0];
        dispatch(updateKidiStatusSuccess(status));
        return status;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(updateKidiStatusFailure(errorMsg));
      });
  };
};
const updateKidiStatusRequest = () => ({
  type: actions.UPDATE_KIDI_STATUS_REQUEST,
});

const updateKidiStatusSuccess = (status) => ({
  type: actions.UPDATE_KIDI_STATUS_SUCCESS,
  payload: status,
});

const updateKidiStatusFailure = (error) => ({
  type: actions.UPDATE_KIDI_STATUS_FAILURE,
  payload: {
    error,
  },
});
export const getMostStars = () => {
  return (dispatch) => {
    dispatch(getMostStarsRequest());
    return axios({
      method: "get",
      url: `${config.API_ENDPOINT}/api/pageContentPublic/mostStars`,
    })
      .then((res) => {
        const posts = res.data;
        dispatch(getMostStarsSuccess(posts));
        return posts;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getMostStarsFailure(errorMsg));
      });
  };
};
const getMostStarsRequest = () => ({
  type: actions.GET_MOST_STARS_REQUEST,
});

const getMostStarsSuccess = (posts) => ({
  type: actions.GET_MOST_STARS_SUCCESS,
  payload: posts,
  //posts: action.payload (das gleiche?)
});

const getMostStarsFailure = (error) => ({
  type: actions.GET_MOST_STARS_FAILURE,
  payload: {
    error,
  },
});

export const getClassPosts = (classId) => {
  return (dispatch) => {
    dispatch(getClassPostsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/posts/classPosts`,
      data: {
        class_id: classId,
      },
    })
      .then((res) => {
        const posts = res.data;
        dispatch(getClassPostsSuccess(posts));
        return posts;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getClassPostsFailure(errorMsg));
        return [];
      });
  };
};

const getClassPostsRequest = () => ({
  type: actions.GET_CLASS_POSTS_REQUEST,
});

const getClassPostsSuccess = (posts) => ({
  type: actions.GET_CLASS_POSTS_SUCCESS,
  payload: posts,
});

const getClassPostsFailure = (error) => ({
  type: actions.GET_CLASS_POSTS_FAILURE,
  payload: {
    error,
  },
});
export const getCategoryPosts = (category_id) => {
  return (dispatch) => {
    dispatch(getCategoryPostsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/posts/category/${category_id}`,
    })
      .then((res) => {
        const posts = res.data;
        dispatch(getCategoryPostsSuccess(posts));
        return posts;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getCategoryPostsFailure(errorMsg));
      });
  };
};
const getCategoryPostsRequest = () => ({
  type: actions.GET_CATEGORY_POSTS_REQUEST,
});

const getCategoryPostsSuccess = (posts) => ({
  type: actions.GET_CATEGORY_POSTS_SUCCESS,
  payload: posts,
});

const getCategoryPostsFailure = (error) => ({
  type: actions.GET_CATEGORY_POSTS_FAILURE,
  payload: {
    error,
  },
});
export const updatePost = (
  postId,
  title,
  text,
  authorId,
  role,
  class_id,
  type,
  lastAuthor
) => {
  return (dispatch) => {
    dispatch(updatePostRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/posts/${postId}`,
      data: {
        title: title,
        text: text,
        authorId: authorId,
        classId: class_id,
      },
    })
      .then((res) => {
        const post = res.data;
        dispatch(updatePostSuccess(post));
        if (role < 7 && authorId !== lastAuthor) {
          dispatch(postNotification(postId, lastAuthor, class_id, type));
          if (type === "updated") {
            dispatch(postNotification(postId, authorId, class_id, "check"));
          } else {
            return null;
          }
        }

        return post;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(updatePostFailure(errorMsg));
      });
  };
};
const updatePostRequest = () => ({
  type: actions.UPDATE_POST_REQUEST,
});

const updatePostSuccess = (posts) => ({
  type: actions.UPDATE_POST_SUCCESS,
  payload: posts[0],
});

const updatePostFailure = (error) => ({
  type: actions.UPDATE_POST_FAILURE,
  payload: {
    error,
  },
});

export const aquireEditLock = (postId) => {
  return () => {
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/posts/${postId}/aquireEditLock`,
    });
  };
};

export const releaseEditLock = (postId) => {
  return () => {
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/posts/${postId}/releaseEditLock`,
    });
  };
};

/* ---------------------------------------- post ----------------------------------------*/
export const getPost = (postId) => {
  return (dispatch) => {
    dispatch(getPostRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/posts/id/${postId}`,
    })
      .then((res) => {
        const post = res.data;
        dispatch(getPostSuccess(post));
        dispatch(getHistory(postId));
        console.log("NO-ERROR", post);
        return post;
      })
      .catch((err) => {
        const errorMsg = err.message;
        console.log("ERROR", err);
        dispatch(getPostFailure(errorMsg));
        return err.message;
      });
  };
};
const getPostRequest = () => ({
  type: actions.GET_POST_REQUEST,
});

const getPostSuccess = (posts) => ({
  type: actions.GET_POST_SUCCESS,
  payload: posts,
  //posts: action.payload (das gleiche?)
});

const getPostFailure = (error) => ({
  type: actions.GET_POST_FAILURE,
  payload: {
    error,
  },
});

export const searchPosts = (title) => {
  return (dispatch) => {
    dispatch(searchPostsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/posts/searchTerm/posts/${title}`,
    })
      .then((res) => {
        const post = res.data.rows;
        dispatch(searchPostsSuccess(post));
        return post;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(searchPostsFailure(errorMsg));
      });
  };
};
const searchPostsRequest = () => ({
  type: actions.SEARCH_POSTS_REQUEST,
});

const searchPostsSuccess = (posts) => ({
  type: actions.SEARCH_POSTS_SUCCESS,
  payload: posts,
});

const searchPostsFailure = (error) => ({
  type: actions.SEARCH_POSTS_FAILURE,
  payload: {
    error,
  },
});

export const reportPost = (
  postId,
  pub,
  rep,
  author_id,
  class_id,
  type,
  noteId
) => {
  return (dispatch) => {
    dispatch(reportPostRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/posts/reportPost/${postId}`,
      data: {
        published: pub,
        reported: rep,
      },
    })
      .then((res) => {
        const post = res.data[0];
        dispatch(reportPostSuccess(post));
        if (type && author_id) {
          dispatch(postNotification(postId, author_id, class_id, type));
        } else if (type === "published") {
          dispatch(postNotification(postId, author_id, class_id, type));
        }

        /*  if (type === "published") {
          dispatch(seenNotifications(noteId));
        } */
        return post;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(reportPostFailure(errorMsg));
        return errorMsg;
      });
  };
};
const reportPostRequest = () => ({
  type: actions.REPORT_POST_REQUEST,
});

const reportPostSuccess = (post) => ({
  type: actions.REPORT_POST_SUCCESS,
  payload: post,
});

const reportPostFailure = (error) => ({
  type: actions.REPORT_POST_FAILURE,
  payload: {
    error,
  },
});

export const postPost = (
  title,
  text,
  id,
  categoryId,
  updateId,
  role,
  class_id,
  type
) => {
  return (dispatch) => {
    dispatch(postPostRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/posts`,
      data: {
        title: title,
        text: text,
        author_id: id,
        category_id: categoryId,
        creation_date: "now",
        published: false,
        author_id_update: updateId,
        class_role: role,
      },
    })
      .then((res) => {
        dispatch(postPostSuccess(res.data[0]));
        const post_id = res.data[0].id;
        if (
          role === 1 ||
          role === 2 ||
          role === 3 ||
          role === 4 ||
          role === 5 ||
          role === 6
        ) {
          dispatch(postNotification(post_id, id, class_id, type));
        } else {
          dispatch(reportPost(post_id, true, false));
        }
      })
      .catch((err) => {
        dispatch(postPostFailure(err));
        throw new Error("Da ging was schief. Probier es noch einmal");
      });
  };
};

const postPostRequest = () => ({
  type: actions.POST_POST_REQUEST,
});

const postPostSuccess = (post) => ({
  type: actions.POST_POST_SUCCESS,
  payload: post,
});

const postPostFailure = (error) => ({
  type: actions.POST_POST_FAILURE,
  payload: {
    error,
  },
});

export const addClick = (id) => {
  return () => {
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/posts/addClick/${id}`,
    })
      .then((res) => {})
      .catch((err) => {});
  };
};
export const deletePost = (id) => {
  return (dispatch) => {
    dispatch(deletePostRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/posts/delete/${id}`,
    })
      .then((res) => {
        dispatch(deletePostSuccess(res.data));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(deletePostFailure(errorMsg));
      });
  };
};
const deletePostRequest = () => ({
  type: actions.DELETE_POST_REQUEST,
});

const deletePostSuccess = (id) => ({
  type: actions.DELETE_POST_SUCCESS,
  payload: id,
});

const deletePostFailure = (error) => ({
  type: actions.DELETE_POST_FAILURE,
  payload: {
    error,
  },
});
/* ---------------------------------------- auth ----------------------------------------*/

export const login = (userName, password) => {
  return (dispatch) => {
    dispatch(loginRequest());
    return axios({
      method: "post",
      url: `${config.API_ENDPOINT}/api/pageContentPublic/login`,
      data: {
        user_name: userName,
        password: password,
      },
    })
      .then((res) => {
        const user = res.data;

        const token = user.token;
        const decoded = jwt_decode(token);
        localStorage.setItem("token", user.token);
        dispatch(loginSuccess(user));
        if (
          user.accepted_terms_of_use ||
          (user.role_id !== 7 && user.role_id !== 8)
        ) {
          dispatch(getUser());
          dispatch(getRole(user.user_name));
          dispatch(getPosts());
          dispatch(getNotifications(user.class_id, user.role_id, user.id));
          dispatch(getClassPosts(user.class_id));
          dispatch(getKidiStatus(user.id));
        }
        return user;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(loginFailure(errorMsg));
        throw new Error("Da ging was schief. Probier es noch einmal");
      });
  };
};
const loginRequest = () => ({
  type: actions.LOGIN_REQUEST,
});

const loginSuccess = (user) => ({
  type: actions.LOGIN_SUCCESS,
  payload: user,
});

const loginFailure = (error) => ({
  type: actions.LOGIN_FAILURE,
  payload: {
    error,
  },
});

export const logOut = () => ({
  type: actions.LOGOUT,
});

export const acceptTermsOfUse = (id) => {
  return (dispatch) => {
    /*     dispatch(loginRequest());
     */ return axios({
      method: "patch",
      url: `${config.API_ENDPOINT}/api/pageContentPublic/acceptedTermsOfUse/${id}`,
    });
  };
};

/* ---------------------------------------- user ----------------------------------------*/
export const getUsers = () => {
  return (dispatch) => {
    dispatch(getUsersRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/users/`,
    })
      .then((res) => {
        const users = res.data;

        dispatch(getUsersSuccess(users));
        return users;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getUsersFailure(errorMsg));
      });
  };
};
const getUsersRequest = () => ({
  type: actions.GET_USERS_REQUEST,
});

const getUsersSuccess = (users) => ({
  type: actions.GET_USERS_SUCCESS,
  payload: users,
});

const getUsersFailure = (error) => ({
  type: actions.GET_USERS_FAILURE,
  payload: {
    error,
  },
});
export const getAllUsers = () => {
  return (dispatch) => {
    dispatch(getAllUsersRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/users/allUsers`,
    })
      .then((res) => {
        const users = res.data;
        dispatch(getAllUsersSuccess(users));
        return users;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getAllUsersFailure(errorMsg));
      });
  };
};
const getAllUsersRequest = () => ({
  type: actions.GET_ALL_USERS_REQUEST,
});

const getAllUsersSuccess = (users) => ({
  type: actions.GET_ALL_USERS_SUCCESS,
  payload: users,
});

const getAllUsersFailure = (error) => ({
  type: actions.GET_ALL_USERS_FAILURE,
  payload: {
    error,
  },
});
export const getUser = () => {
  return (dispatch) => {
    dispatch(getUserRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/users/user`,
    })
      .then((res) => {
        const user = res.data;
        dispatch(getUserSuccess(user));
        return user;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getUserFailure(errorMsg));
      });
  };
};
const getUserRequest = () => ({
  type: actions.GET_USER_REQUEST,
});

const getUserSuccess = (user) => ({
  type: actions.GET_USER_SUCCESS,
  payload: user,
});

const getUserFailure = (error) => ({
  type: actions.GET_USER_FAILURE,
  payload: {
    error,
  },
});

export const getMyStudents = (classId) => {
  return (dispatch) => {
    dispatch(getMyStudentsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/users/myStudents`,
      data: {
        classId: classId,
      },
    })
      .then((res) => {
        const students = res.data;
        dispatch(getMyStudentsSuccess(students));
        return students;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getMyStudentsFailure(errorMsg));
      });
  };
};
const getMyStudentsRequest = () => ({
  type: actions.GET_MY_STUDENTS_REQUEST,
});

const getMyStudentsSuccess = (students) => ({
  type: actions.GET_MY_STUDENTS_SUCCESS,
  payload: students,
});

const getMyStudentsFailure = (error) => ({
  type: actions.GET_MY_STUDENTS_FAILURE,
  payload: {
    error,
  },
});

export const postUser = (
  userName,
  firstName,
  lastName,
  password,
  schoolId,
  classId,
  roleId,
  email
) => {
  return (dispatch) => {
    dispatch(postUserRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/users/register/newUser`,
      data: {
        user_name: userName,
        first_name: firstName,
        last_name: lastName,
        password: password,
        school_id: schoolId,
        class_id: classId,
        role_id: roleId,
        email: email,
      },
    })
      .then((res) => {
        const user = res.data[0];
        dispatch(postUserSuccess(user));
        return user;
      })

      .catch((err) => {
        const errorMsg = err.message;
        dispatch(postUserFailure(errorMsg));
        throw new Error("Der Benutzer konnte nicht angelegt werden!");
      });
  };
};
const postUserRequest = () => ({
  type: actions.POST_USER_REQUEST,
});

const postUserSuccess = (user) => ({
  type: actions.POST_USER_SUCCESS,
  payload: user,
});

const postUserFailure = (error) => ({
  type: actions.POST_USER_FAILURE,
  payload: {
    error,
  },
});

export const updatePassword = (password, userId) => {
  return (dispatch) => {
    dispatch(updatePasswordRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/users/${userId}/change_password`,
      data: {
        password: password,
      },
    })
      .then((res) => {
        dispatch(updatePasswordSuccess());
        return res.status;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(updatePasswordFailure(errorMsg));
      });
  };
};
const updatePasswordRequest = () => ({
  type: actions.UPDATE_PASSWORD_REQUEST,
});

const updatePasswordSuccess = () => ({
  type: actions.UPDATE_PASSWORD_SUCCESS,
});

const updatePasswordFailure = (error) => ({
  type: actions.UPDATE_PASSWORD_FAILURE,
  payload: {
    error,
  },
});

export const getRole = (user_name) => {
  return (dispatch) => {
    dispatch(getRoleRequest());
    return axios({
      method: "post",
      url: `${config.API_ENDPOINT}/api/pageContentPublic/get_role`,
      data: {
        user_name: user_name,
      },
    })
      .then((res) => {
        const role = res.data;
        dispatch(getRoleSuccess(role));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getRoleFailure(errorMsg));
      });
  };
};
const getRoleRequest = () => ({
  type: actions.GET_ROLE_REQUEST,
});

const getRoleSuccess = (role) => ({
  type: actions.GET_ROLE_SUCCESS,
  payload: role,
});

const getRoleFailure = (error) => ({
  type: actions.GET_ROLE_FAILURE,
  payload: {
    error,
  },
});

export const updateUser = (id, data) => {
  return (dispatch) => {
    dispatch(updateUserRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/users/update/${id}`,
      data: data,
    })
      .then((res) => {
        const response = res.data[0];
        dispatch(updateUserSuccess(response));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(updateUserFailure(errorMsg));
      });
  };
};
const updateUserRequest = () => ({
  type: actions.UPDATE_USER_REQUEST,
});

const updateUserSuccess = (response) => ({
  type: actions.UPDATE_USER_SUCCESS,
  payload: response,
});

const updateUserFailure = (error) => ({
  type: actions.UPDATE_USER_FAILURE,
  payload: {
    error,
  },
});
export const deleteUser = (id, classId) => {
  return (dispatch) => {
    dispatch(deleteUserRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/users/delete/${id}/${classId}`,
    })
      .then((res) => {
        dispatch(deleteUserSuccess(res.data));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(deleteUserFailure(errorMsg));
      });
  };
};
const deleteUserRequest = () => ({
  type: actions.DELETE_USER_REQUEST,
});

const deleteUserSuccess = (id) => ({
  type: actions.DELETE_USER_SUCCESS,
  payload: id,
});

const deleteUserFailure = (error) => ({
  type: actions.DELETE_USER_FAILURE,
  payload: {
    error,
  },
});
export const higherGrade = () => {
  return (dispatch) => {
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/users/grade/higher`,
    })
      .then((res) => {})
      .catch((err) => {
        // TODO
        // const errorMsg = err.message;
      });
  };
};
export const lowerGrade = () => {
  return (dispatch) => {
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/users/grade/lower`,
    })
      .then((res) => {})
      .catch((err) => {
        // TODO
        // const errorMsg = err.message;
      });
  };
};
export const addClass = (classId, userId) => {
  return (dispatch) => {
    dispatch(addClassRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/users/update/classes`,
      data: {
        classId,
        userId,
      },
    })
      .then((res) => {
        const newClassArr = res.data[0].class_id;
        dispatch(addClassSuccess(newClassArr));

        return newClassArr;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(addClassFailure(errorMsg));
        throw new Error("Die Klasse konnte nicht hinzugefügt werden");
      });
  };
};
const addClassRequest = () => ({
  type: actions.ADD_CLASS_REQUEST,
});

const addClassSuccess = (newClassArr) => ({
  type: actions.ADD_CLASS_SUCCESS,
  payload: newClassArr,
});

const addClassFailure = (error) => ({
  type: actions.ADD_CLASS_FAILURE,
  payload: {
    error,
  },
});
/* ---------------------------------------- categories ----------------------------------------*/

export const getCategories = () => {
  return (dispatch) => {
    dispatch(getCategoriesRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/categories`,
    })
      .then((res) => {
        const categories = res.data;
        dispatch(getCategoriesSuccess(categories));
        dispatch(getAllSubCategories());
        return categories;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getCategoriesFailure(errorMsg));
      });
  };
};
const getCategoriesRequest = () => ({
  type: actions.GET_CATEGORIES_REQUEST,
});

const getCategoriesSuccess = (categories) => ({
  type: actions.GET_CATEGORIES_SUCCESS,
  payload: categories,
});

const getCategoriesFailure = (error) => ({
  type: actions.GET_CATEGORIES_FAILURE,
  payload: {
    error,
  },
});

export const getAllSubCategories = () => {
  return (dispatch) => {
    dispatch(getAllSubCategoriesRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/categories/subcategories`,
    })
      .then((res) => {
        const categories = res.data;
        dispatch(getAllSubCategoriesSuccess(categories));
        return categories;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getAllSubCategoriesFailure(errorMsg));
      });
  };
};
const getAllSubCategoriesRequest = () => ({
  type: actions.GET_ALL_SUB_CATEGORIES_REQUEST,
});

const getAllSubCategoriesSuccess = (categories) => ({
  type: actions.GET_ALL_SUB_CATEGORIES_SUCCESS,
  payload: categories,
});

const getAllSubCategoriesFailure = (error) => ({
  type: actions.GET_ALL_SUB_CATEGORIES_FAILURE,
  payload: {
    error,
  },
});

export const getParentId = (parentName) => {
  return (dispatch) => {
    dispatch(getParentIdRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/categories/parent/${parentName}`,
    })
      .then((res) => {
        const categoryId = res.data[0];
        dispatch(getParentIdSuccess(categoryId));
        dispatch(getSubCategories(categoryId.id));
        dispatch(getCategoryPosts(categoryId.id));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getParentIdFailure(errorMsg));
      });
  };
};
const getParentIdRequest = () => ({
  type: actions.GET_PARENTID_REQUEST,
});

const getParentIdSuccess = (categories) => ({
  type: actions.GET_PARENTID_SUCCESS,
  payload: categories,
});

const getParentIdFailure = (error) => ({
  type: actions.GET_PARENTID_FAILURE,
  payload: {
    error,
  },
});

export const getSubCategories = (parentId) => {
  return (dispatch) => {
    dispatch(getSubCategoriesRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/categories/subcategories/${parentId}`,
    })
      .then((res) => {
        const subcategories = res.data;
        dispatch(getSubCategoriesSuccess(subcategories));
        return subcategories;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getSubCategoriesFailure(errorMsg));
      });
  };
};
const getSubCategoriesRequest = () => ({
  type: actions.GET_SUBCATEGORIES_REQUEST,
});

const getSubCategoriesSuccess = (subcategories) => ({
  type: actions.GET_SUBCATEGORIES_SUCCESS,
  payload: subcategories,
});

const getSubCategoriesFailure = (error) => ({
  type: actions.GET_SUBCATEGORIES_FAILURE,
  payload: {
    error,
  },
});

export const postCategory = (name, parent, imageUrl) => {
  return (dispatch) => {
    dispatch(postCategoryRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/categories/newCategory/category`,
      data: {
        name: name,
        parent: parent,
        imageUrl: imageUrl,
      },
    })
      .then((res) => {
        const category = res.data.rows[0];
        dispatch(postCategorySuccess(category));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(postCategoryFailure(errorMsg));
      });
  };
};
const postCategoryRequest = () => ({
  type: actions.POST_CATEGORY_REQUEST,
});

const postCategorySuccess = (category) => ({
  type: actions.POST_CATEGORY_SUCCESS,
  payload: category,
});

const postCategoryFailure = (error) => ({
  type: actions.POST_CATEGORY_FAILURE,
  payload: {
    error,
  },
});
export const updateCategory = (category_id, data) => {
  return (dispatch) => {
    dispatch(updateCategoryRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/categories/update/${category_id}`,
      data: data,
    })
      .then((res) => {
        const category = res.data[0];
        dispatch(updateCategorySuccess(category));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(updateCategoryFailure(errorMsg));
      });
  };
};
const updateCategoryRequest = () => ({
  type: actions.UPDATE_CATEGORY_REQUEST,
});

const updateCategorySuccess = (category) => ({
  type: actions.UPDATE_CATEGORY_SUCCESS,
  payload: category,
});

const updateCategoryFailure = (error) => ({
  type: actions.UPDATE_CATEGORY_FAILURE,
  payload: {
    error,
  },
});

export const deleteCategory = (category_id) => {
  return (dispatch) => {
    dispatch(deleteCategoryRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/categories/delete/${category_id}`,
    })
      .then((res) => {
        dispatch(deleteCategorySuccess(res.data));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(deleteCategoryFailure(errorMsg));
      });
  };
};
const deleteCategoryRequest = () => ({
  type: actions.DELETE_CATEGORY_REQUEST,
});

const deleteCategorySuccess = (category_id) => ({
  type: actions.DELETE_CATEGORY_SUCCESS,
  payload: category_id,
});

const deleteCategoryFailure = (error) => ({
  type: actions.DELETE_CATEGORY_FAILURE,
  payload: {
    error,
  },
});

/* ---------------------------------------- content ----------------------------------------*/

export const getHelp = (id, type) => {
  return (dispatch) => {
    dispatch(getHelpRequest());
    if (isNaN(id)) {
      /*  für Error und Success */
      dispatch(getHelpSuccess(id, type));
      dispatch(toggleHelpRedux(true));
    } else {
      return axios({
        headers: {
          "auth-token": localStorage.getItem("token"),
        },
        method: "get",
        url: `${config.API_ENDPOINT}/api/pageContentPublic/helpText/${id}`,
      })
        .then((res) => {
          const text = res.data[0].content;
          dispatch(getHelpSuccess(text, "help"));
          return text;
        })
        .catch((err) => {
          const errorMsg = err.message;
          dispatch(getHelpFailure(errorMsg));
        });
    }
  };
};

const getHelpRequest = () => ({
  type: actions.GET_HELPTEXT_REQUEST,
});

const getHelpSuccess = (text, type) => ({
  type: actions.GET_HELPTEXT_SUCCESS,
  payload: { text, type },
});

const getHelpFailure = (error) => ({
  type: actions.GET_HELPTEXT_FAILURE,
  payload: {
    error,
  },
});
export const getHelpTexts = () => {
  return (dispatch) => {
    dispatch(getHelpTextsRequest());
    return axios({
      method: "get",
      url: `${config.API_ENDPOINT}/api/pageContentPublic/help`,
    })
      .then((res) => {
        const text = res.data;
        dispatch(getHelpTextsSuccess(text));
        return text;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getHelpTextsFailure(errorMsg));
      });
  };
};

const getHelpTextsRequest = () => ({
  type: actions.GET_HELPTEXTS_REQUEST,
});

const getHelpTextsSuccess = (text) => ({
  type: actions.GET_HELPTEXTS_SUCCESS,
  payload: text,
});

const getHelpTextsFailure = (error) => ({
  type: actions.GET_HELPTEXTS_FAILURE,
  payload: {
    error,
  },
});
export const updateHelpTexts = (id, data) => {
  return (dispatch) => {
    dispatch(updateHelpTextsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/helpTexts/update/${id}`,
      data: data,
    })
      .then((res) => {
        const response = res.data[0];
        dispatch(updateHelpTextsSuccess(response));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(updateHelpTextsFailure(errorMsg));
      });
  };
};
const updateHelpTextsRequest = () => ({
  type: actions.UPDATE_HELPTEXTS_REQUEST,
});

const updateHelpTextsSuccess = (response) => ({
  type: actions.UPDATE_HELPTEXTS_SUCCESS,
  payload: response,
});

const updateHelpTextsFailure = (error) => ({
  type: actions.UPDATE_HELPTEXTS_FAILURE,
  payload: {
    error,
  },
});
export const toggleHelpRedux = (boolean) => {
  return {
    type: actions.TOGGLE_HELP,
    payload: boolean,
  };
};

/* ---------------------------------------- School ----------------------------------------*/

export const getSchools = () => {
  return (dispatch) => {
    dispatch(getSchoolsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/schools/`,
    })
      .then((res) => {
        const schools = res.data;
        dispatch(getSchoolsSuccess(schools));
        return schools;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getSchoolsFailure(errorMsg));
      });
  };
};
const getSchoolsRequest = () => ({
  type: actions.GET_SCHOOLS_REQUEST,
});

const getSchoolsSuccess = (schools) => ({
  type: actions.GET_SCHOOLS_SUCCESS,
  payload: schools,
});

const getSchoolsFailure = (error) => ({
  type: actions.GET_SCHOOLS_FAILURE,
  payload: {
    error,
  },
});

export const postSchool = (name, street, number, plz, city, phone, email) => {
  return (dispatch) => {
    dispatch(postSchoolRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/schools/register/newSchool`,
      data: {
        name,
        street,
        number,
        plz,
        city,
        phone,
        email,
      },
    })
      .then((res) => {
        const school = res.data[0];
        dispatch(postSchoolSuccess(school));
        return school;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(postSchoolFailure(errorMsg));
        throw new Error("Da ging was schief. Probier es noch einmal");
      });
  };
};
const postSchoolRequest = () => ({
  type: actions.POST_SCHOOL_REQUEST,
});

const postSchoolSuccess = (school) => ({
  type: actions.POST_SCHOOL_SUCCESS,
  payload: school,
});

const postSchoolFailure = (error) => ({
  type: actions.POST_SCHOOL_FAILURE,
  payload: {
    error,
  },
});

export const updateSchool = (id, data) => {
  return (dispatch) => {
    dispatch(updateSchoolRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/schools/update/${id}`,
      data: data,
    })
      .then((res) => {
        const response = res.data[0];
        dispatch(updateSchoolSuccess(response));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(updateSchoolFailure(errorMsg));
      });
  };
};
const updateSchoolRequest = () => ({
  type: actions.UPDATE_SCHOOL_REQUEST,
});

const updateSchoolSuccess = (response) => ({
  type: actions.UPDATE_SCHOOL_SUCCESS,
  payload: response,
});

const updateSchoolFailure = (error) => ({
  type: actions.UPDATE_SCHOOL_FAILURE,
  payload: {
    error,
  },
});

export const deleteSchool = (id) => {
  return (dispatch) => {
    dispatch(deleteSchoolRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/schools/delete/${id}`,
    })
      .then((res) => {
        dispatch(deleteSchoolSuccess(res.data));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(deleteSchoolFailure(errorMsg));
      });
  };
};
const deleteSchoolRequest = () => ({
  type: actions.DELETE_SCHOOL_REQUEST,
});

const deleteSchoolSuccess = (id) => ({
  type: actions.DELETE_SCHOOL_SUCCESS,
  payload: id,
});

const deleteSchoolFailure = (error) => ({
  type: actions.DELETE_SCHOOL_FAILURE,
  payload: {
    error,
  },
});
/* ---------------------------------------- classes ----------------------------------------*/
export const getAllClasses = (schoolId) => {
  return (dispatch) => {
    dispatch(getClassesRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/classes/all/classes`,
      data: {
        schoolId,
      },
    })
      .then((res) => {
        const classes = res.data;
        dispatch(getClassesSuccess(classes));
        return classes;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getClassesFailure(errorMsg));
      });
  };
};
/* const getClassesRequest = () => ({
  type: actions.GET_CLASSES_REQUEST,
});

const getClassesSuccess = (classes) => ({
  type: actions.GET_CLASSES_SUCCESS,
  payload: classes,
});

const getClassesFailure = (error) => ({
  type: actions.GET_CLASSES_FAILURE,
  payload: {
    error,
  },
}); */
export const getClasses = (id) => {
  return (dispatch) => {
    dispatch(getClassesRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/classes/${id}`,
    })
      .then((res) => {
        const classes = res.data;
        dispatch(getClassesSuccess(classes));

        return classes;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getClassesFailure(errorMsg));
      });
  };
};
const getClassesRequest = () => ({
  type: actions.GET_CLASSES_REQUEST,
});

const getClassesSuccess = (classes) => ({
  type: actions.GET_CLASSES_SUCCESS,
  payload: classes,
});

const getClassesFailure = (error) => ({
  type: actions.GET_CLASSES_FAILURE,
  payload: {
    error,
  },
});

export const getClassDetails = (id) => {
  return (dispatch) => {
    dispatch(getClassDetailsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/classes/classDetails/${id}`,
    })
      .then((res) => {
        const classes = res.data;
        dispatch(getClassDetailsSuccess(classes));
        return classes;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getClassDetailsFailure(errorMsg));
      });
  };
};
const getClassDetailsRequest = () => ({
  type: actions.GET_CLASSDETAILS_REQUEST,
});

const getClassDetailsSuccess = (classes) => ({
  type: actions.GET_CLASSDETAILS_SUCCESS,
  payload: classes,
});

const getClassDetailsFailure = (error) => ({
  type: actions.GET_CLASSDETAILS_FAILURE,
  payload: {
    error,
  },
});

export const postClass = (class_name, school_id, teacher_id) => {
  return (dispatch) => {
    dispatch(postClassRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/classes/register/newClass`,
      data: {
        class_name,
        school_id,
        teacher_id,
      },
    })
      .then((res) => {
        const addedClass = res.data[0];
        dispatch(postClassSuccess(addedClass));
        dispatch(addClass(addedClass.id, teacher_id));
        return addedClass;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(postClassFailure(errorMsg));
        throw new Error("Da ging was schief. Probier es noch einmal");
      });
  };
};
const postClassRequest = () => ({
  type: actions.POST_CLASS_REQUEST,
});

const postClassSuccess = (addedClass) => ({
  type: actions.POST_CLASS_SUCCESS,
  payload: addedClass,
});

const postClassFailure = (error) => ({
  type: actions.POST_CLASS_FAILURE,
  payload: {
    error,
  },
});
export const updateClass = (id, data) => {
  return (dispatch) => {
    dispatch(updateClassRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/classes/update/${id}`,
      data: data,
    })
      .then((res) => {
        const response = res.data[0];
        dispatch(updateClassSuccess(response));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(updateClassFailure(errorMsg));
      });
  };
};
const updateClassRequest = () => ({
  type: actions.UPDATE_CLASS_REQUEST,
});

const updateClassSuccess = (response) => ({
  type: actions.UPDATE_CLASS_SUCCESS,
  payload: response,
});

const updateClassFailure = (error) => ({
  type: actions.UPDATE_CLASS_FAILURE,
  payload: {
    error,
  },
});
export const deleteClasses = (id) => {
  return (dispatch) => {
    dispatch(deleteClassesRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/classes/delete/${id}`,
    })
      .then((res) => {
        ////id
        /*         dispatch(deleteUser(undefined, id));
         */ dispatch(deleteClassesSuccess(res.data));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(deleteClassesFailure(errorMsg));
      });
  };
};
const deleteClassesRequest = () => ({
  type: actions.DELETE_CLASSES_REQUEST,
});

const deleteClassesSuccess = (id) => ({
  type: actions.DELETE_CLASSES_SUCCESS,
  payload: id,
});

const deleteClassesFailure = (error) => ({
  type: actions.DELETE_CLASSES_FAILURE,
  payload: {
    error,
  },
});
/* ---------------------------------------- roles ----------------------------------------*/

export const getRoles = () => {
  return (dispatch) => {
    dispatch(getRolesRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/roles`,
    })
      .then((res) => {
        const roles = res.data;
        dispatch(getRolesSuccess(roles));
        return roles;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getRolesFailure(errorMsg));
      });
  };
};
const getRolesRequest = () => ({
  type: actions.GET_ROLES_REQUEST,
});

const getRolesSuccess = (roles) => ({
  type: actions.GET_ROLES_SUCCESS,
  payload: roles,
});

const getRolesFailure = (error) => ({
  type: actions.GET_ROLES_FAILURE,
  payload: {
    error,
  },
});

/* ---------------------------------------- history ----------------------------------------*/

export const postHistory = (obj) => {
  return (dispatch) => {
    dispatch(postHistoryRequest());

    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/postsHistory/newPost`,
      data: obj,
    })
      .then((res) => {
        const post = res.data.rows[0];
        dispatch(postHistorySuccess(post));
      })
      .catch((err) => {
        dispatch(postHistoryFailure(err));
        console.log(err);
      });
  };
};

const postHistoryRequest = () => ({
  type: actions.POST_HISTORY_REQUEST,
});

const postHistorySuccess = (post) => ({
  type: actions.POST_HISTORY_SUCCESS,
  payload: post,
});

const postHistoryFailure = (error) => ({
  type: actions.POST_HISTORY_FAILURE,
  payload: {
    error,
  },
});
export const getHistory = (postId) => {
  return (dispatch) => {
    dispatch(getHistoryRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/postsHistory/history/${postId}`,
    })
      .then((res) => {
        const history = res.data;
        dispatch(getHistorySuccess(history));
        return history;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getHistoryFailure(errorMsg));
      });
  };
};
const getHistoryRequest = () => ({
  type: actions.GET_HISTORY_REQUEST,
});

const getHistorySuccess = (history) => ({
  type: actions.GET_HISTORY_SUCCESS,
  payload: history,
});

const getHistoryFailure = (error) => ({
  type: actions.GET_HISTORY_FAILURE,
  payload: {
    error,
  },
});

export const getPostsIWorkedOn = (author_id) => {
  return (dispatch) => {
    dispatch(getPostsIWorkedOnRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/postsHistory/history/workedOn/${author_id}`,
    })
      .then((res) => {
        const posts = res.data;
        dispatch(getPostsIWorkedOnSuccess(posts));
        return posts;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getPostsIWorkedOnFailure(errorMsg));
      });
  };
};
const getPostsIWorkedOnRequest = () => ({
  type: actions.GET_POSTS_I_WORKED_ON_REQUEST,
});

const getPostsIWorkedOnSuccess = (posts) => ({
  type: actions.GET_POSTS_I_WORKED_ON_SUCCESS,
  payload: posts,
});

const getPostsIWorkedOnFailure = (error) => ({
  type: actions.GET_POSTS_I_WORKED_ON_FAILURE,
  payload: error,
});

/* ---------------------------------------- notes ----------------------------------------*/
export const getNotes = (postId) => {
  return (dispatch) => {
    dispatch(getNotesRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/postsNotes/notes/${postId}`,
    })
      .then((res) => {
        const notes = res.data;
        dispatch(getNotesSuccess(notes));
        return notes;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getNotesFailure(errorMsg));
      });
  };
};
const getNotesRequest = () => ({
  type: actions.GET_NOTES_REQUEST,
});

const getNotesSuccess = (notes) => ({
  type: actions.GET_NOTES_SUCCESS,
  payload: notes,
});

const getNotesFailure = (error) => ({
  type: actions.GET_NOTES_FAILURE,
  payload: {
    error,
  },
});

export const postNote = (postId, text, authorId, user_name) => {
  return (dispatch) => {
    dispatch(postNoteRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/postsNotes/newNote`,
      data: {
        post_id: postId,
        text: text,
        author_id: authorId,
        creation_date: "now",
      },
    })
      .then((res) => {
        const note = res.data;
        dispatch(postNoteSuccess(note, user_name));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(postNoteFailure(errorMsg));
      });
  };
};
const postNoteRequest = () => ({
  type: actions.POST_NOTE_REQUEST,
});

const postNoteSuccess = (note, user_name) => ({
  type: actions.POST_NOTE_SUCCESS,
  payload: { note, user_name },
});

const postNoteFailure = (error) => ({
  type: actions.POST_NOTE_FAILURE,
  payload: {
    error,
  },
});
export const deletePostNotes = (id) => {
  return (dispatch) => {
    dispatch(deletePostNotesRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/posts_notes/delete/${id}`,
    })
      .then((res) => {
        dispatch(deletePostNotesSuccess(res.data));
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(deletePostNotesFailure(errorMsg));
      });
  };
};
const deletePostNotesRequest = () => ({
  type: actions.DELETE_POSTNOTES_REQUEST,
});

const deletePostNotesSuccess = (id) => ({
  type: actions.DELETE_POSTNOTES_SUCCESS,
  payload: id,
});

const deletePostNotesFailure = (error) => ({
  type: actions.DELETE_POSTNOTES_FAILURE,
  payload: {
    error,
  },
});
/* ---------------------------------------- Stars ----------------------------------------*/

export const giveStars = (
  postId,
  teacherId,
  version,
  stars,
  author_id,
  class_id,
  type
) => {
  return (dispatch) => {
    /*     dispatch(updateStarsRequest()); */
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/starRatings/post`,
      data: {
        post_id: postId,
        teacher_id: teacherId,
        version: version,
        stars: stars,
      },
    })
      .then((res) => {
        console.log(res);
        dispatch(getRating(teacherId, postId));
        dispatch(postNotification(postId, author_id, class_id, type, stars));

        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        console.log(errorMsg);
      });
  };
};
export const updateStars = (
  postId,
  teacherId,
  version,
  stars,
  author_id,
  class_id,
  type
) => {
  return (dispatch) => {
    /*     dispatch(updateStarsRequest()); */
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/starRatings/patch`,
      data: {
        post_id: postId,
        teacher_id: teacherId,
        version: version,
        stars: stars,
      },
    })
      .then((res) => {
        dispatch(getRating(teacherId, postId));
        dispatch(postNotification(postId, author_id, class_id, type, stars));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        console.log(errorMsg);
      });
  };
};
export const getStars = (postId) => {
  return (dispatch) => {
    dispatch(getStarsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/starRatings/id/${postId}`,
    })
      .then((res) => {
        const starsArr = res.data;
        const ratings = starsArr.length;

        const allStars = starsArr
          .map((rating) => rating.stars)
          .reduce((a, b) => a + b, 0);
        dispatch(getStarsSuccess(ratings, allStars));
        const stars = allStars / ratings;
        return stars;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getStarsFailure(errorMsg));
      });
  };
};
const getStarsRequest = () => ({
  type: actions.GET_STARS_REQUEST,
});

const getStarsSuccess = (ratings, allStars) => ({
  type: actions.GET_STARS_SUCCESS,
  payload: { ratings, allStars },
});

const getStarsFailure = (error) => ({
  type: actions.GET_STARS_FAILURE,
  payload: {
    error,
  },
});

export const getRating = (userId, postId) => {
  return (dispatch) => {
    dispatch(getRatingRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/starRatings/user/${userId}/${postId}`,
    })
      .then((res) => {
        const myRating = () => {
          if (res.data.length === 0) {
            return null;
          } else {
            return res.data[0].stars;
          }
        };

        dispatch(getRatingSuccess(myRating()));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getRatingFailure(errorMsg));
      });
  };
};
const getRatingRequest = () => ({
  type: actions.GET_RATING_REQUEST,
});

const getRatingSuccess = (myRating) => ({
  type: actions.GET_RATING_SUCCESS,
  payload: myRating,
});

const getRatingFailure = (error) => ({
  type: actions.GET_RATING_FAILURE,
  payload: {
    error,
  },
});
/* ---------------------------------------- notifications ----------------------------------------*/

export const postNotification = (
  post_id,
  student_id,
  class_id,
  type,
  stars
) => {
  return (dispatch) => {
    dispatch(getRatingRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "post",
      url: `${config.API_ENDPOINT}/api/notifications`,
      data: {
        post_id: post_id,
        student_id: student_id,
        class_id: class_id,
        type: type,
        stars: stars,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getRatingFailure(errorMsg));
      });
  };
};

export const getNotifications = () => {
  return (dispatch) => {
    dispatch(getNotificationsRequest());
    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "get",
      url: `${config.API_ENDPOINT}/api/notifications/getNotifications`,
    })
      .then((res) => {
        const getNotifications = res.data;
        dispatch(getNotificationsSuccess(getNotifications));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getNotificationsFailure(errorMsg));
      });
  };
};
const getNotificationsRequest = () => ({
  type: actions.GET_NOTIFICATIONS_REQUEST,
});

const getNotificationsSuccess = (notifications) => ({
  type: actions.GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

const getNotificationsFailure = (error) => ({
  type: actions.GET_NOTIFICATIONS_FAILURE,
  payload: {
    error,
  },
});

export const seenNotifications = (id) => {
  return (dispatch) => {
    dispatch(seenNotificationsRequest());

    return axios({
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      method: "patch",
      url: `${config.API_ENDPOINT}/api/notifications/seen/${id}`,
    })
      .then((res) => {
        const notification = res.data.rows[0];
        dispatch(seenNotificationsSuccess(notification));

        return res;
      })
      .catch((error) => {
        dispatch(seenNotificationsFailure(error));
      });
  };
};

const seenNotificationsRequest = () => ({
  type: actions.SEEN_NOTIFICATIONS_REQUEST,
});

const seenNotificationsSuccess = (notification) => ({
  type: actions.SEEN_NOTIFICATIONS_SUCCESS,
  payload: notification,
});

const seenNotificationsFailure = (error) => ({
  type: actions.SEEN_NOTIFICATIONS_FAILURE,
  payload: error,
});

/* ---------------------------------------- page content ----------------------------------------*/

export const getPageContent = () => {
  return (dispatch) => {
    dispatch(getPageContentRequest());
    return axios({
      method: "get",
      url: `${config.API_ENDPOINT}/api/pageContentPublic`,
    })
      .then((res) => {
        const content = res.data;
        dispatch(getPageContentSuccess(content));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(getPageContentFailure(errorMsg));
      });
  };
};
const getPageContentRequest = () => ({
  type: actions.GET_PAGE_CONTENT_REQUEST,
});

const getPageContentSuccess = (content) => ({
  type: actions.GET_PAGE_CONTENT_SUCCESS,
  payload: content,
});

const getPageContentFailure = (error) => ({
  type: actions.GET_PAGE_CONTENT_FAILURE,
  payload: error,
});

export const postPageContent = (text, authorId, type) => {
  return (dispatch) => {
    dispatch(postPageContentRequest());
    return axios({
      method: "post",
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      url: `${config.API_ENDPOINT}/api/pageContent/post`,
      data: {
        text: text,
        author_id: authorId,
        type: type,
      },
    })
      .then((res) => {
        const content = res.data[0];
        dispatch(postPageContentSuccess(content));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(postPageContentFailure(errorMsg));
      });
  };
};
const postPageContentRequest = () => ({
  type: actions.POST_PAGE_CONTENT_REQUEST,
});

const postPageContentSuccess = (content) => ({
  type: actions.POST_PAGE_CONTENT_SUCCESS,
  payload: content,
});

const postPageContentFailure = (error) => ({
  type: actions.POST_PAGE_CONTENT_FAILURE,
  payload: error,
});

export const updatePageContent = (text, authorId, type) => {
  return (dispatch) => {
    dispatch(updatePageContentRequest());
    return axios({
      method: "patch",
      headers: {
        "auth-token": localStorage.getItem("token"),
      },
      url: `${config.API_ENDPOINT}/api/pageContent/update`,
      data: {
        text: text,
        author_id: authorId,
        type: type,
      },
    })
      .then((res) => {
        const content = res.data[0];
        dispatch(updatePageContentSuccess(content));
        return res;
      })
      .catch((err) => {
        const errorMsg = err.message;
        dispatch(updatePageContentFailure(errorMsg));
      });
  };
};
const updatePageContentRequest = () => ({
  type: actions.UPDATE_PAGE_CONTENT_REQUEST,
});

const updatePageContentSuccess = (content) => ({
  type: actions.UPDATE_PAGE_CONTENT_SUCCESS,
  payload: content,
});

const updatePageContentFailure = (error) => ({
  type: actions.UPDATE_PAGE_CONTENT_FAILURE,
  payload: error,
});
