import React from "react";
import { Link } from "react-router-dom";

import "./Administration.css";

const Administration = () => {
  return (
    <div className="body-container">
      <div className="grid-administration">
        <Link to="/administration/SchulenUndKlassen" className="box1">
          <div>Schulen und Klassen</div>
        </Link>
        <Link to="/administration/Benutzer" className="box2">
          <div>Benutzer</div>
        </Link>
        <Link to="/administration/Beiträge" className="box3">
          <div>Beiträge</div>
        </Link>
        <Link to="/administration/Kategorien" className="box4">
          <div>Kategorien</div>
        </Link>
        <Link to="/administration/PageAdministration" className="box5">
          <div>Seitenadministration</div>
        </Link>
        <Link to="/administration/Statistik" className="box6">
          <div>Statistik</div>
        </Link>
      </div>
    </div>
  );
};

export default Administration;
