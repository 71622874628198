export const karte = `<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#F1F9FC;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st1{fill:#CED5D8;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st2{fill:#2C5A24;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st3{fill:#366D29;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st4{fill:none;stroke:#F1FDFF;stroke-width:0.25;stroke-miterlimit:10;}
	.st5{fill:#AE1D4B;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st6{fill:#CE2159;}
	.st7{fill:#F1FDFF;}
</style>
<g>
	<polygon class="st0" points="25.3,37.7 5.8,78.7 33.6,88.7 41.1,40.6 	"/>
	<polygon class="st1" points="63.3,79.2 33.6,88.7 41.1,40.6 59,39.6 	"/>
	<polygon class="st0" points="59,39.6 75.6,40.7 98.6,88.7 63.3,79.2 	"/>
	<polygon class="st2" points="34.6,81.9 63,72.5 59.5,46 40.5,46 	"/>
	<polygon class="st3" points="62.6,72.6 85.3,78.9 71.4,45.9 61.3,45.1 59.7,46 	"/>
	<polygon class="st3" points="27.3,44.5 13.5,74.8 34.7,81.9 40.4,46 	"/>
	<line class="st4" x1="74.9" y1="53.9" x2="61.8" y2="63.7"/>
	<line class="st4" x1="61.5" y1="63.8" x2="38.5" y2="59.1"/>
	<line class="st4" x1="38" y1="59.4" x2="17.1" y2="76"/>
	<path class="st5" d="M51.4,20.9c0.7,0,2,0.1,3.5,0.6c0.3,0.1,1.1,0.3,2.1,0.9c1.1,0.5,3.2,1.6,5,3.8c1.9,2.2,2.4,4.5,2.6,5
		c0.9,3.6,0,6.5-0.5,8.1c-0.4,1.3-0.9,2.4-1.5,3.4c-0.5,1.1-1.1,2-2.9,4.8c-1.3,2-1.9,2.8-2.2,3.3c-0.1,0.1-0.2,0.3-0.5,0.7
		c-0.5,0.8-0.9,1.4-1.1,1.6c-1,1.5-1.1,1.5-1.8,2.4c-1.1,1.8-2,3.2-2,3.2c-0.2,0.4-0.5,0.9-0.9,0.9c-0.4,0-0.7-0.5-0.9-0.9
		c-0.5-0.8-1.1-1.5-1.7-2.4c-1-1.5-1-1.3-2.2-3.1c-0.6-0.9-0.7-1.1-1.3-1.9c-0.7-1.1-1.1-1.4-2.2-3.2c-0.3-0.5,0,0-2.3-4.1
		c-0.9-1.6-1.5-2.6-2.1-4.1c-0.3-0.7-0.5-1.3-0.7-2c-0.5-2.1-0.4-3.8-0.3-4.6c0.1-1,0.3-2.9,1.3-5.1c0.4-0.8,1.3-2.6,3.2-4.2
		c1.9-1.6,3.8-2.1,5.1-2.5C48,21.3,49.5,20.9,51.4,20.9z"/>
	<path class="st6" d="M51.5,58.2c-0.3-0.5-0.6-0.9-0.9-1.3c-0.3-0.3-0.5-0.7-0.7-1.1c-0.5-0.8-0.8-1.1-1.1-1.5
		c-0.3-0.3-0.6-0.7-1.2-1.7L47.2,52c-0.3-0.4-0.5-0.7-0.9-1.3c-0.2-0.3-0.4-0.5-0.6-0.7c-0.5-0.6-0.9-1.1-1.7-2.4
		c-0.1-0.2-0.1-0.3-0.3-0.5c-0.2-0.4-0.7-1.2-2-3.6l-0.1-0.1c-0.9-1.5-1.4-2.4-2-3.9c-0.3-0.7-0.5-1.2-0.7-1.9
		c-0.5-1.9-0.4-3.5-0.3-4.3c0.1-0.9,0.3-2.7,1.3-4.8c0.3-0.7,1.1-2.4,2.9-3.8c1.7-1.5,3.5-2,4.8-2.3c0.7-0.2,2.1-0.6,3.7-0.6
		c0.1,0,0.2,0,0.3,0c1.1,0,2.2,0.2,3.2,0.5c0.6,0.2,1.3,0.5,2,0.8c1.1,0.6,3,1.5,4.7,3.5c1.6,1.9,2.2,3.9,2.4,4.6
		c0.9,3.3-0.1,6.2-0.5,7.7c-0.4,1.3-0.9,2.2-1.4,3.3c-0.5,1.1-1.1,1.9-2.9,4.7c-1.3,1.9-1.9,2.8-2.2,3.3l-0.5,0.7
		c-0.5,0.7-0.9,1.3-1.1,1.5c-0.5,0.8-0.8,1.1-1.1,1.5c-0.3,0.3-0.5,0.6-0.8,1.1c-1.1,1.8-2,3.2-2,3.3s-0.1,0.1-0.1,0.2
		C51.7,58.4,51.5,58.2,51.5,58.2z"/>
	<circle class="st0" cx="51.2" cy="35.7" r="7.3"/>
	<polygon class="st7" points="37.9,59.3 38.2,59.1 38.1,59.5 38,59.3 	"/>
	<polygon class="st7" points="61.4,63.9 61.5,63.9 61.5,63.7 61.4,63.8 	"/>
	<polygon class="st7" points="61.7,63.6 61.9,63.7 61.9,63.8 61.8,63.9 	"/>
</g>
</svg>`;
