import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getSchools,
  postSchool,
  getHelp,
  toggleHelpRedux,
  getAllClasses,
  postClass,
  updateClass,
  deleteClasses,
  updateSchool,
  deleteSchool,
  postUser,
} from "../../../actions";
import styles from "./SchoolsAndClasses.module.css";
import { MdDelete, MdEdit } from "react-icons/md";
import Select from "react-select";
import { TiArrowUnsorted } from "react-icons/ti";
import UpdateModal from "../../../components/UpdateModal/UpdateModal";

const SchoolsAndClasses = ({
  getSchools,
  postSchool,
  getHelp,
  toggleHelpRedux,
  getAllClasses,
  updateClass,
  updateSchool,
  postClass,
  user,
  schools,
  users,
  classes,
  deleteClasses,
  deleteSchool,
  postUser,
}) => {
  const [schoolsList, setSchoolsList] = useState([]);
  const [classesList, setClassesList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalTeacher, setModalTeacher] = useState(false);
  const [modalClass, setModalClass] = useState(false);
  const [className, setClassName] = useState("");
  const [schoolId, setSchoolId] = useState(null);
  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [number, setNumber] = useState(null);
  const [plz, setPlz] = useState(null);
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState("");
  const [validateName, setValidateName] = useState(false);

  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [teacherSchoolId, setTeacherSchoolId] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState("");

  /////classesModal
  const [modalUpdate, setModalUpdate] = useState(false);
  const [editId, setEditId] = React.useState();
  const [updatedName, setupdatedName] = React.useState("");

  /////schoolModal
  const [modalUpdateSchool, setModalUpdateSchool] = useState(false);
  const [updatedNameSchool, setupdatedNameSchool] = React.useState("");
  const [updatedStreetSchool, setupdatedStreetSchool] = React.useState("");
  const [updatedNumberSchool, setupdatedNumberSchool] = React.useState("");
  const [updatedPLZSchool, setupdatedPLZSchool] = React.useState("");
  const [updatedPhoneSchool, setupdatedPhoneSchool] = React.useState("");
  const [updatedMailSchool, setupdatedMailSchool] = React.useState("");
  const [updatedCitySchool, setupdatedCitySchool] = React.useState("");

  useEffect(() => {
    async function fetchSchools() {
      await getSchools();
      await getAllClasses();
    }
    fetchSchools();
  }, [getAllClasses, getSchools]);

  useEffect(() => {
    setSchoolsList(schools);
  }, [schools]);
  useEffect(() => {
    setClassesList(classes);
  }, [classes]);

  const renderList = (data, name) => {
    return data.map((d) => ({
      label: d[name],
      value: d.id,
    }));
  };
  const handleChangeSchool = (selectedOption) => {
    if (selectedOption !== null) {
      setSchoolId(selectedOption.value);
    } else {
      setSchoolId(null);
    }
  };
  const handlePostSchool = () => {
    postSchool(name, street, number, plz, city, phone, email)
      .then((res) => {
        setModal(false);
        setModalTeacher(true);
        setTeacherSchoolId(res.id);
        getHelp("Neue Schule wurde angelegt", "success");
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
      })
      .catch((err) => {
        getHelp(err.message, "error");
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
      });
  };
  const handlePostClass = () => {
    postClass(className, schoolId, user.id)
      .then((res) => {
        setModalClass(false);
        setSchoolId(null);
        setClassName("");
        getHelp("Neue Klasse wurde angelegt", "success");
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
      })
      .catch((err) => {
        getHelp(err.message, "error");
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
      });
  };
  const sortingSchools = (property) => {
    const copy = [...schoolsList];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] !== null && b[property] !== null) {
        const nameA = a[property].toLowerCase();
        const nameB = b[property].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        return 0;
      }
    });
    setSchoolsList(copy);
    if (copy[0].id === schoolsList[0].id) {
      copy.reverse();
    }
  };

  const sortingClasses = (property) => {
    const copy = [...classesList];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] !== null && b[property] !== null) {
        const nameA = a[property].toLowerCase();
        const nameB = b[property].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        return 0;
      }
    });
    setClassesList(copy);

    if (copy[0].id === classesList[0].id) {
      copy.reverse();
    }
  };
  const handleEdit = (c) => {
    setModalUpdate(true);
    setupdatedName(c.class_name);
    setEditId(c.id);
  };

  const handleEditSchool = (school) => {
    setModalUpdateSchool(true);
    setupdatedNameSchool(school.name);
    setupdatedStreetSchool(school.street);
    setupdatedNumberSchool(school.number);
    setupdatedPLZSchool(school.plz);
    setupdatedPhoneSchool(school.phone);
    setupdatedMailSchool(school.email);
    setupdatedCitySchool(school.city);
    setEditId(school.id);
  };
  const handleChangeClass = () => {
    updateClass(editId, {
      class_name: updatedName,
    }).then(setModalUpdate(false));
  };

  const handleUpdateSchool = () => {
    updateSchool(editId, {
      name: updatedNameSchool,
      street: updatedStreetSchool,
      number: updatedNumberSchool,
      plz: updatedPLZSchool,
      city: updatedCitySchool,
      phone: updatedPhoneSchool,
      email: updatedMailSchool,
    }).then(setModalUpdateSchool(false));
  };
  const saveUser = (
    userName,
    firstName,
    lastName,
    password,
    school,
    teacherEmail
  ) => {
    const userNameValidation = () => {
      const split = userName.split("");
      const space = [" "];
      if (userName.length > 2 && !split.some((e) => space.includes(e))) {
        return true;
      } else {
        getHelp("Der kidi-Name ist nicht lange genug");
        toggleHelpRedux(true);
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
        return false;
      }
    };
    const passwordValidation = () => {
      const split = password.split("");

      const upper = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      const lower = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      const number = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
      const space = [" "];
      if (
        password.length > 4 &&
        split.some((e) => upper.includes(e)) &&
        split.some((e) => lower.includes(e)) &&
        split.some((e) => number.includes(e)) &&
        !split.some((e) => space.includes(e))
      ) {
        return true;
      } else {
        getHelp("Das Passwort entspricht nicht den Richtlinien");
        toggleHelpRedux(true);
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
        return false;
      }
    };
    const userNameTrim = userName.trim();

    if (passwordValidation() && userNameValidation()) {
      postUser(
        userNameTrim,
        firstName,
        lastName,
        password,
        school,
        teacherEmail
      );
      setModalTeacher(false);
      getHelp("Schule und Schuladmin wurden angelegt", "success");
      toggleHelpRedux(true);
      setTimeout(function () {
        toggleHelpRedux(false);
      }, 6000);
    } else {
      setModalTeacher(false);
      getHelp(
        "Passwort oder kidi-Name entsprechen nicht den Richtlinien",
        "error"
      );
      toggleHelpRedux(true);
      setTimeout(function () {
        toggleHelpRedux(false);
      }, 6000);
    }
  };
  const handleKidiName = (e) => {
    setUserName(e.target.value);
    const findUserName = users.filter((u) => u.user_name === e.target.value);
    if (findUserName.length === 0 && e.target.value.length > 2) {
      setValidateName(true);
    } else {
      setValidateName(false);
    }
  };
  return (
    <div className="body-container">
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={modalTeacher ? styles.modal : styles.noModal}>
            <div className={styles.frame}>
              <div className={styles.columnUser}>
                <h2>Schuladmin anlegen</h2>
                <div className={styles.inputfields}>
                  <div className={styles.labelRow}>
                    <div className={styles.label}>kidi-Name *</div>
                    <input
                      className={
                        validateName ? styles.inputGreen : styles.inputRed
                      }
                      type="text"
                      value={userName}
                      onChange={handleKidiName}
                    ></input>
                  </div>
                  <div className={styles.labelRow}>
                    <div className={styles.label}>Vorname</div>
                    <input
                      className={styles.input}
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    ></input>
                  </div>
                  <div className={styles.labelRow}>
                    <div className={styles.label}>Nachname</div>
                    <input
                      className={styles.input}
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    ></input>
                  </div>

                  <div className={styles.labelRow}>
                    <div className={styles.label}>Email</div>
                    <input
                      className={styles.input}
                      type="email"
                      value={teacherEmail}
                      onChange={(e) => setTeacherEmail(e.target.value)}
                    ></input>
                  </div>

                  <div className={styles.labelRow}>
                    <div className={styles.label}>Passwort *</div>
                    <input
                      className={styles.input}
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></input>
                  </div>
                </div>
                <button
                  className="button"
                  onClick={() => {
                    saveUser(
                      userName,
                      firstName,
                      lastName,
                      password,
                      teacherSchoolId,
                      teacherEmail
                    );
                  }}
                >
                  kidi-User speichern
                </button>
                <button
                  className={`button ${styles.margin}`}
                  onClick={() => setModalTeacher(false)}
                >
                  abbrechen
                </button>
              </div>
            </div>
          </div>

          <div className={modalUpdate ? styles.modal : styles.noModal}>
            <UpdateModal
              inputFields={[{ input: updatedName, state: setupdatedName }]}
              setModal={setModalUpdate}
              handleChangeCategory={handleChangeClass}
            />
          </div>

          <div className={modalUpdateSchool ? styles.modal : styles.noModal}>
            <UpdateModal
              inputFields={[
                {
                  input: updatedNameSchool,
                  state: setupdatedNameSchool,
                  placeholder: "Name",
                },
                {
                  input: updatedStreetSchool,
                  state: setupdatedStreetSchool,
                  placeholder: "Strasse",
                },
                {
                  input: updatedNumberSchool,
                  state: setupdatedNumberSchool,
                  placeholder: "Nummer",
                },
                {
                  input: updatedPLZSchool,
                  state: setupdatedPLZSchool,
                  placeholder: "PLZ",
                },
                {
                  input: updatedPhoneSchool,
                  state: setupdatedPhoneSchool,
                  placeholder: "Telefon",
                },
                {
                  input: updatedMailSchool,
                  state: setupdatedMailSchool,
                  placeholder: "Email",
                },
                {
                  input: updatedCitySchool,
                  state: setupdatedCitySchool,
                  placeholder: "Stadt",
                },
              ]}
              setModal={setModalUpdateSchool}
              handleChangeCategory={handleUpdateSchool}
            />
          </div>

          <div className={modal ? styles.modal : styles.noModal}>
            <div className={styles.frame}>
              <h4>Füge eine neue Schule hinzu:</h4>
              <input
                className={styles.input}
                placeholder="Name der Schule *"
                onChange={(e) => setName(e.target.value)}
              ></input>
              <div className={`${styles.row} ${styles.inputRow}`}>
                <input
                  className={styles.input}
                  placeholder="Strasse *"
                  onChange={(e) => setStreet(e.target.value)}
                ></input>
                <input
                  className={styles.input}
                  placeholder="Nr *"
                  onChange={(e) => setNumber(e.target.value)}
                ></input>
              </div>
              <div className={`${styles.row} ${styles.inputRow}`}>
                <input
                  className={styles.input}
                  placeholder="PLZ *"
                  onChange={(e) => setPlz(e.target.value)}
                ></input>
                <input
                  className={styles.input}
                  placeholder="Stadt *"
                  onChange={(e) => setCity(e.target.value)}
                ></input>
              </div>
              <div className={`${styles.row} ${styles.inputRow}`}>
                <input
                  className={styles.input}
                  placeholder="Telefonnummer"
                  onChange={(e) => setPhone(e.target.value)}
                ></input>
                <input
                  className={styles.input}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
              <div className={`${styles.row} ${styles.inputRow}`}>
                <button
                  className={`button ${styles.margin}`}
                  onClick={() => handlePostSchool()}
                >
                  speichern
                </button>
                <button
                  className={`button ${styles.margin}`}
                  onClick={() => setModal(false)}
                >
                  abbrechen
                </button>
              </div>
            </div>
          </div>

          <div className={modalClass ? styles.modal : styles.noModal}>
            <div className={styles.frame}>
              <h4>Füge eine neue Klasse hinzu:</h4>
              <Select
                placeholder="wähle eine Schule..."
                className={styles.select}
                options={renderList(schools, "name")}
                onChange={handleChangeSchool}
              />
              <input
                className={styles.input}
                placeholder="Name der Klasse *"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
              ></input>

              <div className={`${styles.row} ${styles.inputRow}`}>
                <button
                  className={`button ${styles.margin}`}
                  onClick={() => handlePostClass()}
                >
                  speichern
                </button>
                <button
                  className={`button ${styles.margin}`}
                  onClick={() => setModalClass(false)}
                >
                  abbrechen
                </button>
              </div>
            </div>
          </div>

          <div className={styles.row}>
            <h3>Schulen</h3>
            <div className={styles.add} onClick={() => setModal(true)}>
              + Schule mit Schuladmin hinzufügen
            </div>
          </div>
          <div className={styles.header}>
            <div onClick={() => sortingSchools("name")}>
              Name <TiArrowUnsorted />
            </div>
            <div onClick={() => sortingSchools("phone")}>
              Telefon <TiArrowUnsorted />
            </div>
            <div onClick={() => sortingSchools("email")}>
              Mail <TiArrowUnsorted />
            </div>
            <div onClick={() => sortingSchools("city")}>
              Stadt <TiArrowUnsorted />
            </div>
            <div onClick={() => sortingSchools("school_admin")}>
              Schuladmin <TiArrowUnsorted />
            </div>
            <p className={styles.iconSpace}></p>
            <p className={styles.iconSpace}></p>
          </div>

          <div className={styles.height}>
            {console.log(schoolsList)}
            {schoolsList.map((school) => (
              <div className={styles.rowList} key={school.id}>
                <div className={styles.list}> {school.name}</div>
                <div className={styles.list}> {school.phone}</div>
                <div className={styles.list}> {school.email}</div>
                <div className={styles.list}> {school.city}</div>
                <div className={styles.list}>{school.school_admin}</div>

                <div
                  className={styles.delete}
                  onClick={() => {
                    handleEditSchool(school);
                  }}
                >
                  <MdEdit className={styles.icons} />
                </div>
                <div
                  className={styles.delete}
                  onClick={() => {
                    if (
                      window.confirm(
                        `Die Schule "${school.name}" wirklich löschen?`
                      )
                    ) {
                      deleteSchool(school.id);
                    }
                  }}
                >
                  <MdDelete className={styles.icons} />
                </div>
              </div>
            ))}
          </div>
          <div className={styles.row}>
            <h3>Klassen</h3>
            <div className={styles.add} onClick={() => setModalClass(true)}>
              + Klasse hinzufügen
            </div>
          </div>
          <div className={styles.header}>
            <div onClick={() => sortingClasses("class_name")}>
              Name <TiArrowUnsorted />
            </div>
            <p className={styles.icons}></p>
          </div>

          <div className={styles.height}>
            {console.log(classesList)}{" "}
            {classesList.map((c) => (
              <div className={styles.rowList} key={`classes-${c.id}`}>
                <div className={styles.list}> {c.class_name}</div>
                <div className="row">
                  <div
                    className={styles.delete}
                    onClick={() => {
                      handleEdit(c);
                    }}
                  >
                    <MdEdit className={styles.icons} />
                  </div>
                  <div
                    className={styles.delete}
                    onClick={() => {
                      if (
                        window.confirm(
                          `Die Klasse "${c.class_name}" wirklich löschen?`
                        )
                      ) {
                        deleteClasses(c.id);
                      }
                    }}
                  >
                    <MdDelete className={styles.icons} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    schools: state.schools.schools,
    classes: state.classes.classes,
    loading: state.posts.loadings,
    user: state.user,
    users: state.users.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSchools: () => dispatch(getSchools()),
    postSchool: (name, street, number, plz, city, phone, email) =>
      dispatch(postSchool(name, street, number, plz, city, phone, email)),
    getHelp: (res, type) => dispatch(getHelp(res, type)),
    toggleHelpRedux: (boolean) => dispatch(toggleHelpRedux(boolean)),
    getAllClasses: () => dispatch(getAllClasses()),
    postClass: (className, schoolId, id) =>
      dispatch(postClass(className, schoolId, id)),
    deleteClasses: (id) => dispatch(deleteClasses(id)),
    deleteSchool: (id) => dispatch(deleteSchool(id)),
    updateClass: (id, data) => dispatch(updateClass(id, data)),
    updateSchool: (id, data) => dispatch(updateSchool(id, data)),
    postUser: (
      userName,
      firstName,
      lastName,
      password,
      schoolId,
      teacherEmail
    ) =>
      dispatch(
        postUser(
          userName,
          firstName,
          lastName,
          password,
          schoolId,
          [],
          8,
          teacherEmail
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoolsAndClasses);
