///////////////////POST
export const GET_POSTS_REQUEST = "GET_POSTS_REQUEST";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE";

export const GET_ALL_POSTS_REQUEST = "GET_ALL_POSTS_REQUEST";
export const GET_ALL_POSTS_SUCCESS = "GET_ALL_POSTS_SUCCESS";
export const GET_ALL_POSTS_FAILURE = "GET_ALL_POSTS_FAILURE";

export const GET_MY_POSTS_REQUEST = "GET_MY_POSTS_REQUEST";
export const GET_MY_POSTS_SUCCESS = "GET_MY_POSTS_SUCCESS";
export const GET_MY_POSTS_FAILURE = "GET_MY_POSTS_FAILURE";

export const GET_MOST_STARS_REQUEST = "GET_MOST_STARS_REQUEST";
export const GET_MOST_STARS_SUCCESS = "GET_MOST_STARS_SUCCESS";
export const GET_MOST_STARS_FAILURE = "GET_MOST_STARS_FAILURE";

export const GET_STARS_REQUEST = "GET_STARS_REQUEST";
export const GET_STARS_SUCCESS = "GET_STARS_SUCCESS";
export const GET_STARS_FAILURE = "GET_STARS_FAILURE";

export const GET_KIDI_STATUS_REQUEST = "GET_KIDI_STATUS_REQUEST";
export const GET_KIDI_STATUS_SUCCESS = "GET_KIDI_STATUS_SUCCESS";
export const GET_KIDI_STATUS_FAILURE = "GET_KIDI_STATUS_FAILURE";

export const UPDATE_KIDI_STATUS_REQUEST = "UPDATE_KIDI_STATUS_REQUEST";
export const UPDATE_KIDI_STATUS_SUCCESS = "UPDATE_KIDI_STATUS_SUCCESS";
export const UPDATE_KIDI_STATUS_FAILURE = "UPDATE_KIDI_STATUS_FAILURE";

export const GET_POSTS_STATUS_REQUEST = "GET_POSTS_STATUS_REQUEST";
export const GET_POSTS_STATUS_SUCCESS = "GET_POSTS_STATUS_SUCCESS";
export const GET_POSTS_STATUS_FAILURE = "GET_POSTS_STATUS_FAILURE";

export const GET_RATING_REQUEST = "GET_RATING_REQUEST";
export const GET_RATING_SUCCESS = "GET_RATING_SUCCESS";
export const GET_RATING_FAILURE = "GET_RATING_FAILURE";

export const GET_CLASS_POSTS_REQUEST = "GET_CLASS_POSTS_REQUEST";
export const GET_CLASS_POSTS_SUCCESS = "GET_CLASS_POSTS_SUCCESS";
export const GET_CLASS_POSTS_FAILURE = "GET_CLASS_POSTS_FAILURE";

export const GET_CATEGORY_POSTS_REQUEST = "GET_CATEGORY_POSTS_REQUEST";
export const GET_CATEGORY_POSTS_SUCCESS = "GET_CATEGORY_POSTS_SUCCESS";
export const GET_CATEGORY_POSTS_FAILURE = "GET_CATEGORY_POSTS_FAILURE";

export const GET_POST_REQUEST = "GET_POST_REQUEST";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_FAILURE = "GET_POST_FAILURE";

export const UPDATE_POST_REQUEST = "UPDATE_POST_REQUEST";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_FAILURE = "UPDATE_POST_FAILURE";

export const REPORT_POST_REQUEST = "REPORT_POST_REQUEST";
export const REPORT_POST_SUCCESS = "REPORT_POST_SUCCESS";
export const REPORT_POST_FAILURE = "REPORT_POST_FAILURE";

export const POST_POST_REQUEST = "POST_POST_REQUEST";
export const POST_POST_SUCCESS = "POST_POST_SUCCESS";
export const POST_POST_FAILURE = "POST_POST_FAILURE";

export const SEARCH_POSTS_REQUEST = "SEARCH_POSTS_REQUEST";
export const SEARCH_POSTS_SUCCESS = "SEARCH_POSTS_SUCCESS";
export const SEARCH_POSTS_FAILURE = "SEARCH_POSTS_FAILURE";

export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

/////////////////////AUTH
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT   ";

/////////////////USER
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_MY_STUDENTS_REQUEST = "GET_MY_STUDENTS_REQUEST";
export const GET_MY_STUDENTS_SUCCESS = "GET_MY_STUDENTS_SUCCESS";
export const GET_MY_STUDENTS_FAILURE = "GET_MY_STUDENTS_FAILURE";

export const GET_TEACHER_REQUEST = "GET_TEACHER_REQUEST";
export const GET_TEACHER_SUCCESS = "GET_TEACHER_SUCCESS";
export const GET_TEACHER_FAILURE = "GET_TEACHER_FAILURE";

export const GET_ROLE_REQUEST = "GET_ROLE_REQUEST";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_FAILURE = "GET_ROLE_FAILURE";

export const POST_USER_REQUEST = "POST_USER_REQUEST";
export const POST_USER_SUCCESS = "POST_USER_SUCCESS";
export const POST_USER_FAILURE = "POST_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const ADD_CLASS_REQUEST = "ADD_CLASS_REQUEST";
export const ADD_CLASS_SUCCESS = "ADD_CLASS_SUCCESS";
export const ADD_CLASS_FAILURE = "ADD_CLASS_FAILURE";
////////////////CATEGORIES
export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILURE = "GET_CATEGORIES_FAILURE";

export const GET_ALL_SUB_CATEGORIES_REQUEST = "GET_ALL_SUB_CATEGORIES_REQUEST";
export const GET_ALL_SUB_CATEGORIES_SUCCESS = "GET_ALL_SUB_CATEGORIES_SUCCESS";
export const GET_ALL_SUB_CATEGORIES_FAILURE = "GET_ALL_SUB_CATEGORIES_FAILURE";

export const GET_PARENTID_REQUEST = "GET_PARENTID_REQUEST";
export const GET_PARENTID_SUCCESS = "GET_PARENTID_SUCCESS";
export const GET_PARENTID_FAILURE = "GET_PARENTID_FAILURE";

export const GET_SUBCATEGORIES_REQUEST = "GET_SUBCATEGORIES_REQUEST";
export const GET_SUBCATEGORIES_SUCCESS = "GET_SUBCATEGORIES_SUCCESS";
export const GET_SUBCATEGORIES_FAILURE = "GET_SUBCATEGORIES_FAILURE";

export const POST_CATEGORY_REQUEST = "POST_CATEGORY_REQUEST";
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS";
export const POST_CATEGORY_FAILURE = "POST_CATEGORY_FAILURE";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

////////////////////////HELPTEXT
export const GET_HELPTEXT_REQUEST = "GET_HELPTEXT_REQUEST";
export const GET_HELPTEXT_SUCCESS = "GET_HELPTEXT_SUCCESS";
export const GET_HELPTEXT_FAILURE = "GET_HELPTEXT_FAILURE";

export const GET_HELPTEXTS_REQUEST = "GET_HELPTEXTS_REQUEST";
export const GET_HELPTEXTS_SUCCESS = "GET_HELPTEXTS_SUCCESS";
export const GET_HELPTEXTS_FAILURE = "GET_HELPTEXTS_FAILURE";

export const UPDATE_HELPTEXTS_REQUEST = "UPDATE_HELPTEXTS_REQUEST";
export const UPDATE_HELPTEXTS_SUCCESS = "UPDATE_HELPTEXTS_SUCCESS";
export const UPDATE_HELPTEXTS_FAILURE = "UPDATE_HELPTEXTS_FAILURE";

export const TOGGLE_HELP = "TOGGLE_HELP";

//////////////////////SCHOOLS
export const GET_SCHOOLS_REQUEST = "GET_SCHOOLS_REQUEST";
export const GET_SCHOOLS_SUCCESS = "GET_SCHOOLS_SUCCESS";
export const GET_SCHOOLS_FAILURE = "GET_SCHOOLS_FAILURE";

export const POST_SCHOOL_REQUEST = "POST_SCHOOL_REQUEST";
export const POST_SCHOOL_SUCCESS = "POST_SCHOOL_SUCCESS";
export const POST_SCHOOL_FAILURE = "POST_SCHOOL_FAILURE";

export const UPDATE_SCHOOL_REQUEST = "UPDATE_SCHOOL_REQUEST";
export const UPDATE_SCHOOL_SUCCESS = "UPDATE_SCHOOL_SUCCESS";
export const UPDATE_SCHOOL_FAILURE = "UPDATE_SCHOOL_FAILURE";

export const DELETE_SCHOOL_REQUEST = "DELETE_SCHOOL_REQUEST";
export const DELETE_SCHOOL_SUCCESS = "DELETE_SCHOOL_SUCCESS";
export const DELETE_SCHOOL_FAILURE = "DELETE_SCHOOL_FAILURE";
///////////////////////CLASSES
export const GET_CLASSES_REQUEST = "GET_CLASSES_REQUEST";
export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS";
export const GET_CLASSES_FAILURE = "GET_CLASSES_FAILURE";

export const GET_CLASSDETAILS_REQUEST = "GET_CLASSDETAILS_REQUEST";
export const GET_CLASSDETAILS_SUCCESS = "GET_CLASSDETAILS_SUCCESS";
export const GET_CLASSDETAILS_FAILURE = "GET_CLASSDETAILS_FAILURE";

export const POST_CLASS_REQUEST = "POST_CLASS_REQUEST";
export const POST_CLASS_SUCCESS = "POST_CLASS_SUCCESS";
export const POST_CLASS_FAILURE = "POST_CLASS_FAILURE";

export const UPDATE_CLASS_REQUEST = "UPDATE_CLASS_REQUEST";
export const UPDATE_CLASS_SUCCESS = "UPDATE_CLASS_SUCCESS";
export const UPDATE_CLASS_FAILURE = "UPDATE_CLASS_FAILURE";

export const DELETE_CLASSES_REQUEST = "DELETE_CLASSES_REQUEST";
export const DELETE_CLASSES_SUCCESS = "DELETE_CLASSES_SUCCESS";
export const DELETE_CLASSES_FAILURE = "DELETE_CLASSES_FAILURE";
/////////////////////ROLES
export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

//////////////////////History
export const GET_HISTORY_REQUEST = "GET_HISTORY_REQUEST";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAILURE = "GET_HISTORY_FAILURE";

export const POST_HISTORY_REQUEST = "POST_HISTORY_REQUEST";
export const POST_HISTORY_SUCCESS = "POST_HISTORY_SUCCESS";
export const POST_HISTORY_FAILURE = "POST_HISTORY_FAILURE";

export const GET_POSTS_I_WORKED_ON_REQUEST = "GET_POSTS_I_WORKED_ON_REQUEST";
export const GET_POSTS_I_WORKED_ON_SUCCESS = "GET_POSTS_I_WORKED_ON_SUCCESS";
export const GET_POSTS_I_WORKED_ON_FAILURE = "GET_POSTS_I_WORKED_ON_FAILURE";

//////////////////////Notes
export const GET_NOTES_REQUEST = "GET_NOTES_REQUEST";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_FAILURE = "GET_NOTES_FAILURE";

export const POST_NOTE_REQUEST = "POST_NOTE_REQUEST";
export const POST_NOTE_SUCCESS = "POST_NOTE_SUCCESS";
export const POST_NOTE_FAILURE = "POST_NOTE_FAILURE";

export const DELETE_POSTNOTES_REQUEST = "DELETE_POSTNOTES_REQUEST";
export const DELETE_POSTNOTES_SUCCESS = "DELETE_POSTNOTES_SUCCESS";
export const DELETE_POSTNOTES_FAILURE = "DELETE_POSTNOTES_FAILURE";

///////////////////////NOTIFICATIONS
export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const SEEN_NOTIFICATIONS_REQUEST = "SEEN_NOTIFICATIONS_REQUEST";
export const SEEN_NOTIFICATIONS_SUCCESS = "SEEN_NOTIFICATIONS_SUCCESS";
export const SEEN_NOTIFICATIONS_FAILURE = "SEEN_NOTIFICATIONS_FAILURE";

/////////////////////////pageContent
export const GET_PAGE_CONTENT_REQUEST = "GET_PAGE_CONTENT_REQUEST";
export const GET_PAGE_CONTENT_SUCCESS = "GET_PAGE_CONTENT_SUCCESS";
export const GET_PAGE_CONTENT_FAILURE = "GET_PAGE_CONTENT_FAILURE";

export const POST_PAGE_CONTENT_REQUEST = "POST_PAGE_CONTENT_REQUEST";
export const POST_PAGE_CONTENT_SUCCESS = "POST_PAGE_CONTENT_SUCCESS";
export const POST_PAGE_CONTENT_FAILURE = "POST_PAGE_CONTENT_FAILURE";

export const UPDATE_PAGE_CONTENT_REQUEST = "UPDATE_PAGE_CONTENT_REQUEST";
export const UPDATE_PAGE_CONTENT_SUCCESS = "UPDATE_PAGE_CONTENT_SUCCESS";
export const UPDATE_PAGE_CONTENT_FAILURE = "UPDATE_PAGE_CONTENT_FAILURE";
