import * as actions from "../actions/types";

const initialState = {
  loading: false,
  post: {},
  error: null,
};

const post = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        post: action.payload,
      };
    //[ ...state.posts,action.payload],
    case actions.GET_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.UPDATE_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        post: action.payload,
      };

    case actions.UPDATE_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    /*     case actions.POST_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.POST_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        title: action.payload.title,
        text: action.payload.text,
      };

    case actions.POST_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }; */
    case actions.REPORT_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.REPORT_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        post: {},
        reported: true,
      };

    case actions.REPORT_POST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default post;
