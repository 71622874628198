import * as actions from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  roles: [],
};

const roles = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
      };
    case actions.GET_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default roles;
