import React, { useState, useEffect } from "react";
import styles from "./LandingPage.module.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getMostStars,
  getHelp,
  toggleHelpRedux,
  getPageContent,
} from "../../actions";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import Logo from "../../pictures/Logo.svg";
import Door from "../../pictures/login_pictures/Tuer.svg";
import Landingpage from "../../pictures/Szene/Szene_Startseite.svg";
import GoodBye from "../../pictures/Szene/Szene_bye.svg";
import Loading from "../../components/Loading/Loading";
import students from "../../pictures/information/students.svg";
import teachers from "../../pictures/information/teachers.svg";
import parents from "../../pictures/information/parents.svg";
import kidiEmpfiehlt from "../../pictures/kidi_empfiehlt.svg";
import kidiTop from "../../pictures/kidi_top.svg";

const LandingPage = (props) => {
  const {
    user,
    getHelp,
    loading,
    mostStars,
    toggleHelpRedux,
    pageContent,
  } = props;

  const loggedIn = user.loggedIn;
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    async function fetchPosts() {
      await getMostStars();
      await getPageContent();

      getHelp(1);
    }

    fetchPosts();
  }, [getHelp]);
  /////Hier die Logik für die Auswahl von Posts auf der Landing Page
  /* const postOfTheWeek = posts.slice(0, 3); */
  const handleClick = (id) => {
    const visiblePost = mostStars.filter((post) => post.id === id);
    props.setPost(visiblePost);
  };
  const handleClose = () => {
    props.setPost(0);
  };

  const handlePostOfMonth = () => {
    if (mostStars.length !== 0) {
      const max = mostStars.reduce((prev, current) =>
        prev.clicks > current.clicks ? prev : current
      );
      return max;
    } else {
      return null;
    }
  };
  const handleTopPosts = () => {
    const postOfMonth = handlePostOfMonth();
    const topPosts = mostStars.filter((post) => post.id !== postOfMonth.id);
    return topPosts;
  };
  const filteredContent = pageContent.pageContent.filter((c) => {
    return c.type === "Landingpage";
  });
  const filteredContentBye = pageContent.pageContent.filter((c) => {
    return c.type === "GoodBye";
  });
  const handleInfo = (input) => {
    const text = pageContent.pageContent.filter((c) => c.type === input);
    setContent(text[0].text);
    setModal(true);
  };
  return (
    <div>
      <div className="body-container">
        <div className={modal ? styles.modal : styles.noModal}>
          <div className={styles.frame}>
            <div className={styles.crossContainer}>
              <GrClose
                className={styles.cross}
                onClick={() => setModal(false)}
              />
            </div>
            <div className={styles.infoText}>
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.stretch}>
            <div className={styles.topBox}>
              <div className={styles.question}>
                <div className={styles.space}></div>
                <h2 className="underline">kidi empfiehlt</h2>
                <AiOutlineQuestionCircle
                  className={styles.help}
                  onClick={() => {
                    getHelp(10);
                    toggleHelpRedux();
                  }}
                />
              </div>
              <div
                className={styles.containerMonth}
                onClick={() => handleClick(handlePostOfMonth().id)}
              >
                {handlePostOfMonth() ? (
                  <>
                    <h3 className={styles.mostStars} key={props.post.id}>
                      {handlePostOfMonth().title}
                    </h3>
                    {
                      <div
                        className={styles.postOfMonth}
                        dangerouslySetInnerHTML={{
                          __html: handlePostOfMonth().text,
                        }}
                      ></div>
                    }
                  </>
                ) : null}
              </div>
              <img
                src={kidiEmpfiehlt}
                alt="kidiEmpfiehlt"
                className={styles.imageTopBox}
              />
            </div>
            <div className={styles.welcomeBox}>
              {props.post === 0 ? (
                <>
                  {loggedIn /* && pageContent.loading */ ? (
                    <>
                      <h1 className={styles.middle}>
                        Hallo {user.first_name}!
                      </h1>

                      <img
                        src={Landingpage}
                        className={styles.HelloPicture}
                        alt="Hello"
                      ></img>
                      <h2>
                        {" "}
                        Viel Spaß auf{" "}
                        <img src={Logo} className={styles.Logo} alt="Logo" />
                      </h2>
                    </>
                  ) : (
                    <>
                      {loggedIn === null ? (
                        <>
                          {" "}
                          <div className={styles.headline}>
                            <h1> Herzlich willkommen bei </h1>
                            <img
                              src={Logo}
                              className={styles.Logo}
                              alt="Logo"
                            />
                          </div>
                          {filteredContent[0] ? (
                            <div
                              className={styles.text}
                              dangerouslySetInnerHTML={{
                                __html: filteredContent[0].text,
                              }}
                            ></div>
                          ) : null}
                          <div>
                            <Link to="/anmelden" className="text-decoration">
                              <div className="row">
                                <img src={Door} alt="Tür" className="tuer" />
                                <div className="text">Rein</div>
                              </div>
                            </Link>
                          </div>
                          <div className={styles.information}>
                            <div className={styles.column}>
                              <img
                                className={styles.picture}
                                src={students}
                                alt="Schüler"
                                onClick={() => handleInfo("Students")}
                              />
                              <div onClick={() => handleInfo("Students")}>
                                Infos für Schülerinnen und Schüler
                              </div>
                            </div>
                            <div className={styles.column}>
                              <img
                                className={styles.picture}
                                src={teachers}
                                alt="Lehrkräfte"
                                onClick={() => handleInfo("Teachers")}
                              />
                              <div onClick={() => handleInfo("Teachers")}>
                                Infos für Lehrpersonen
                              </div>
                            </div>
                            <div className={styles.column}>
                              <img
                                className={styles.picture}
                                src={parents}
                                alt="Eltern"
                                onClick={() => handleInfo("Parents")}
                              />
                              <div onClick={() => handleInfo("Parents")}>
                                Infos für Eltern
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <h1>Auf Wiedersehen</h1>
                          <img
                            src={GoodBye}
                            alt="goodBye"
                            className={styles.goodBye}
                          />
                          {filteredContentBye[0] ? (
                            <div
                              className={styles.text}
                              dangerouslySetInnerHTML={{
                                __html: filteredContentBye[0].text,
                              }}
                            />
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                  <br />
                </>
              ) : (
                <div>
                  <div className={styles.titleContainer}>
                    <div className={styles.left}></div>
                    <h2>{props.post[0].title}</h2>
                    <GrClose
                      className={styles.cross}
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </div>
                  <div className={styles.height}>
                    <div
                      dangerouslySetInnerHTML={{ __html: props.post[0].text }}
                      className={styles.postScroll}
                    ></div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.topBox}>
              <div className={styles.question}>
                <div className={styles.space}></div>
                <h2 className="underline">Top Beiträge</h2>
                <AiOutlineQuestionCircle
                  className={styles.help}
                  onClick={() => {
                    getHelp(11);
                    toggleHelpRedux();
                  }}
                />
              </div>

              <div className={styles.containerTop}>
                {handleTopPosts().map((post, i) => (
                  <h3
                    className={styles.mostStars}
                    key={post.id}
                    onClick={() => handleClick(post.id)}
                  >
                    {i + 1}) {post.title}
                  </h3>
                ))}
              </div>
              <img
                src={kidiTop}
                alt="kidiTop"
                className={`${styles.imageTopBox} ${styles.right}`}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    mostStars: state.posts.mostStars,
    loading: state.posts.loading,
    pageContent: state.pageContent,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMostStars: () => dispatch(getMostStars()),
    getHelp: (nr) => dispatch(getHelp(nr)),
    toggleHelpRedux: () => dispatch(toggleHelpRedux()),
    getPageContent: () => dispatch(getPageContent()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
