import React, { useState, useEffect } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import {
  getCategories,
  postCategory,
  deleteCategory,
  getHelp,
  updateCategory,
} from "../../../actions";
import styles from "./Categories.module.css";
import { MdDelete, MdEdit } from "react-icons/md";
import axios from "axios";
import config from "../../../config";
import UpdateModal from "../../../components/UpdateModal/UpdateModal";

const EditCategories = ({
  getCategories,
  postSubCategory,
  postCategory,
  allCategories,
  loading,
  roleId,
  deleteCategory,
  updateCategory,
  getHelp,
}) => {
  const [category, setCategory] = React.useState("");
  const [modal, setModal] = useState(false);
  const [subCategory, setSubCategory] = React.useState("");
  const [parentId, setParentId] = React.useState();
  const [editId, setEditId] = React.useState();
  const [updatedName, setupdatedName] = React.useState("");
  const [updatedPosition, setUpdatedPosition] = useState("");
  const [file, setFile] = useState();
  const [fileSub, setFileSub] = useState();

  const [value, setValue] = useState("");
  const [valueSub, setValueSub] = useState("");

  const categories = allCategories.filter(
    (category) => category.parent === null
  );
  const subCategories = allCategories.filter(
    (category) => category.parent !== null
  );
  useEffect(() => {
    async function fetchCategories() {
      await getCategories();
    }
    fetchCategories();
  }, [getCategories]);

  const renderList = () => {
    return categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  };

  const handleChange = (selectedOption) => {
    const parentId = selectedOption.value;
    setParentId(parentId);
  };
  const handleDelete = (id) => {
    deleteCategory(id);
  };

  const handleEdit = (c) => {
    setModal(true);
    setupdatedName(c.name);
    setUpdatedPosition(c.position);
    setEditId(c.id);
    /*     if (editId === id) {
      setEdittId("");
    } else {
      setEdittId(id);
    }
    const name = allCategories.filter((cate) => cate.id === id)[0].name;
    setupdatedName(name);
  };
  const handleEditChange = (e) => {
    setupdatedName(e.target.value); */
  };

  const handleInput = (e) => {
    setCategory(e.target.value);
  };

  const handleInputSub = (e) => {
    setSubCategory(e.target.value);
  };
  //////////////////////////////////////////////////////////////////////////////////////////////////
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setValue(e.target.files[0].name);
    setFile(file);
  };
  const handleFileInputSub = (e) => {
    const file = e.target.files[0];
    setValueSub(e.target.files[0].name);
    setFileSub(file);
  };
  const saveData = (e) => {
    if (category && file) {
      const data = new FormData();
      data.append("name", category);
      data.append("file", file);
      axios
        .post(`${config.API_ENDPOINT}/api/upload`, data)
        .then(function (res) {
          postCategory(category, res.data.location);
          setCategory("");
          setFile(null);
          setValue("");
        })
        .catch(function (err) {});
    } else {
      getHelp("Der Name oder das Bild für die Unterkategorie fehlen", "error");
    }
  };
  /*   const saveData = () => {
    postCategory(category, res.data.location);
    setCategory("");
  }; */
  const saveSubData = () => {
    if (subCategory && fileSub) {
      const data = new FormData();
      data.append("name", subCategory);
      data.append("file", fileSub);
      axios
        .post(`${config.API_ENDPOINT}/api/upload`, data)
        .then(function (res) {
          postSubCategory(subCategory, parentId, res.data.location);
          setSubCategory("");
          setFileSub(null);
          setValueSub("");
        })
        .catch(function (err) {
          console.log("catch", err);
        });
    } else {
      getHelp("Der Name oder das Bild für die Kategorie fehlen", "error");
    }
  };
  const handleChangeCategory = () => {
    updateCategory(editId, {
      name: updatedName,
      position: updatedPosition,
    }).then(setModal(false));
  };

  const hiddenFileInput = React.useRef(null);
  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };
  const hiddenFileInputSub = React.useRef(null);
  const handleClickSub = (e) => {
    hiddenFileInputSub.current.click();
  };

  return (
    <div className="body-container">
      {loading ? (
        <h1>loading</h1>
      ) : (
        <div className={styles.column}>
          <h2> Oberkategorien</h2>
          {categories.map((category) => (
            <div key={category.id} className={styles.row}>
              <div className="row">
                {category.position}.
                <div className={styles.list}>{category.name}</div>
              </div>

              <div className={styles.end}>
                <div
                  className={styles.delete}
                  onClick={() => {
                    handleEdit(category);
                  }}
                >
                  <MdEdit className={styles.icons} />
                </div>
                <div
                  className={styles.delete}
                  onClick={() => {
                    if (
                      window.confirm(
                        `Die Kategorie "${category.name}" wirklich löschen?`
                      )
                    ) {
                      handleDelete(category.id);
                    }
                  }}
                >
                  <MdDelete className={styles.icons} />
                </div>
              </div>
            </div>
          ))}
          <input
            type="text"
            value={category}
            onChange={handleInput}
            className={styles.input}
            placeholder="neue Oberkategorie hinzufügen..."
          />
          {/*           Modal
           */}{" "}
          <div className={modal ? styles.modal : styles.noModal}>
            <UpdateModal
              updatedName={updatedName}
              setupdatedName={setupdatedName}
              updatedPosition={updatedPosition}
              setUpdatedPosition={setUpdatedPosition}
              inputFields={[
                {
                  input: updatedName,
                  state: setupdatedName,
                  placeholder: "Name",
                },
                {
                  input: updatedPosition,
                  state: setUpdatedPosition,
                  placeholder: "Position",
                },
              ]}
              setModal={setModal}
              handleChangeCategory={handleChangeCategory}
            />
            {/*  <div className={styles.frame}>
              <h3>Bearbeite hier die Kategorie</h3>
              <div className={styles.innerFrame}>
                <div className="row">
                  <div className={styles.label}>Name:</div>
                  <input
                    type="text"
                    name={category.name}
                    value={updatedName}
                    onChange={(e) => setupdatedName(e.target.value)}
                    className={styles.inputModal}
                  ></input>
                </div>
                <div className="row">
                  <div className={styles.label}>Position:</div>
                  <input
                    name={category.name}
                    value={updatedPosition}
                    onChange={(e) => setUpdatedPosition(e.target.value)}
                    className={styles.inputModal}
                  ></input>
                </div>
              </div>
              <div className="row">
                <button
                  className={`button ${styles.label}`}
                  onClick={() => setModal(false)}
                >
                  abbrechen
                </button>
                <button className="button" onClick={handleChangeCategory}>
                  Änderung speichern
                </button>
              </div>
            </div> */}
          </div>
          <button className={styles.uploadButton} onClick={handleClick}>
            {value ? <div>{value}</div> : <div>Bild auswählen ...</div>}
          </button>
          <input
            ref={hiddenFileInput}
            className={styles.filesInput}
            type="file"
            accept=".png ,.jpg ,.jpeg"
            onChange={handleFileInput}
            style={{ display: "none" }}
          ></input>
          <button className="button" onClick={saveData}>
            Oberkategorie speichern
          </button>
          {/*      Oberkategorie - Unterkategorien */}
          <h2 className={styles.margin}>Unterkategorien</h2>
          {subCategories.map((category) => (
            <div key={category.id} className={styles.row}>
              <div className={styles.list}>
                <div className="row">
                  {category.position}.
                  <div className={styles.list}>{category.name}</div>
                </div>
              </div>
              <div className={styles.end}>
                <div
                  className={styles.delete}
                  onClick={() => {
                    handleEdit(category);
                  }}
                >
                  <MdEdit className={styles.icons} />
                </div>
                <div
                  className={styles.delete}
                  onClick={() => {
                    if (window.confirm("Diese Kategorie wirklich löschen?")) {
                      handleDelete(category.id);
                    }
                  }}
                >
                  <MdDelete className={styles.icons} />
                </div>
              </div>
            </div>
          ))}
          <Select
            className={styles.select}
            /* theme={customTheme} */
            onChange={handleChange}
            options={renderList()}
            type="search"
            placeholder="wähle die Oberkategorie ..."
            isClearable
            classNamePrefix="Select"
            /* menuIsOpen={true} */
          />
          <input
            type="text"
            value={subCategory}
            onChange={handleInputSub}
            className={styles.input}
            placeholder="neue Unterkategorie hinzufügen..."
          />
          <button className={styles.uploadButton} onClick={handleClickSub}>
            {valueSub ? <div>{valueSub}</div> : <div>Bild auswählen ...</div>}
          </button>
          <input
            ref={hiddenFileInputSub}
            className={styles.filesInput}
            type="file"
            accept=".png ,.jpg ,.jpeg"
            onChange={handleFileInputSub}
            style={{ display: "none" }}
          ></input>
          <button className="button" onClick={saveSubData}>
            Unterkategorie speichern
          </button>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loading: state.categories.loading,
    allCategories: state.categories.categories,
    roleId: state.user.role_id,
    /*  subCategories: state.subCategories.allSubCategories, */
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHelp: (text, type) => dispatch(getHelp(text, type)),
    getCategories: () => dispatch(getCategories()),
    getAllSubCategories: () => dispatch(),
    postCategory: (category, imageUrl) =>
      dispatch(postCategory(category, null, imageUrl)),
    postSubCategory: (subCategory, parentId, imageUrl) =>
      dispatch(postCategory(subCategory, parentId, imageUrl)),
    deleteCategory: (id) => dispatch(deleteCategory(id)),
    updateCategory: (id, data) => dispatch(updateCategory(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCategories);
