import React, { useEffect, useState } from "react";
import TinyMCE34 from "../../components/TinyMCE/TinyMCE34";
import TinyMCE12 from "../../components/TinyMCE/TinyMCE12";

import { connect } from "react-redux";
import {
  updatePost,
  postHistory,
  toggleHelpRedux,
  getHelp,
  aquireEditLock,
  releaseEditLock,
} from "../../actions";
import styles from "../NewPost/NewPost.module.css";
import happy from "../../pictures/Kidistatus/happy.svg";
const ReworkPost = ({
  updatePost,
  id,
  titleRedux,
  textRedux,
  postHistory,
  postId,
  role,
  class_id,
  post,
  getHelp,
  toggleHelpRedux,
  aquireEditLock,
  releaseEditLock,
}) => {
  const [preparing, setPreparing] = useState(true);
  const [title, setTitle] = useState(titleRedux);
  const [text, setText] = useState(textRedux);
  const [click, setClick] = useState(false);
  // TODO:
  const [originalPost, setOriginalPost] = useState(post);

  const titleInput = React.useRef(null);

  // Attempt to claim edit lock on page load
  useEffect(() => {
    aquireEditLock(postId)
      .then(() => {
        setPreparing(false);
      })
      .catch(() => {
        getHelp("Dieser Beitrag kann nicht bearbeitet werden", "error");
      });
  }, [postId, getHelp, aquireEditLock, id]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleTextChange = (text) => {
    setText(text);
  };

  const handleDataRedux = () => {
    if (title === "") {
      getHelp("Wo ist denn die Überschrift hin?", "error");
      titleInput.current.focus();
      window.scrollTo(0, 0);
    } else if (text === "") {
      getHelp("Wo ist denn der Text hin?", "error");
    } else {
      updatePost(postId, title, text, id, role, class_id, handleLastAuthor())
        .then((res) => {
          // TODO: think about:
          // It seems like it may be nicer to allow the user to save a post multiple times
          // without loosing the lock, no?
          // The lock should only be released when the post is closed again, which does not match
          // the current UI flow.
          releaseEditLock(postId)
            .then(() => {
              getHelp(
                `Super, du hast den Beitrag ${res[0].title} überarbeitet`,
                "success"
              );
              setClick(true);
            })
            .catch(() => {
              // TODO: @Alisa, what should happen in this case?
              getHelp(
                `Das speichern hat nicht funktioniert. Versuche es erneut.`,
                "error"
              );
            });
        })
        .catch((err) => {
          getHelp("Da ging was schief", "error");
        });
      postHistory(originalPost);
    }
  };

  const handleLastAuthor = () => {
    if (post.author_id_update === null) {
      return post.author_id;
    } else {
      return post.author_id_update;
    }
  };
  const handleEditor = () => {
    if (role === 1 || role === 2) {
      return true;
    } else {
      return false;
    }
  };
  if (preparing) {
    return (
      <div className="body-container column">
        <h2>Dieser Beitrag wird gerade bearbeitet</h2>
      </div>
    );
  }
  return (
    <div className="body-container column">
      {click ? (
        <>
          <img className={styles.picture} src={happy} alt="gut gemacht" />
        </>
      ) : (
        <>
          <div className={styles.block}>
            {handleEditor() ? (
              <TinyMCE12
                setTitle={handleTitleChange}
                title={title}
                setText={handleTextChange}
                text={text}
                titleInput={titleInput}
              />
            ) : (
              <TinyMCE34
                setTitle={handleTitleChange}
                title={title}
                setText={handleTextChange}
                text={text}
                titleInput={titleInput}
              />
            )}
            <button className="button" onClick={handleDataRedux}>
              Beitrag speichern
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    id: state.user.id,
    postId: state.post.post.id,
    titleRedux: state.post.post.title,
    textRedux: state.post.post.text,
    post: state.post.post,
    role: state.user.role_id,
    class_id: state.user.class_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updatePost: (postId, title, text, authorId, role, class_id, lastAuthor) =>
      dispatch(
        updatePost(
          postId,
          title,
          text,
          authorId,
          role,
          class_id,
          "updated",
          lastAuthor
        )
      ),
    postHistory: (obj) => dispatch(postHistory(obj)),
    getHelp: (res, type) => dispatch(getHelp(res, type)),
    toggleHelpRedux: (boolean) => dispatch(toggleHelpRedux(boolean)),
    aquireEditLock: (postId) => dispatch(aquireEditLock(postId)),
    releaseEditLock: (postId) => dispatch(releaseEditLock(postId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReworkPost);
