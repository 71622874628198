import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getUsers,
  deleteUser,
  getSchools,
  getClasses,
  getRoles,
  postUser,
  toggleHelpRedux,
  updateUser,
  getHelp,
  getAllUsers,
  getClassDetails,
  getMyStudents,
} from "../../../actions";
import styles from "./Students.module.css";
import { MdDelete, MdEdit } from "react-icons/md";
import { TiArrowUnsorted } from "react-icons/ti";
import Select from "react-select";
import LoginGrid from "../../../components/LoginGrid/LoginGrid";
import UpdateModal from "../../../components/UpdateModal/UpdateModal";
import { MdCheck } from "react-icons/md";
import PasswordGuidelines from "../../../components/PasswordGuidelines/PasswordGuidelines";

const Users = ({
  getUsers,
  deleteUser,
  getSchools,
  getClasses,
  getRoles,
  postUser,
  updateUser,
  users,
  schools,
  classes,
  roles,
  loading,
  getHelp,
  toggleHelpRedux,
  getClassDetails,
  user,
  getMyStudents,
  everyUser,
  getAllUsers,
  allUserNames,
}) => {
  const [allUsers, setAllUsers] = useState([]);
  const [myClasses, setMyClasses] = useState([]);
  const [activeFilter, setActiveFilter] = useState([]);
  const [schoolClass, setSchoolClass] = useState([]);
  const [userName, setuserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [hidden, setHidden] = useState(true);
  const [role, setRole] = useState("");
  const [passwordGrid, setPasswordGrid] = useState(false);
  const [validateName, setValidateName] = useState(false);
  const [buttonSucces, setButtonSuccess] = useState(false);

  /////userModal
  const [modalUpdateUser, setModalUpdateUser] = useState(false);
  const [editId, setEditId] = React.useState();
  const [updatedFirstNameUser, setupdatedFirstNameUser] = React.useState("");
  const [updatedLastNameUser, setupdatedLastNameUser] = React.useState("");
  const [updatedRoleUser, setupdatedRoleUser] = useState("");
  const [updatedMailUser, setupdatedMailUser] = useState("");

  useEffect(() => {
    async function fetchUsers() {
      await getUsers();
      await getMyStudents(user.class_id);
      await getRoles();
      await getAllUsers();
      await getClassDetails(user.class_id);
    }
    fetchUsers();
  }, []);

  const myUsers = users
    .filter((u) => u.school_id === user.school_id)
    .filter((u) => u.role_id < 7);

  useEffect(() => {
    setAllUsers(myUsers);
  }, [users]);

  useEffect(() => {
    setMyClasses(classes);
  }, [classes]);

  const sorting = (property) => {
    const copy = [...allUsers];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] !== null && b[property] !== null) {
        if (isNaN(a[property]) || isNaN(b[property])) {
          const nameA = a[property].toLowerCase();
          const nameB = b[property].toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        } else {
          const nameA = a[property];
          const nameB = b[property];
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }
      } else {
        return 0;
      }
    });
    setAllUsers(copy);

    if (copy[0].id === allUsers[0].id) {
      copy.reverse();
    }
  };
  /*   const filterRoles = (role) => {
    var filter = activeFilter.includes(role);
    console.log("activeFilter", activeFilter);
    /////wenn es drin is
    if (filter) {
      const newArr = activeFilter.filter((item) => item !== role);
      setActiveFilter([...newArr]);
      const res = users.filter((user) => newArr.includes(user.role_id));
      console.log("RES", res);
      setAllUsers([...res]);
      if (newArr.length === 0) {
        setAllUsers([...users]);
      }
    } else {
      //////wenn es nicht drin is
      const filteredUsers = users.filter((user) => {
        return user.role_id === role;
      });
      if (activeFilter.length === 0) {
        setAllUsers([...filteredUsers]);
      } else {
        const newArr = allUsers.concat(filteredUsers);
        setAllUsers([...newArr]);
      }

      activeFilter.push(role);
    }
  }; */

  const filterClasses = (classId) => {
    var filter = activeFilter.includes(classId[0]);
    /////wenn es drin is
    if (filter) {
      setActiveFilter([]);
      setAllUsers([...users]);
    } else {
      //////wenn es nicht drin is
      setActiveFilter(classId);
      const filteredUsers = users.filter(
        (u) => !!u.class_id.find((n) => classId.includes(n))
      );
      if (activeFilter.length === 0) {
        setAllUsers([...filteredUsers]);
      } else {
        const newArray = allUsers.concat(filteredUsers);
        setAllUsers([...filteredUsers]);
      }
    }
  };

  const renderList = (data, name) => {
    if (name === "role_name") {
      const filteredData = data.filter((d) => d.id < 7);
      return filteredData.map((d) => ({
        label: d[name],
        value: d.id,
      }));
    } else {
      return data.map((d) => ({
        label: d[name],
        value: d.id,
      }));
    }
  };

  const handleChangeClass = (selectedOption) => {
    if (selectedOption !== null) {
      setSchoolClass(selectedOption.value);
    } else {
      setSchoolClass("");
    }
  };

  const handleChangeRole = (selectedOption) => {
    console.log("Alisa");
    if (selectedOption !== null) {
      setRole(selectedOption.value);
    } else {
      setRole("");
    }
    if (
      selectedOption.label === "erste Klasse" ||
      selectedOption.label === "zweite Klasse"
    ) {
      setPasswordGrid(true);
    } else {
      setPasswordGrid(false);
    }
  };

  const handleBackgroundColor = (role) => {
    if (activeFilter.includes(role)) {
      return false;
    } else {
      return true;
    }
  };
  const handleGridPassword = (passwordArray) => {
    if (passwordArray.length === 3) {
      const copy = [...passwordArray];
      const passwordString = copy.sort().join("");
      setPassword(passwordString);
    } else if (passwordArray.length === 2) {
      setPassword("");
    }
  };
  const saveUser = () => {
    const userNameValidation = () => {
      const split = userName.split("");
      const space = [" "];
      if (userName.length > 2 && !split.some((e) => space.includes(e))) {
        return true;
      } else {
        getHelp("Der kidi-Name ist nicht lange genug");
        toggleHelpRedux(true);
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
        return false;
      }
    };
    const passwordValidation = () => {
      const split = password.split("");

      const upper = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      const lower = [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      const number = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
      const space = [" "];
      if (
        password.length > 4 &&
        split.some((e) => upper.includes(e)) &&
        split.some((e) => lower.includes(e)) &&
        split.some((e) => number.includes(e)) &&
        !split.some((e) => space.includes(e))
      ) {
        return true;
      } else {
        getHelp("Das Passwort entspricht nicht den Richtlinien", "error");
        toggleHelpRedux(true);
        setTimeout(function () {
          toggleHelpRedux(false);
        }, 6000);
        return false;
      }
    };
    const userNameTrim = userName.trim();

    if (passwordValidation() && userNameValidation()) {
      postUser(
        userNameTrim,
        firstName,
        lastName,
        password,
        user.school_id,
        schoolClass,
        role
      )
        .then(setuserName(""))
        .then(setFirstName(""))
        .then(setLastName(""))
        .then(setPassword(""))
        .then(getHelp("Es wurde ein neuer Benutzer angelegt", "success"))
        .then(
          setTimeout(function () {
            toggleHelpRedux(false);
          }, 6000)
        )
        .then(setButtonSuccess(true))
        .then(
          setTimeout(function () {
            setButtonSuccess(false);
          }, 6000)
        );
    } else {
      getHelp(
        "Passwort oder kidi-Name entsprechen nicht den Richtlinien",
        "error"
      );
      setTimeout(function () {
        toggleHelpRedux(false);
      }, 6000);
    }
  };

  const handleKidiName = (e) => {
    setuserName(e.target.value);
    const findUserName = allUserNames.filter(
      (u) => u.user_name === e.target.value
    );
    if (findUserName.length === 0 && e.target.value.length > 2) {
      setValidateName(true);
    } else {
      setValidateName(false);
    }
  };

  const handleEditUser = (u) => {
    setModalUpdateUser(true);
    setupdatedFirstNameUser(u.first_name);
    setupdatedLastNameUser(u.last_name);
    setupdatedRoleUser(u.role_id);
    setupdatedMailUser(u.mail);
    setEditId(u.id);
  };
  const handleUpdateUser = () => {
    updateUser(editId, {
      first_name: updatedFirstNameUser,
      last_name: updatedLastNameUser,
      role_id: updatedRoleUser,
      mail: updatedMailUser,
    }).then(setModalUpdateUser(false));
  };
  const classValidation = (user) => {
    if (user.role_id === 8) {
      return "Schuladmin";
    } else if (user.role_id === 7) {
      return "Lehrer";
    } else {
      return user.class_name;
    }
  };
  return (
    <div className="body-container">
      <div className={styles.columnOut}>
        <div className={styles.columnUser}>
          <h2>Neuen User anlegen</h2>
          <div className={styles.center}>
            <div className={styles.labelRow}>
              <div className={styles.label}>kidi-Name *</div>
              <input
                className={validateName ? styles.inputGreen : styles.inputRed}
                type="text"
                value={userName}
                onChange={handleKidiName}
              ></input>
            </div>
            <div className={styles.labelRow}>
              <div className={styles.label}>Vorname</div>
              <input
                className={styles.input}
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></input>
            </div>
            <div className={styles.labelRow}>
              <div className={styles.label}>Nachname</div>
              <input
                className={styles.input}
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></input>
            </div>
            <div className={styles.labelRow}>
              <div className={styles.label}>Klasse *</div>
              <Select
                placeholder="wähle eine Klasse..."
                className={styles.select}
                options={renderList(myClasses, "class_name")}
                onChange={handleChangeClass}
                isSearchable={false}
                noOptionsMessage={() => "Sie haben keine Klasse"}
              />
            </div>

            <div className={styles.labelRow}>
              <div className={styles.label}>Klassenstufe *</div>
              <Select
                placeholder="wähle eine Klassenstufe..."
                className={styles.select}
                options={renderList(roles, "role_name")}
                onChange={handleChangeRole}
                isSearchable={false}
              />
            </div>
            {passwordGrid ? (
              <div className={styles.labelRow}>
                <div className={styles.label}>Bilderpasswort</div>
                <LoginGrid getGridArray={handleGridPassword} />
              </div>
            ) : (
              <div className="column">
                <div className={styles.labelRow}>
                  <div className={styles.label}>Passwort *</div>
                  <input
                    className={styles.input}
                    type={hidden ? "password" : "text"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></input>
                </div>
                <button
                  className="buttonOutline"
                  onClick={() => {
                    setHidden(!hidden);
                  }}
                >
                  {hidden ? <>Passwort anzeigen</> : <>Passwort verbergen</>}
                </button>
              </div>
            )}

            {buttonSucces ? (
              <button
                className={styles.buttonSuccess}
                onClick={() => {
                  saveUser();
                }}
              >
                <MdCheck className="icons"></MdCheck>
                kidi-User wurde gespeichert
              </button>
            ) : (
              <button
                className="button"
                onClick={() => {
                  saveUser();
                }}
              >
                kidi-User speichern
              </button>
            )}
          </div>
          <h4>Richtlinien für das Passwort:</h4>
          <PasswordGuidelines passwordGrid={passwordGrid} />
        </div>
        {loading ? (
          <h1>loading</h1>
        ) : (
          <div className={styles.column}>
            <div className={modalUpdateUser ? styles.modal : styles.noModal}>
              <UpdateModal
                inputFields={[
                  {
                    input: updatedFirstNameUser,
                    state: setupdatedFirstNameUser,
                    placeholder: "Vorname",
                  },
                  {
                    input: updatedLastNameUser,
                    state: setupdatedLastNameUser,
                    placeholder: "Nachname",
                  },
                  {
                    input: updatedRoleUser,
                    state: setupdatedRoleUser,
                    placeholder: "Klassenstufe",
                  },
                  {
                    input: updatedMailUser,
                    state: setupdatedMailUser,
                    placeholder: "Mail",
                  },
                ]}
                setModal={setModalUpdateUser}
                handleChangeCategory={handleUpdateUser}
              />
            </div>
            <h2>Das sind Ihre Schülerinnen und Schüler</h2>
            <div className={styles.rowmenu}>
              {classes.map((c) => (
                <div key={c.id}>
                  <button
                    className={`${
                      handleBackgroundColor(c.id)
                        ? styles.button
                        : styles.buttonActive
                    }`}
                    onClick={() => {
                      filterClasses([c.id]);
                    }}
                  >
                    {c.class_name}
                  </button>
                </div>
              ))}
            </div>

            <div className={styles.margin}>
              <div className={styles.rowmenusort}>
                <p onClick={() => sorting("user_name")} className={styles.list}>
                  kidi-Name <TiArrowUnsorted />
                </p>
                <p
                  onClick={() => sorting("first_name")}
                  className={styles.list}
                >
                  Vorname <TiArrowUnsorted />
                </p>
                <p onClick={() => sorting("last_name")} className={styles.list}>
                  Nachname <TiArrowUnsorted />
                </p>
                <p onClick={() => sorting("role_id")} className={styles.list}>
                  Klassenstufe <TiArrowUnsorted />
                </p>
                <p
                  onClick={() => sorting("class_name")}
                  className={styles.list}
                >
                  Klasse <TiArrowUnsorted />
                </p>

                <div className={styles.icons}></div>
                <div className={styles.icons}></div>
              </div>
              <div className={styles.height}>
                {allUsers.length === 0 ? (
                  <h2>Diese Klasse hat keine Schüler</h2>
                ) : (
                  <>
                    {" "}
                    {allUsers.map((user) => (
                      <div className={styles.row} key={user.id}>
                        <div className={styles.list}> {user.user_name}</div>
                        <div className={styles.list}> {user.first_name}</div>
                        <div className={styles.list}> {user.last_name}</div>
                        <div className={styles.list}> {user.role_id}</div>
                        <div className={styles.list}>
                          {" "}
                          {classValidation(user)}
                        </div>
                        {user.role_id < 6 ? (
                          <div className={styles.end}>
                            <div
                              className={styles.delete}
                              onClick={() => {
                                handleEditUser(user);
                              }}
                            >
                              <MdEdit className={styles.icons} />
                            </div>
                            <div
                              key={user.id}
                              className={styles.delete}
                              onClick={() => {
                                if (
                                  window.confirm(
                                    `Den Benutzer "${user.user_name}" wirklich löschen?`
                                  )
                                ) {
                                  deleteUser(user.id);
                                }
                              }}
                            >
                              <MdDelete className={styles.icons} />
                            </div>
                          </div>
                        ) : (
                          <div className={styles.space}></div>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.count}>Anzahl: {allUsers.length}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
    allUserNames: state.users.allUserNames,
    users: state.users.myStudents,
    everyUser: state.users.users,
    schools: state.schools.schools,
    classes: state.classes.classDetails,
    roles: state.roles.roles,
    loading: state.users.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsers()),
    getAllUsers: () => dispatch(getAllUsers()),
    deleteUser: (id) => dispatch(deleteUser(id)),
    getSchools: () => dispatch(getSchools()),
    getClasses: (id) => dispatch(getClasses(id)),
    getRoles: () => dispatch(getRoles()),
    getHelp: (res, type) => dispatch(getHelp(res, type)),
    toggleHelpRedux: (boolean) => dispatch(toggleHelpRedux(boolean)),
    getClassDetails: (classId) => dispatch(getClassDetails(classId)),
    getMyStudents: (classId) => dispatch(getMyStudents(classId)),
    updateUser: (id, data) => dispatch(updateUser(id, data)),

    postUser: (
      userName,
      firstName,
      lastName,
      password,
      schoolId,
      classId,
      roleId
    ) =>
      dispatch(
        postUser(
          userName,
          firstName,
          lastName,
          password,
          schoolId,
          classId,
          roleId
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
