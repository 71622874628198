import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getMyPosts, getHelp, getPostsIWorkedOn } from "../../actions";
import PostList from "../../components/PostList/PostList";
import Loading from "../../components/Loading/Loading";
import { TiArrowUnsorted } from "react-icons/ti";

const MeineBeiträge = ({
  getMyPosts,
  userName,
  myPosts,
  loading,
  role,
  getHelp,
  user,
  getPostsIWorkedOn,
  postsIWorkedOn,
}) => {
  // TODO
  const [myPostsList, setMyPostsList] = useState([]);
  const [publishedPosts, setpublishedPosts] = useState([]);
  const [notPublishedPosts, setNotPublishedPosts] = useState([]);
  const [iWorkedOn, setIWorkedOn] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      const posts = await getMyPosts(userName);
      const published = await posts.filter((post) => post.published === true);
      const notPublished = await posts.filter(
        (post) => post.published === false
      );
      const workedOn = await getPostsIWorkedOn(user.id);
      const workedOnNotInPub = workedOn.filter(function (objFromA) {
        return !published.find(function (objFromB) {
          return objFromA.post_id === objFromB.id;
        });
      });
      const workedOnNotInPubAndNonPub = workedOnNotInPub.filter(function (
        objFromA
      ) {
        return !notPublished.find(function (objFromB) {
          return objFromA.post_id === objFromB.id;
        });
      });

      setpublishedPosts(published);
      setNotPublishedPosts(notPublished);
      setIWorkedOn(workedOnNotInPubAndNonPub);
    }
    getHelp();
    fetchPosts();
  }, [getHelp, getMyPosts, getPostsIWorkedOn, user.id, userName]);

  useEffect(() => {
    setMyPostsList(myPosts);
  }, [myPosts]);

  useEffect(() => {
    setIWorkedOn(postsIWorkedOn);
  }, [postsIWorkedOn]);

  const sortingPublished = (property) => {
    const copy = [...publishedPosts];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] && b[property]) {
        const nameA = a[property].toLowerCase();
        const nameB = b[property].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        return 0;
      }
    });
    setpublishedPosts(copy);

    if (copy[0].id === publishedPosts[0].id) {
      copy.reverse();
    }
  };

  const sortingNotPublished = (property) => {
    const copy = [...notPublishedPosts];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] !== null && b[property] !== null) {
        const nameA = a[property].toLowerCase();
        const nameB = b[property].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        return 0;
      }
    });
    setNotPublishedPosts(copy);

    if (copy[0].id === notPublishedPosts[0].id) {
      copy.reverse();
    }
  };

  return (
    <div className="body-container">
      <div className="column">
        {loading ? (
          <Loading />
        ) : (
          <>
            <h3>Das sind deine veröffentlichten Beiträge</h3>
            <div className="list-header-row">
              <div onClick={() => sortingPublished("title")}>
                Titel <TiArrowUnsorted />
              </div>
              <div onClick={() => sortingPublished("user_name")}>
                verfasst von <TiArrowUnsorted />
              </div>
              <div onClick={() => sortingPublished("creation_date")}>
                erschienen am <TiArrowUnsorted />
              </div>
              <div onClick={() => sortingPublished("update_author_name")}>
                bearbeitet von <TiArrowUnsorted />
              </div>
              <div onClick={() => sortingPublished("last_update_date")}>
                bearbeitet am <TiArrowUnsorted />
              </div>
              <div onClick={() => sortingPublished("edit_lock_user_id")}>
                in Bearbeitung von <TiArrowUnsorted />
              </div>
            </div>
            {publishedPosts.map((post) => (
              <Link
                to={`/stöbern/kategorien/beitrag/${post.id}`}
                className="Link"
                key={post.id}
              >
                <div>
                  <PostList
                    id={post.id}
                    role={post.class_role}
                    title={post.title}
                    date={post.creation_date}
                    userName={post.user_name}
                    updateDate={post.last_update_date}
                    updateUserName={post.update_author_name}
                    editLockUserName={post.edit_lock_user_name}
                    aquiredEditLockAt={post.aquired_edit_lock_at}
                  />
                </div>
              </Link>
            ))}
            {user.role_id <= 6 ? (
              <>
                {" "}
                <h3>Das sind deine unveröffentlichten Beiträge</h3>
                <div className="list-header-row">
                  <div onClick={() => sortingNotPublished("title")}>
                    Titel <TiArrowUnsorted />
                  </div>
                  <div onClick={() => sortingNotPublished("user_name")}>
                    verfasst von <TiArrowUnsorted />
                  </div>
                  <div onClick={() => sortingNotPublished("creation_date")}>
                    erschienen am <TiArrowUnsorted />
                  </div>
                  <div
                    onClick={() => sortingNotPublished("update_author_name")}
                  >
                    bearbeitet von <TiArrowUnsorted />
                  </div>
                  <div onClick={() => sortingNotPublished("last_update_date")}>
                    bearbeitet am <TiArrowUnsorted />
                  </div>
                  <div onClick={() => sortingNotPublished("edit_lock_user_id")}>
                    in Bearbeitung von <TiArrowUnsorted />
                  </div>
                </div>
                {notPublishedPosts.map((post) => (
                  <Link
                    to={`/stöbern/kategorien/beitrag/${post.id}`}
                    className="Link"
                    key={post.id}
                  >
                    <div>
                      <PostList
                        id={post.id}
                        role={post.class_role}
                        title={post.title}
                        date={post.creation_date}
                        userName={post.user_name}
                        updateDate={post.last_update_date}
                        updateUserName={post.update_author_name}
                        editLockUserName={post.edit_lock_user_name}
                        aquiredEditLockAt={post.aquired_edit_lock_at}
                      />
                    </div>
                  </Link>
                ))}
              </>
            ) : null}

            <h3>An diesen Beiträgen hast du mitgearbeitet</h3>

            {iWorkedOn.map((post) => (
              <Link
                to={`/stöbern/kategorien/beitrag/${post.post_id}`}
                className="Link"
                key={post.id}
              >
                <PostList
                  id={post.post_id}
                  role={post.class_role}
                  title={post.title}
                  date={post.creation_date}
                  userName={post.user_name}
                  updateDate={post.last_update_date}
                  updateUserName={post.update_author_name}
                  editLockUserName={post.edit_lock_user_name}
                  aquiredEditLockAt={post.aquired_edit_lock_at}
                />
              </Link>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.posts.loading,
    myPosts: state.posts.myPosts,
    userName: state.user.user_name,
    role: state.user.role,
    user: state.user,
    postsIWorkedOn: state.posts.postsIWorkedOn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMyPosts: (userName) => dispatch(getMyPosts(userName)),
    getHelp: () => dispatch(getHelp(6)),
    getPostsIWorkedOn: (authorId) => dispatch(getPostsIWorkedOn(authorId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeineBeiträge);
