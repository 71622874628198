import * as actions from "../actions/types";

const initialState = {
  loading: false,
  loggedIn: null,
  error: null,
  user_name: "",
  first_name: "",
  last_name: "",
  school_id: "",
  school: "",
  teachers: [],
  class_id: "",
  accepted_terms_of_use: null,
  class: "",
  role_id: "",
  role: "",
  id: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        user_name: action.payload.user_name,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        school_id: action.payload.school_id,
        role_id: action.payload.role_id,
        class_id: action.payload.class_id,
        accepted_terms_of_use: action.payload.accepted_terms_of_use,
        loggedIn: true,
        loading: false,
      };
    case actions.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: action.payload.error,
      };
    case actions.LOGOUT:
      return {
        ...initialState,
        loggedIn: false,
      };
    case actions.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_USER_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        user_name: action.payload.user_name,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        school_id: action.payload.school_id,
        school: action.payload.school_name,
        class: action.payload.class_name,
        role_id: action.payload.role_id,
        class_id: action.payload.class_id,
        accepted_terms_of_use: action.payload.accepted_terms_of_use,
        loggedIn: true,
        loading: false,
      };
    case actions.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.payload.role,
      };
    case actions.GET_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_TEACHER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        teachers: action.payload.teacher,
      };
    case actions.GET_TEACHER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.ADD_CLASS_SUCCESS:
      return {
        ...state,
        class_id: action.payload,
      };
    default:
      return state;
  }
};

export default user;
