import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCategories, getHelp } from "../../actions";
import notLoggedIn from "../../pictures/Szene/Szene_rot.svg";
import styles from "./Stöbern.module.css";

const Stöbern = ({ categories, getCategories, getHelp, pageContent }) => {
  const [categoryNames, setCategoryNames] = useState(null);
  const names = categories.categories;

  const filteredContent = pageContent.pageContent.filter((c) => {
    return c.type === "Stobern";
  });
  useEffect(() => {
    async function fetchCategories() {
      const cate = await getCategories();
      setCategoryNames(cate);
    }
    getHelp();
    fetchCategories();
  }, [getCategories, getHelp]);

  useEffect(() => {
    setCategoryNames(names);
  }, [categories, names]);

  const count = (category) => {
    const parentId = category.id;
    const children = categories.categories.filter((c) => c.parent === parentId);
    const childrenCount = children.map((c) => c.count);
    const sum = childrenCount.reduce(
      (a, b) => parseInt(a, 0) + parseInt(b, 0),
      0
    );
    const result = sum + parseInt(category.count, 0);
    return result;
  };

  return (
    <>
      {localStorage.getItem("token") ? (
        <div className="body-container">
          {categoryNames ? (
            <div className={styles.gridFlex}>
              {categoryNames
                .filter((name) => name.parent === null)
                .map((name) => (
                  <div key={name.id}>
                    <Link
                      to={{
                        pathname: `/stöbern/${name.name}`,
                        /*    state: { id: name.id }, */
                      }}
                      className={styles.textDecoration}
                      key={name.id}
                    >
                      <div className={styles.column}>
                        {name.imageurl ? (
                          <img
                            /*                           src={require(`../../pictures/Kategoriebilder_aktuell/${name.name}.svg`)}
                             */ alt={`${name.name}`}
                            src={name.imageurl}
                            className={styles.picture}
                          />
                        ) : null}

                        <div className={styles.textDecoration}>
                          {name.name} ({count(name)})
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="row">
          {" "}
          <img
            src={notLoggedIn}
            alt="du bist leider nicht angemeldet"
            className="pictures"
          />
          <div className="column welcometexts">
            {filteredContent[0] ? (
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: filteredContent[0].text }}
              ></div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categories: state.categories,
    loggedIn: state.user.loggedIn,
    pageContent: state.pageContent,
    roleId: state.user.role_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: () => dispatch(getCategories()),
    getHelp: () => dispatch(getHelp(8)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stöbern);
