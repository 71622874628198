import React from "react";
import styles from "./Loading.module.css";

const Loading = () => {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loading}>
        <div
          className={`${`${styles.loadingLetter} ${styles.blue}`} ${
            styles.blue
          }`}
        >
          e
        </div>
        <div className={`${styles.loadingLetter} ${styles.blue}`}>i</div>
        <div className={`${styles.loadingLetter} ${styles.red}`}>n</div>
        <div className={`${styles.loadingLetter} ${styles.red}`}>e</div>
        <div className={`${styles.loadingLetter} ${styles.red}`}>n</div>
        <div className={styles.loadingLetterNone}></div>
        <div className={`${styles.loadingLetter} ${styles.yellow}`}>M</div>
        <div className={`${styles.loadingLetter} ${styles.yellow}`}>o</div>
        <div className={`${styles.loadingLetter} ${styles.green}`}>m</div>
        <div className={`${styles.loadingLetter} ${styles.green}`}>e</div>
        <div className={`${styles.loadingLetter} ${styles.green}`}>n</div>
        <div className={`${styles.loadingLetter} ${styles.green}`}>t</div>
        <div className={`${styles.loadingLetter} ${styles.blue}`}>.</div>
        <div className={`${styles.loadingLetter} ${styles.blue}`}>.</div>
        <div className={`${styles.loadingLetter} ${styles.blue}`}>.</div>
      </div>
    </div>
  );
};

export default Loading;
