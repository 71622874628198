import { combineReducers } from "redux";
import post from "./post";
import user from "./user";
import users from "./users";
import posts from "./posts";
import categories from "./categories";
import subCategories from "./subCategories";
import helpTexts from "./helpTexts";
import schools from "./schools";
import classes from "./classes";
import roles from "./roles";
import postsHistory from "./postsHistory";
import postsNotes from "./postsNotes";
import starRatings from "./starRatings";
import notifications from "./notifications";
import pageContent from "./pageContent";

const allReducers = combineReducers({
  post,
  posts,
  postsHistory,
  postsNotes,
  starRatings,
  user,
  users,
  categories,
  subCategories,
  helpTexts,
  schools,
  classes,
  roles,
  notifications,
  pageContent,
});
export default allReducers;
