export const pinselGelb = `<svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<style type="text/css">
	.st0y{fill:#F4AE16;stroke:#1D1D1B;stroke-width:0.25;stroke-miterlimit:10;}
	.st1y{fill:none;}
	.st2y{fill:#D1AE0D;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st3y{fill:#F7CB0E;}
	.st4y{fill:#956037;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st5y{fill:#B37243;}
	.st6y{fill:#A98E0C;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st7y{fill:#D1AE0D;}
	.st8y{fill:#BD9E0D;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st9y{fill:#E4BC0C;}
	.st10y{fill:#CE9612;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st11y{fill:#F4AE16;}
</style>

	<g>
		<path class="st0y" d="M50.4,83.7l-3.2-8.4H19.9l-3.2,8.6c-1.3,3.4-2.3,5.7-3.2,6.8c-0.9,1.2-2.4,1.7-4.4,1.7
			c-1.7,0-3.2-0.6-4.6-1.9c-1.3-1.3-2-2.6-2-4.3c0-0.9,0.2-1.8,0.5-2.8s0.8-2.4,1.6-4.1l17.1-43.6c0.5-1.3,1.1-2.7,1.7-4.5
			c0.7-1.7,1.5-3.2,2.2-4.4c0.8-1.2,1.8-2.1,3.1-2.8c1.3-0.7,2.8-1.1,4.7-1.1s3.5,0.4,4.7,1.1s2.3,1.6,3.1,2.7s1.5,2.4,2,3.6
			c0.5,1.3,1.2,3,2,5.2l17.5,43.3c1.4,3.3,2.1,5.7,2.1,7.2c0,1.6-0.6,3-1.9,4.3s-2.8,1.9-4.7,1.9c-1.1,0-2-0.2-2.7-0.5
			c-0.7-0.4-1.4-0.9-1.9-1.6s-1.1-1.6-1.6-3C51.3,86,50.9,84.8,50.4,83.7z M23.5,65.1h20.1L33.4,37.4L23.5,65.1z"/>
	</g>


</svg>`;
