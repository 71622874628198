import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./AdministrationTeacher.module.css";

const AdministrationTeacher = ({ role }) => {
  return (
    <div className="body-container">
      <div className={styles.gridAdministration}>
        <Link
          to="/Administration_Lehrpersonen/SchulenUndKlassen"
          className={styles.box1}
        >
          <div>Klassen</div>
        </Link>
        {role === 8 ? (
          <Link
            to="/Administration_Lehrpersonen/Lehrerpersonen_und_Schüler"
            className={styles.box2}
          >
            <div>Lehrpersonen, Schülerinnen und Schüler</div>
          </Link>
        ) : (
          <Link
            to="/Administration_Lehrpersonen/Schüler"
            className={styles.box2}
          >
            <div>Schülerinnen und Schüler</div>
          </Link>
        )}
        <Link
          to="/Administration_Lehrpersonen/Beiträge"
          className={styles.box3}
        >
          <div>Beiträge</div>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.user.role_id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdministrationTeacher);
