import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  getMostStars,
  getPageContent,
  getUser,
  getPosts,
  getRole,
  getNotifications,
  getClassPosts,
  getKidiStatus,
} from "../../actions";
import {
  PrivateRoute,
  AdminRoute,
  TeacherRoute,
} from "../../components/privateRoute";
import NavBar from "../NavBar/Navbar";
import LandingPage from "../LandingPage/LandingPage";
import InfosFuerKids from "../InfosFuerKids/InfosFuerKids";
import Administration from "../Administration/Administration";
import AdministrationTeacher from "../AdministratonTeacher/AdministrationTeacher";
import AdministrationTeacherClasses from "../AdministratonTeacher/Classes/Classes";
import Login from "../Login/Login";
import Beiträge from "../Beiträge/Beiträge";
import meineBeiträge from "../MeineBeiträge/MeineBeiträge";
import Stöbern from "../Stöbern/Stöbern";
import MeinKidi from "../MeinKidi/MeinKidi";
import Notifications from "../Notifications/Notifications";
import MeinKidiPassword from "../ChangePassword/ChangePassword";
import Footer from "../Footer/Footer";
import Impressum from "../Impressum/Impressum";
import Materials from "../Materials/Materials";
import NeuerBeitrag from "../NewPost/NewPost";
import ReworkPost from "../ReworkPost/ReworkPost";
import Post from "../Post/Post";
import Categories from "../Categories/Categories";
import SubCategories from "../SubCategories/SubCategories";
import Users from "../Administration/Users/Users";
import CategoriesAdmin from "../Administration/Categories/Categories";
import SchoolsAndClasses from "../Administration/SchoolsAndClasses/SchoolsAndClasses";
import PostSearchResult from "../PostSearchResult/PostSearchResult";
import ClassPosts from "../ClassPosts/ClassPosts";
import ClassPost from "../ClassPost/ClassPost";
import PageAdministration from "../Administration/PageAdministration/PageAdministration";
import PostsAdmin from "../Administration/Posts/Posts";
import kidiABC from "../kidiABC/kidiABC";
import Students from "../AdministratonTeacher/Students/Students";
import StudentsTeacher from "../AdministratonTeacher/StudentsAndTeacher/StudentsAndTeacher";
import HelpTexts from "../Administration/HelpTexts/HelpTexts";
import Meins from "../Meins/Meins";

const Container = ({
  user,
  getMostStars,
  getPageContent,
  getUser,
  getPosts,
  getRole,
  getNotifications,
  getKidiStatus,
}) => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    async function fetchPosts() {
      await getMostStars();
      await getPageContent();
    }
    if (localStorage.getItem("token") && !user.loggedIn) {
      getUser().then((res) => {
        if (res) {
          getRole(res.user_name);
          getNotifications();
          getClassPosts(res.class_id);
          getPosts();
          getKidiStatus(res.id);
        } else {
          localStorage.removeItem("token");
          setRedirect(true);
        }
      });
    }
    fetchPosts();
  }, [getKidiStatus, getMostStars, getNotifications, getPageContent, getPosts, getRole, getUser, user.loggedIn]);
  const [post, setPost] = useState(0);

  return (
    <div className="App">
      {redirect ? <Redirect to={`/`}></Redirect> : null}
      <NavBar setPost={setPost} />
      <div className="placeholder"></div>
      <Switch>
        <Route
          exact
          path="/"
          component={(props) => <LandingPage post={post} setPost={setPost} />}
        />
        <Route path="/infosfuerkids" exact component={InfosFuerKids} />
        <Route path="/infosfuerlehrkraefte" exact component={InfosFuerKids} />
        <AdminRoute
          path="/administration"
          exact
          component={Administration}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <TeacherRoute
          path="/Administration_Lehrpersonen"
          exact
          component={AdministrationTeacher}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <TeacherRoute
          path="/Administration_Lehrpersonen/SchulenUndKlassen"
          exact
          component={AdministrationTeacherClasses}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <TeacherRoute
          path="/Administration_Lehrpersonen/Schüler"
          exact
          component={Students}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <TeacherRoute
          path="/Administration_Lehrpersonen/Lehrerpersonen_und_Schüler"
          exact
          component={StudentsTeacher}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />

        <AdminRoute
          path="/administration/Benutzer"
          exact
          component={Users}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <AdminRoute
          path="/administration/Kategorien"
          exact
          component={CategoriesAdmin}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <AdminRoute
          path="/administration/SchulenUndKlassen"
          exact
          component={SchoolsAndClasses}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <AdminRoute
          path="/administration/PageAdministration"
          exact
          component={PageAdministration}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <AdminRoute
          path="/administration/Beiträge"
          exact
          component={PostsAdmin}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <AdminRoute
          path="/administration/pageAdministration/helpTexts"
          exact
          component={HelpTexts}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />

        <Route path="/infosfuerkids" exact component={InfosFuerKids} />
        <Route path="/anmelden" exact component={Login} />
        <Route path="/beiträge" exact component={Beiträge} />
        <PrivateRoute
          path="/beiträge/neuerBeitrag"
          exact
          component={NeuerBeitrag}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/beiträge/überarbeiten"
          exact
          component={ReworkPost}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/beiträge/meineBeiträge"
          exact
          component={meineBeiträge}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/beiträge/Klassenbeiträge"
          exact
          component={ClassPosts}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/beiträge/Klassenbeiträge/:id"
          exact
          component={ClassPost}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/beiträge/suche/:title"
          exact
          component={PostSearchResult}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <Route path="/stöbern" exact component={Stöbern} />
        <PrivateRoute
          path="/stöbern/:name"
          exact
          component={Categories}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/stöbern/:parentName/:name"
          exact
          component={SubCategories}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/stöbern/kategorien"
          exact
          component={Categories}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/stöbern/kategorien/beitrag/:id"
          exact
          component={Post}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
          class_id={user.class_id}
        />
        <Route path="/meinkidi" exact component={MeinKidi} />
        <PrivateRoute
          path="/meinkidi/meins"
          exact
          component={Meins}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/meinkidi/passwort"
          exact
          component={MeinKidiPassword}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <PrivateRoute
          path="/meinkidi/Postfach"
          exact
          component={Notifications}
          role={user.role_id}
          loggedIn={user.loggedIn}
          acceptedTermsOfUse={user.accepted_terms_of_use}
        />
        <Route path="/kidi-ABC" exact component={kidiABC} />
        <Route path="/impressum" exact component={Impressum} />
        <Route path="/materialien" exact component={Materials} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMostStars: () => dispatch(getMostStars()),
    getPageContent: () => dispatch(getPageContent()),
    getPosts: () => dispatch(getPosts()),
    getUser: () => dispatch(getUser()),
    getRole: (userName) => dispatch(getRole(userName)),
    getNotifications: () => dispatch(getNotifications()),
    getClassPosts: (classId) => dispatch(getClassPosts(classId)),
    getKidiStatus: (id) => dispatch(getKidiStatus(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Container);
