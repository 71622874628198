import React, { useState, useEffect } from "react";
import styles from "./HelpTexts.module.css";
import { connect } from "react-redux";
import { getHelpTexts, updateHelpTexts } from "../../../actions";
import TinyMCE from "../../../components/TinyMCE/TinyMCE";

const Helptexts = ({ getHelpTexts, helpTexts, updateHelpTexts }) => {
  /////userModal
  const [modalUpdateText, setModalUpdateText] = useState(false);
  const [editId, setEditId] = React.useState();
  const [updatedContent, setUpdatedContent] = React.useState("");

  useEffect(() => {
    async function fetchUsers() {
      await getHelpTexts();
    }
    fetchUsers();
  }, [getHelpTexts]);
  const handleEditText = (t) => {
    setModalUpdateText(true);
    setUpdatedContent(t.content);
    setEditId(t.id);
  };
  const handleUpdateText = () => {
    updateHelpTexts(editId, {
      content: updatedContent,
    }).then(setModalUpdateText(false));
  };
  return (
    <div className="body-container">
      <div className={styles.container}>
        <div className={modalUpdateText ? styles.modal : styles.noModal}>
          {/*           <UpdateModal
            inputFields={[
              {
                input: updatedContent,
                state: setUpdatedContent,
                placeholder: "Text",
              },
            ]}
            setModal={setModalUpdateText}
            handleChangeCategory={handleUpdateText}
          /> */}
          <div className={styles.frame}>
            <TinyMCE setText={setUpdatedContent} text={updatedContent} />
            <div className="row">
              <button className="button" onClick={handleUpdateText}>
                Änderung speichern
              </button>
              <button
                className={`button ${styles.label}`}
                onClick={() => setModalUpdateText(false)}
              >
                abbrechen
              </button>
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <h2>Klicke auf einen Text, um ihn zu bearbeiten</h2>
          {helpTexts ? (
            <>
              {helpTexts.map((text) => {
                return (
                  <div
                    onClick={() => {
                      handleEditText(text);
                    }}
                    dangerouslySetInnerHTML={{ __html: text.content }}
                  ></div>
                );
              })}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    helpTexts: state.helpTexts.helpTexts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHelpTexts: () => dispatch(getHelpTexts()),
    updateHelpTexts: (id, data) => dispatch(updateHelpTexts(id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Helptexts);
