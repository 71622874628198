import React, { useState, useEffect } from "react";
import styles from "./PageAdministration.module.css";
import TinyMCE from "../../../components/TinyMCE/TinyMCE";
import { FiArrowLeft } from "react-icons/fi";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getHelp,
  postPageContent,
  updatePageContent,
  updateKidiStatus,
  lowerGrade,
  getHelpTexts,
  higherGrade,
} from "../../../actions";

const PageAdministration = ({
  pageContent,
  authorId,
  postPageContent,
  getHelpTexts,
  lowerGrade,
  updateKidiStatus,
  higherGrade,
  status,
  helpTexts,
  updatePageContent,
  getHelp,
}) => {
  const [showEditor, setShowEditor] = useState(false);
  const [title, setTitle] = useState("Platzhalter-Titel");
  const [text, setText] = useState("Platzhalter");
  const [type, setType] = useState("");
  const [undo, setUndo] = useState(false);

  const [days, setDays] = useState(status.days);
  const [air, setAir] = useState(status.air);
  const [neutral, setNeutral] = useState(status.neutral);
  const [sad, setSad] = useState(status.sad);

  useEffect(() => {
    async function fetchUsers() {
      await getHelpTexts();
    }

    fetchUsers();
  }, [getHelpTexts]);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleTextChange = (text) => {
    setText(text);
  };
  const handleClick = (input) => {
    const filteredArr = pageContent.pageContent.filter((c) => c.type === input);
    if (filteredArr.length !== 0) {
      setText(filteredArr[0].text);
    }

    setType(input);
    setShowEditor(true);
  };
  const handleDataRedux = () => {
    if (pageContent.pageContent.length !== 0) {
      const existing = pageContent.pageContent.filter((c) => c.type === type);
      if (existing.length === 0) {
        postPageContent(text, authorId, type);
      } else {
        updatePageContent(text, authorId, type);
        getHelp("Der Inhalt wurde aktualisiert", "success");
        setShowEditor(false);
      }
    } else {
      postPageContent(text, authorId, type);
    }
  };
  const handleGradeHigher = () => {
    if (
      window.confirm(
        `ACHTUNG!
        Wollen Sie wirklich ALLE Schülerinnen und Schüler um eine Klassestufe erhöhen?`
      )
    ) {
      higherGrade().then(setUndo(true));
    }
  };
  const handleGradeLower = () => {
    if (
      window.confirm(
        `ACHTUNG!
        Wollen Sie wirklich ALLE Schülerinnen und Schüler um eine Klassestufe verringern?`
      )
    ) {
      lowerGrade();
    }
  };
  const handleChangeStatus = () => {
    updateKidiStatus(days, air, neutral, sad);
  };
  return (
    <div className="body-container">
      <div className={styles.column}>
        {showEditor ? (
          <div className={styles.block}>
            <TinyMCE
              setTitle={handleTitleChange}
              title={title}
              setText={handleTextChange}
              text={text}
            />
            <div className={styles.rowNormal}>
              <button
                className={styles.buttonArrow}
                onClick={() => {
                  setShowEditor(false);
                }}
              >
                <FiArrowLeft className={styles.arrow} />
              </button>
              <div className="buttonContainer">
                <button
                  className={styles.button}
                  onClick={() => handleDataRedux("test")}
                >
                  speichern
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <h3>Bearbeite hier folgende Seitentexte:</h3>
            <div className={styles.gridAdministration}>
              <div className="box1" onClick={() => handleClick("Landingpage")}>
                Begrüßungstext
              </div>
              <div className="box2" onClick={() => handleClick("TermsOfUse")}>
                Nutzungsbedingungen
              </div>
              <div className="box3" onClick={() => handleClick("ABC")}>
                kidi-ABC
              </div>
              <div className="box4" onClick={() => handleClick("Impressum")}>
                Impressum
              </div>
              <div className="box5" onClick={() => handleClick("Materialien")}>
                Materialien
              </div>
              <div className="box6" onClick={() => handleClick("Beitrage")}>
                Uneingeloggt-Beiträge
              </div>
              <div className="box7" onClick={() => handleClick("Stobern")}>
                Uneingeloggt-Stöbern
              </div>
              <div className="box8" onClick={() => handleClick("MeinKidi")}>
                Uneingeloggt-Mein kidi
              </div>
              <div className="box9" onClick={() => handleClick("loggedOutABC")}>
                Uneingeloggt-kidi-ABC
              </div>
              <div className="box10" onClick={() => handleClick("Students")}>
                Infos für Schülerinnen und Schüler
              </div>
              <div className="box11" onClick={() => handleClick("Teachers")}>
                Infos für Lehrpersonen
              </div>
              <div className="box12" onClick={() => handleClick("Parents")}>
                Infos für Eltern
              </div>
              <div className="box13" onClick={() => handleClick("GoodBye")}>
                Auf Wiedersehen
              </div>
              <Link
                to={{
                  pathname: `/administration/pageAdministration/helpTexts`,
                  /*    state: { id: name.id }, */
                }}
                className={styles.textDecoration}
              >
                {" "}
                <div className="box14">Hilfe-Texte</div>
              </Link>
            </div>
          </>
        )}
        {!showEditor ? (
          <>
            <div className={styles.kidiStatus}>
              <h2>kidi-Status ändern</h2>
              <h4>Grundlage zur Berechnung ist das Intervall:</h4>
              <div>
                Finde die <b>Anzahl</b> aller neuen Beiträge plus die Anzahl
                aller Beitragsänderungen im Intervall von heute bis heute minus
                X Tagen:
              </div>
              <div>
                Intervall{" "}
                <input
                  className={styles.inputNumber}
                  value={days}
                  onChange={(e) => setDays(e.target.value)}
                  type="number"
                ></input>
              </div>
              <h4>Einstellung der Stati:</h4>
              <div>
                {" "}
                Wenn die Anzahl grösser{" "}
                <input
                  className={styles.inputNumber}
                  value={air}
                  onChange={(e) => setAir(e.target.value)}
                  type="number"
                ></input>{" "}
                ist, dann zeige "Luftsprung"
              </div>
              <div>
                {" "}
                SONST, wenn die Anzahl grösser{" "}
                <input
                  className={styles.inputNumber}
                  value={neutral}
                  onChange={(e) => setNeutral(e.target.value)}
                  type="number"
                ></input>{" "}
                ist, dann zeige "Daumen hoch"
              </div>
              <div>
                SONST, wenn die Anzahl grösser
                <input
                  className={styles.inputNumber}
                  value={sad}
                  onChange={(e) => setSad(e.target.value)}
                  type="number"
                ></input>
                ist, dann zeige "sitzend"
              </div>
              <div> SONST zeige "schlafend"</div>
              <button className="button" onClick={handleChangeStatus}>
                speichern
              </button>
            </div>
            {!undo ? (
              <button
                className="button-red"
                onClick={() => {
                  handleGradeHigher();
                }}
              >
                alle Schülerinnen und Schüler eine Klassenstufe höher
              </button>
            ) : (
              <button
                className="button-red"
                onClick={() => {
                  handleGradeLower();
                }}
              >
                rückgänig machen
              </button>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    pageContent: state.pageContent,
    authorId: state.user.id,
    helpTexts: state.helpTexts.helpTexts,
    status: state.posts.kidiStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postPageContent: (text, authorId, type) => {
      dispatch(postPageContent(text, authorId, type));
    },
    updatePageContent: (text, authorId, type) => {
      dispatch(updatePageContent(text, authorId, type));
    },
    getHelp: (res, type) => dispatch(getHelp(res, type)),
    getHelpTexts: () => dispatch(getHelpTexts()),
    higherGrade: () => dispatch(higherGrade()),
    lowerGrade: () => dispatch(lowerGrade()),
    updateKidiStatus: (days, air, neutral, sad) =>
      dispatch(updateKidiStatus(days, air, neutral, sad)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageAdministration);
