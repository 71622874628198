import React, { useState, useEffect } from "react";
import "./LoginGrid.css";
import Schneemann from "../../pictures/login_pictures/Schneemann.svg";
import Reagenzglaeser from "../../pictures/login_pictures/Reagenzglaeser.svg";
import Pferd from "../../pictures/login_pictures/Pferd.svg";
import Weltkugel from "../../pictures/login_pictures/Weltkugel.svg";
import Kerze from "../../pictures/login_pictures/Kerze.svg";
import Sonne from "../../pictures/login_pictures/Sonne.svg";
import Fussball from "../../pictures/login_pictures/Fussball.svg";
import Controller from "../../pictures/login_pictures/Controler.svg";
import Fussabdruecke from "../../pictures/login_pictures/Fussabdruecke.svg";
import Skateboard from "../../pictures/login_pictures/Skateboard.svg";
import Baum from "../../pictures/login_pictures/Baum.svg";
import Stift from "../../pictures/login_pictures/Stift.svg";
import Elefant from "../../pictures/login_pictures/Elefant.svg";
import Delfin from "../../pictures/login_pictures/Delfin.svg";
import Euro from "../../pictures/login_pictures/Euro.svg";
import Auto from "../../pictures/login_pictures/Auto.svg";
import Inlineskate from "../../pictures/login_pictures/Inlineskate.svg";
import Stern from "../../pictures/login_pictures/Stern.svg";
import Boot from "../../pictures/login_pictures/Boot.svg";
import Smartphone from "../../pictures/login_pictures/Smartphone.svg";
import Pflanze from "../../pictures/login_pictures/Pflanze.svg";
import Sonnenblume from "../../pictures/login_pictures/Sonnenblume.svg";
import Lokomotive from "../../pictures/login_pictures/Lokomotive.svg";
import Herz from "../../pictures/login_pictures/Herz.svg";
import Basketball from "../../pictures/login_pictures/Basketball.svg";
import Eis from "../../pictures/login_pictures/Eis.svg";
import Kleeblatt from "../../pictures/login_pictures/Kleeblatt.svg";
import Haus from "../../pictures/login_pictures/Haus.svg";
import Schmetterling from "../../pictures/login_pictures/Schmetterling.svg";
import Wuerfel from "../../pictures/login_pictures/Wuerfel.svg";

const LoginGrid = (props) => {
  const [passwordArray, setPasswordArray] = useState([]);

  useEffect(() => {
    props.getGridArray(passwordArray);
  }, [passwordArray, props]);

  const handleClick = (obj) => {
    const text = obj.text;
    const existing = passwordArray.includes(text);
    if (existing) {
      const arr = passwordArray.filter((e) => e !== text);
      setPasswordArray(arr);
    } else {
      if (passwordArray.length < 3) {
        const arr = [text, ...passwordArray];
        setPasswordArray(arr);
      } else {
        const copy = passwordArray.slice(0, 2);
        const arr = [text, ...copy];

        setPasswordArray(arr);
      }
    }
  };
  const handleBackgroundColor = (word) => {
    if (passwordArray.includes(word)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className="grid-login">
      <img
        src={Weltkugel}
        alt="Weltkugel"
        className={`${
          handleBackgroundColor("Weltkugel") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Weltkugel", id: 1 })}
      />
      <img
        src={Fussabdruecke}
        alt="Fussabdruecke"
        className={`${
          handleBackgroundColor("Fussabdruecke1") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Fussabdruecke1", id: 2 })}
      />
      <img
        src={Pferd}
        alt="Pferd"
        className={`${
          handleBackgroundColor("Pferd2") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Pferd2", id: 3 })}
      />
      <img
        src={Stern}
        alt="Stern"
        className={`${
          handleBackgroundColor("Stern3") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Stern3", id: 4 })}
      />
      <img
        src={Kerze}
        alt="Kerze"
        className={`${
          handleBackgroundColor("Kerze3") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Kerze3", id: 5 })}
      />
      <img
        src={Sonne}
        alt="Sonne"
        className={`${
          handleBackgroundColor("Sonne7") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Sonne7", id: 6 })}
      />
      <img
        src={Smartphone}
        alt="Smartphone"
        className={`${
          handleBackgroundColor("Smartphone5") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Smartphone5", id: 7 })}
      />

      <img
        src={Boot}
        alt="Boot"
        className={`${
          handleBackgroundColor("Boot7") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Boot7", id: 8 })}
      />

      <img
        src={Reagenzglaeser}
        alt="Reagenzglaeser"
        className={`${
          handleBackgroundColor("Reagenzglaeser2") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Reagenzglaeser2", id: 9 })}
      />

      <img
        src={Schneemann}
        alt="Schneemann"
        className={`${
          handleBackgroundColor("Schneemann3") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Schneemann3", id: 10 })}
      />

      <img
        src={Auto}
        alt="Auto"
        className={`${
          handleBackgroundColor("Auto1") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Auto1", id: 11 })}
      />

      <img
        src={Baum}
        alt="Baum"
        className={`${
          handleBackgroundColor("Baum6") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Baum6", id: 12 })}
      />

      <img
        src={Pflanze}
        alt="Pflanze"
        className={`${
          handleBackgroundColor("Pflanze2") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Pflanze2", id: 13 })}
      />

      <img
        src={Elefant}
        alt="Elefant"
        className={`${
          handleBackgroundColor("Elefant3") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Elefant3", id: 14 })}
      />

      <img
        src={Controller}
        alt="Controller"
        className={`${
          handleBackgroundColor("Controller6") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Controller6", id: 15 })}
      />

      <img
        src={Skateboard}
        alt="Skateboard"
        className={`${
          handleBackgroundColor("Skateboard4") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Skateboard4", id: 16 })}
      />

      <img
        src={Stift}
        alt="Stift"
        className={`${
          handleBackgroundColor("Stift4") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Stift4", id: 17 })}
      />
      <img
        src={Inlineskate}
        alt="Inlineskate"
        className={`${
          handleBackgroundColor("Inlineskate8") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Inlineskate8", id: 18 })}
      />

      <img
        src={Lokomotive}
        alt="Lokomotive"
        className={`${
          handleBackgroundColor("Lokomotive2") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Lokomotive2", id: 19 })}
      />
      <img
        src={Kleeblatt}
        alt="Kleeblatt"
        className={`${
          handleBackgroundColor("Kleeblatt4") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Kleeblatt4", id: 20 })}
      />

      <img
        src={Euro}
        alt="Euro"
        className={`${
          handleBackgroundColor("Euro6") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Euro6", id: 21 })}
      />
      <img
        src={Delfin}
        alt="Delfin"
        className={`${
          handleBackgroundColor("Delfin5") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Delfin5", id: 22 })}
      />
      <img
        src={Sonnenblume}
        alt="Sonnenblume"
        className={`${
          handleBackgroundColor("Sonnenblume4") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Sonnenblume4", id: 23 })}
      />
      <img
        src={Fussball}
        alt="Fussball"
        className={`${
          handleBackgroundColor("Fussball1") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Fussball1", id: 24 })}
      />
      <img
        src={Herz}
        alt="Herz"
        className={`${
          handleBackgroundColor("Herz2") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Herz2", id: 25 })}
      />
      <img
        src={Basketball}
        alt="Basketball"
        className={`${
          handleBackgroundColor("Basketball5") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Basketball5", id: 26 })}
      />
      <img
        src={Eis}
        alt="Eis"
        className={`${handleBackgroundColor("Eis3") ? "Login" : "Login-Input"}`}
        onClick={() => handleClick({ text: "Eis3", id: 27 })}
      />

      <img
        src={Haus}
        alt="Haus"
        className={`${
          handleBackgroundColor("Haus4") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Haus4", id: 28 })}
      />
      <img
        src={Wuerfel}
        alt="Wuerfel"
        className={`${
          handleBackgroundColor("Wuerfel2") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Wuerfel2", id: 29 })}
      />
      <img
        src={Schmetterling}
        alt="Schmetterling"
        className={`${
          handleBackgroundColor("Schmetterling4") ? "Login" : "Login-Input"
        }`}
        onClick={() => handleClick({ text: "Schmetterling4", id: 30 })}
      />
    </div>
  );
};

export default LoginGrid;
