import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./ClassPosts.module.css";
import { connect } from "react-redux";
import { getClassPosts, getHelp, getClassDetails } from "../../actions";
import PostList from "../../components/PostList/PostList";
import Loading from "../../components/Loading/Loading";
import { TiArrowUnsorted } from "react-icons/ti";

const MeineBeiträge = ({
  getClassPosts,
  loading,
  classId,
  user,
  getClassDetails,
  getHelp,
  classesRedux,
}) => {
  const [publishedPosts, setpublishedPosts] = useState([]);
  const [notPublishedPosts, setNotPublishedPosts] = useState([]);
  const [classesList, setClassesList] = useState([]);
  const [classArr, setClassArr] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      await getClassDetails(user.class_id);
      const posts = await getClassPosts(classId);
      const published = await posts.filter((post) => post.published === true);
      setpublishedPosts(published);
      setClassArr(classId);
      const notPublished = await posts.filter(
        (post) => post.published === false
      );
      setNotPublishedPosts(notPublished);
    }
    getHelp();
    fetchPosts();
  }, [classId, getClassDetails, getClassPosts, getHelp, user.class_id]);

  useEffect(() => {
    setClassesList(classesRedux.classDetails);
  }, [classesRedux.classDetails]);

  const sortingPublished = (property) => {
    const copy = [...publishedPosts];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] !== null && b[property] !== null) {
        const nameA = a[property].toLowerCase();
        const nameB = b[property].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        return 0;
      }
    });
    setpublishedPosts(copy);

    if (copy[0].id === publishedPosts[0].id) {
      copy.reverse();
    }
  };
  const filterPosts = (c_id) => {
    const newPublishedPosts = publishedPosts.filter((post) => {
      return post.class_id === c_id;
    });
    const newNotPublishedPosts = notPublishedPosts.filter((post) => {
      return post.class_id === c_id;
    });
    setpublishedPosts(newPublishedPosts);
    setNotPublishedPosts(newNotPublishedPosts);
    setClassArr([c_id]);
  };
  const sortingNotPublished = (property) => {
    const copy = [...notPublishedPosts];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] !== null && b[property] !== null) {
        const nameA = a[property].toLowerCase();
        const nameB = b[property].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        return 0;
      }
    });
    setNotPublishedPosts(copy);

    if (copy[0].id === notPublishedPosts[0].id) {
      copy.reverse();
    }
  };
  return (
    <div className="body-container">
      {classArr.length <= 1 ? (
        <div className="column">
          {loading ? (
            <Loading />
          ) : (
            <div>
              {publishedPosts.length !== 0 ? (
                <>
                  <h3 className={styles.margin}>
                    Das sind die veröffentlichten Beiträge deiner Klasse
                  </h3>
                  <div className="list-header-row">
                    <div onClick={() => sortingPublished("title")}>
                      Titel <TiArrowUnsorted />
                    </div>
                    <div onClick={() => sortingPublished("user_name")}>
                      verfasst von <TiArrowUnsorted />
                    </div>
                    <div onClick={() => sortingPublished("creation_date")}>
                      erschienen am <TiArrowUnsorted />
                    </div>
                    <div onClick={() => sortingPublished("update_author_name")}>
                      bearbeitet von <TiArrowUnsorted />
                    </div>
                    <div onClick={() => sortingPublished("last_update_date")}>
                      bearbeitet am <TiArrowUnsorted />
                    </div>
                    <div onClick={() => sortingPublished("edit_lock_user_id")}>
                      in Bearbeitung von <TiArrowUnsorted />
                    </div>
                  </div>
                </>
              ) : (
                <h2>Es gibt keine veröffentlichten Beiträge</h2>
              )}

              {publishedPosts.map((post) => (
                <Link
                  to={`/stöbern/kategorien/beitrag/${post.id}`}
                  className="Link"
                  key={post.id}
                >
                  <div className={styles.list}>
                    <PostList
                      id={post.id}
                      role={post.class_role}
                      title={post.title}
                      date={post.creation_date}
                      userName={post.user_name}
                      updateDate={post.last_update_date}
                      updateUserName={post.update_author_name}
                      editLockUserName={post.edit_lock_user_name}
                      aquiredEditLockAt={post.aquired_edit_lock_at}
                    />
                  </div>
                </Link>
              ))}
              <div className={styles.space}></div>
              {notPublishedPosts.length !== 0 ? (
                <>
                  <h3 className={styles.margin}>
                    Das sind die unveröffentlichten Beiträge deiner Klasse
                  </h3>
                  <div className="list-header-row">
                    <div onClick={() => sortingNotPublished("title")}>
                      Titel <TiArrowUnsorted />
                    </div>
                    <div onClick={() => sortingNotPublished("user_name")}>
                      verfasst von <TiArrowUnsorted />
                    </div>
                    <div onClick={() => sortingNotPublished("creation_date")}>
                      erschienen am <TiArrowUnsorted />
                    </div>
                    <div
                      onClick={() => sortingNotPublished("update_author_name")}
                    >
                      bearbeitet von <TiArrowUnsorted />
                    </div>
                    <div
                      onClick={() => sortingNotPublished("last_update_date")}
                    >
                      bearbeitet am <TiArrowUnsorted />
                    </div>
                    <div onClick={() => sortingPublished("edit_lock_user_id")}>
                      in Bearbeitung von <TiArrowUnsorted />
                    </div>
                  </div>
                </>
              ) : (
                <h3>Es gibt keine unveröffentlichten Beiträge</h3>
              )}

              {notPublishedPosts.map((post) => (
                <Link
                  to={`/stöbern/kategorien/beitrag/${post.id}`}
                  className="Link"
                  key={post.id}
                >
                  <div className={styles.list}>
                    <PostList
                      id={post.id}
                      role={post.class_role}
                      title={post.title}
                      date={post.creation_date}
                      userName={post.user_name}
                      updateDate={post.last_update_date}
                      updateUserName={post.update_author_name}
                      editLockUserName={post.edit_lock_user_name}
                      aquiredEditLockAt={post.aquired_edit_lock_at}
                    />
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.column}>
          <h3>Du hast mehrere Klassen</h3>
          <h4>Wähle eine aus:</h4>
          {classesRedux.loading ? null : (
            <div className={styles.height}>
              {classesList.map((c) => (
                <div key={c.id} onClick={() => filterPosts(c.id)}>
                  <div className={styles.row}>
                    <div className={styles.button}> {c.class_name}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    classesRedux: state.classes,
    loading: state.posts.classPosts.loading,
    classId: state.user.class_id,
    classPosts: state.posts.classPosts.posts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClassPosts: (classId) => dispatch(getClassPosts(classId)),
    getHelp: () => dispatch(getHelp(7)),
    getClassDetails: (classId) => {
      dispatch(getClassDetails(classId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeineBeiträge);
