import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllPosts, reportPost, deletePost } from "../../../actions";
import styles from "./Posts.module.css";
import { MdDelete, MdCheck, MdPublic } from "react-icons/md";
import { TiArrowUnsorted } from "react-icons/ti";
import { GrRedo, GrUndo } from "react-icons/gr";

const Posts = ({
  getAllPosts,
  reportPost,
  posts,
  loading,

  deletePost,
}) => {
  const [allPosts, setAllPosts] = useState([]);
  const [activeFilter, setActiveFilter] = useState([]);
  const [report, setReport] = useState(false);
  const [notPublishedPosts, setNotPublishedPosts] = useState([]);
  const [publishedPosts, setPublishedPosts] = useState([]);
  const [reportedPosts, setReportedPosts] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      await getAllPosts();
    }
    fetchPosts();
    reported();
  }, []);
  useEffect(() => {
    reported();
  }, [posts]);

  const sorting = (property) => {
    const copy = [...allPosts];
    copy.sort(function (a, b) {
      if (a[property] === null) {
        return 1;
      } else if (b[property] === null) {
        return -1;
      } else if (a[property] !== null && b[property] !== null) {
        const nameA = a[property].toLowerCase();
        const nameB = b[property].toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      } else {
        return 0;
      }
    });
    setAllPosts(copy);

    if (copy[0].id === allPosts[0].id) {
      copy.reverse();
    }
  };
  const published = (boolean) => {
    if (activeFilter.includes("reported")) {
      setActiveFilter([boolean]);
      setReport(true);
      /*    const res = posts.filter((post) =>
        posts.includes(post.published == boolean)
      );
      setAllPosts([...res]); */
    }
    var filter = activeFilter.includes(boolean);
    /////wenn es drin is
    if (filter) {
      const newArr = activeFilter.filter((item) => item !== boolean);
      setActiveFilter([...newArr]);
      const res = posts.filter((post) => newArr.includes(post.published));
      setAllPosts([...res]);
      if (newArr.length === 0) {
        setAllPosts([...posts]);
      }
    } else {
      //////wenn es nicht drin is
      const filteredPosts = posts.filter((post) => {
        return post.published === boolean;
      });
      if (activeFilter.length === 0 || activeFilter.includes("reported")) {
        setAllPosts([...filteredPosts]);
      } else {
        const newArr = allPosts.concat(filteredPosts);
        setAllPosts([...newArr]);
      }

      /* filteredUsers.push(allUsers); */
      activeFilter.push(boolean);
    }
  };

  const reported = () => {
    if (report === true) {
      const filteredPosts = posts.filter((post) => {
        return post.reported === true;
      });
      setReportedPosts([...filteredPosts]);
      setAllPosts([...filteredPosts]);
      setActiveFilter(["reported"]);
    } else {
      setAllPosts(posts);
      /*       const newArr = activeFilter.filter((item) => item !== "reported");
      setActiveFilter([...newArr]); */
      setActiveFilter([]);
    }
    setReport(!report);
  };

  const handleBackgroundColor = (filter) => {
    if (activeFilter.includes(filter)) {
      return false;
    } else {
      return true;
    }
  };
  const formatDate = (input) => {
    const date = input.slice(0, 10);
    const dArr = date.split("-");
    return dArr[2] + "-" + dArr[1] + "-" + dArr[0].substring(2);
  };
  const badge = () => {
    const filteredPosts = posts.filter((post) => post.reported === true);
    return filteredPosts.length;
  };

  const handleCheck = (postId) => {
    reportPost(postId);
    const filteredPosts = allPosts.filter((post) => post.id !== postId);
    setAllPosts([...filteredPosts]);
    window.location.reload(false);
  };

  return (
    <div className="body-container">
      <div className={styles.columnOutside}>
        {loading ? (
          <h1>loading</h1>
        ) : (
          <div className={styles.column}>
            <div className={styles.rowmenu}>
              <button
                className={`${
                  handleBackgroundColor("reported")
                    ? styles.button
                    : styles.buttonActive
                }`}
                onClick={() => {
                  reported();
                }}
              >
                gemeldet
                {badge() == 0 ? null : (
                  <div className={styles.badge}>{badge()}</div>
                )}
              </button>
              <button
                className={`${
                  handleBackgroundColor(false)
                    ? styles.button
                    : styles.buttonActive
                }`}
                onClick={() => {
                  published(false);
                }}
              >
                unveröffentlicht
              </button>
              <button
                className={`${
                  handleBackgroundColor(true)
                    ? styles.button
                    : styles.buttonActive
                }`}
                onClick={() => {
                  published(true);
                }}
              >
                veröffentlicht
              </button>
            </div>
            <div className={styles.rowmenusort}>
              <p onClick={() => sorting("title")} className={styles.list}>
                Titel <TiArrowUnsorted />
              </p>
              <p onClick={() => sorting("user_name")} className={styles.list}>
                kidi-Name <TiArrowUnsorted />
              </p>
              <p onClick={() => sorting("stars")} className={styles.list}>
                Sterne <TiArrowUnsorted />
              </p>

              <p
                onClick={() => sorting("creation_date")}
                className={styles.list}
              >
                Erstellung <TiArrowUnsorted />
              </p>
              <div className={styles.blank}> </div>
            </div>
            {allPosts.length > 0 ? (
              <div className={styles.height}>
                {allPosts.map((post) => (
                  <div key={post.id}>
                    <div className={styles.row}>
                      <Link
                        to={`/stöbern/kategorien/beitrag/${post.id}`}
                        className={styles.link}
                      >
                        <div className={styles.list}> {post.title}</div>
                        <div className={styles.list}> {post.user_name}</div>
                        <div className={styles.list}>
                          {" "}
                          {Math.round(post.stars) / 2}
                        </div>
                        <div className={styles.list}>
                          {formatDate(post.creation_date)}
                        </div>
                      </Link>
                      <div className={styles.end}>
                        <div
                          className={styles.delete}
                          onClick={() => {
                            /*  handleEdit(category.id); */
                          }}
                        >
                          {post.published ? (
                            <div className={styles.iconCheck}></div>
                          ) : null}
                          {!post.published && !post.reported ? (
                            <GrRedo
                              className={styles.iconCheck}
                              onClick={() => {
                                handleCheck(post.id);
                              }}
                            />
                          ) : null}
                          {post.reported ? (
                            <GrUndo
                              className={styles.iconCheck}
                              onClick={() => {
                                handleCheck(post.id);
                              }}
                            />
                          ) : null}
                        </div>
                        <div
                          className={styles.delete}
                          onClick={() => {
                            if (
                              window.confirm(
                                `Den Beitrag "${post.title}" wirklich löschen?`
                              )
                            ) {
                              deletePost(post.id);
                            }
                          }}
                        >
                          <MdDelete className={styles.icons} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            <div className={styles.footer}>
              <div className={styles.count}>Anzahl: {allPosts.length}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    posts: state.posts.allPosts,
    loading: state.posts.loadings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllPosts: () => dispatch(getAllPosts()),
    reportPost: (postId) => dispatch(reportPost(postId, true, false)),
    deletePost: (id) => dispatch(deletePost(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Posts);
