import React from "react";
import "./PostView.css";

const PostView = (props) => {
  return (
    <div className="post-container">
      <div className="post-title">{props.title}</div>
      <div
        className="post-text"
        dangerouslySetInnerHTML={{ __html: props.text }}
      ></div>
    </div>
  );
};

export default PostView;
