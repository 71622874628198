import React from "react";
import styles from "./PasswordGuidelines.module.css";

const PostList = (props) => {
  return (
    <div className={styles.container}>
      {props.passwordGrid ? (
        <div className={styles.row}>
          Wähle <b>3</b> unterschiedliche Bilder
        </div>
      ) : (
        <>
          <div className={styles.row}>
            mindestens <b> 5 </b> Zeichen, davon
          </div>
          <ul className={styles.ul}>
            <li className={styles.il}>
              mindestens <b>1</b> Großbuchstabe
            </li>
            <li className={styles.il}>
              mindestens <b>1</b> Kleinbuchstabe
            </li>
            <li className={styles.il}>
              mindestens <b>1</b> Zahl
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default PostList;
