import * as actions from "../actions/types";

const initialState = {
  loading: false,
  allUserNames: [],
  users: [],
  myStudents: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case actions.POST_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.POST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, action.payload],
        myStudents: [...state.myStudents, action.payload],
      };
    case actions.POST_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case actions.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        allUserNames: action.payload,
      };
    case actions.GET_ALL_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_MY_STUDENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_MY_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        myStudents: action.payload,
      };
    case actions.GET_MY_STUDENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_USER_SUCCESS:
      const filteredArrUser = state.users.filter(
        (c) => c.id !== action.payload.id
      );
      const filteredArrStudents = state.myStudents.filter(
        (c) => c.id !== action.payload.id
      );
      const arrUsers = [action.payload, ...filteredArrUser];
      const arrStudents = [action.payload, ...filteredArrStudents];
      const sortedArrUser = arrUsers.sort((a, b) =>
        a.user_name > b.user_name ? 1 : b.user_name > a.user_name ? -1 : 0
      );
      const sortedArrStudents = arrStudents.sort((a, b) =>
        a.user_name > b.user_name ? 1 : b.user_name > a.user_name ? -1 : 0
      );
      return {
        ...state,
        loading: false,
        users: sortedArrUser,
        myStudents: sortedArrStudents,
      };
    case actions.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    case actions.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default users;
