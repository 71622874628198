import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getHelp, logOut } from "../../actions";
import Meins from "../../pictures/kidi/Meins.svg";
import Postfach from "../../pictures/kidi/Briefkasten_leer.svg";
import Postfach2 from "../../pictures/kidi/Postfach2.svg";
import closedDoor from "../../pictures/login_pictures/geschlossene_Tuer.svg";
import notLoggedIn from "../../pictures/Szene/Szene_blau.svg";

import "./MeinKidi.css";

const MeinKidi = ({
  getHelp,
  logOut,
  notifications,
  loggedIn,
  pageContent,
}) => {
  useEffect(() => {
    getHelp();
  }, [getHelp]);
  const handleLogOut = () => {
    logOut();
  };
  const filteredContent = pageContent.pageContent.filter((c) => {
    return c.type === "MeinKidi";
  });
  const unseen = notifications.filter(
    (notification) => notification.seen === false
  );
  return (
    <>
      {localStorage.getItem("token") ? (
        <div className="body-container">
          <div className="grid-container">
            <div className="grid-mein-kidi">
              <Link
                to={{
                  pathname: `/meinkidi/meins`,
                  /*    state: { id: name.id }, */
                }}
                className="text-decoration"
              >
                <div className="box1">
                  <div className="column">
                    <img
                      src={Meins}
                      alt={`Neuer Beitrag`}
                      className="pictureMeinKidi"
                    />
                    <div className="text-decoration">Meins</div>
                  </div>
                </div>
              </Link>
              <Link
                to={{
                  pathname: `/meinkidi/Postfach`,
                  /*    state: { id: name.id }, */
                }}
                className="text-decoration"
              >
                <div className="box2">
                  <div className="column">
                    {unseen.length ? (
                      <img
                        src={Postfach2}
                        alt={`Neuer Beitrag`}
                        className="pictureMeinKidi"
                      />
                    ) : (
                      <img
                        src={Postfach}
                        alt={`Neuer Beitrag`}
                        className="pictureMeinKidi"
                      />
                    )}
                    <div className="text-decoration">Postfach</div>
                  </div>
                </div>
              </Link>
              <Link to="/" onClick={handleLogOut} className="text-decoration">
                <div className="box3">
                  <div className="column">
                    <img
                      src={closedDoor}
                      alt={`Raus`}
                      className="pictureMeinKidi"
                    />
                    <div className="text-decoration">Raus</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <img
            src={notLoggedIn}
            alt="du bist leider nicht angemeldet"
            className="pictures"
          />
          {filteredContent[0] ? (
            <div className="column welcometexts marginLeft">
              <div
                dangerouslySetInnerHTML={{ __html: filteredContent[0].text }}
              ></div>{" "}
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    notifications: state.notifications.notifications,
    loggedIn: state.user.loggedIn,
    pageContent: state.pageContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    /*  getPosts: () => dispatch(getPosts()), */

    getHelp: () => dispatch(getHelp(9)),
    logOut: () => dispatch(logOut()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MeinKidi);
