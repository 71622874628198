import React from "react";
import styles from "./Impressum.module.css";
import { connect } from "react-redux";

const Impressum = ({ pageContent }) => {
  const filteredContent = pageContent.pageContent.filter((c) => {
    return c.type === "Impressum";
  });

  return (
    <>
      {
        <div className="body-container">
          <div className="column">
            <h2>Impressum von kidipedia </h2>
            <div className={styles.width}>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{ __html: filteredContent[0].text }}
              ></div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageContent: state.pageContent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Impressum);
