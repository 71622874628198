import * as actions from "../actions/types";

const initialState = {
  loading: false,
  toggle: false,
  error: null,
  help: "",
  type: "",
  helpTexts: [],
};

const helpTexts = (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_HELP:
      if (action.payload !== undefined) {
        return {
          ...state,
          toggle: action.payload,
        };
      } else {
        return {
          ...state,
          toggle: !state.toggle,
        };
      }
    case actions.GET_HELPTEXTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_HELPTEXTS_SUCCESS:
      return {
        ...state,
        loading: false,
        helpTexts: action.payload,
      };
    case actions.GET_HELPTEXTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.GET_HELPTEXT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_HELPTEXT_SUCCESS:
      return {
        ...state,
        loading: false,
        help: action.payload.text,
        type: action.payload.type,
      };
    case actions.GET_HELPTEXT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case actions.UPDATE_HELPTEXTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actions.UPDATE_HELPTEXTS_SUCCESS:
      const newArr = state.helpTexts.filter(
        (texts) => texts.id !== action.payload.id
      );
      const arr = [action.payload, ...newArr];
      const sortedArr = arr.sort((a, b) =>
        a.id > b.id ? 1 : b.id > a.id ? -1 : 0
      );
      return {
        ...state,
        loading: false,
        helpTexts: sortedArr,
      };
    case actions.UPDATE_HELPTEXTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default helpTexts;
